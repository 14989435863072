import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
// Customizable Area Start
import HelpController, { Props, configJSON } from "./HelpController";
import { NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";

export default class HelpDetails extends HelpController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <Grid container style={webStyles.Container}>
        <Loader loading={this.state.loader} data-test-id="loader" />
        <Grid item lg={12} sm={12} xs={12}>
          <Box p={{ sm: 4 }}>
            <Box style={webStyles.headerBox}>
              <span data-test-id="account-text" style={webStyles.accountText}>{configJSON.helpText}</span>
              <NavigateNext />
              <span style={webStyles.currentPage} data-test-id="delete-text">{this.state.is_terms_condition ? configJSON.TermsandConditionsText : configJSON.privacyPolicyText}</span>
            </Box>
            {this.state.is_privacy_policy && <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.privacyPolicyText}</Typography>}
            {this.state.is_terms_condition && <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.TermsandConditionsText}</Typography>}
            <Typography className="privacyPolicy" style={webStyles.policyData} dangerouslySetInnerHTML={{ __html: this.state.data }}></Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
  currentPage: {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer'
  },
  Container: {
    background: 'rgb(237, 238, 238)',
    height: '100vh',
  },
  policyData: {
    padding: '0px 30px'
  },
  headerBox: {
    fontSize: '0.875rem',
    color: '#A1A1A1',
    display: 'flex',
    gap: '0.3rem',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  accountText: {
    fontFamily: 'ClotherRegular',
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer',
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: '24px',
    lineHeight: "24px",
    paddingBottom: '25px'
  },
};

// Customizable Area End

