import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  privousStepFun:()=>void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  setStep: number;
  personalGoalInput: {
    helthGoalInput: string;
    SafetyGoalInput: string;
    EnviormentGoalInput: string;
  };
  selectHelthGoal: string | null;
  selectSafetyGoal: string | null;
  selectEnivormentGoal: string | null;
  indexForHelthMonth: string | null;
  indexForSafetyMonth: string | null;
  indexForEnivromentMonth: string | null;
  selectedMonthHealth: string;
  selectedMonthSafety: string;
  selectedMonthEnivroment: string;
  monthHelthSelect: any[];
  monthSafetySelect: any[];
  monthEnviromentSelect: any[];
  // Customizable Area End
}
interface SS {}

export default class RiskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  CreateGoalPostAPICallID: string = "";
  getAccountDataCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      setStep: 1,
      personalGoalInput: {
        helthGoalInput: "",
        SafetyGoalInput: "",
        EnviormentGoalInput: "",
      },
      selectHelthGoal: null,
      selectSafetyGoal: null,
      selectEnivormentGoal: null,
      indexForHelthMonth: null,
      indexForSafetyMonth: null,
      indexForEnivromentMonth: null,
      selectedMonthHealth: "",
      selectedMonthSafety: "",
      selectedMonthEnivroment: "",
      monthHelthSelect: [],
      monthSafetySelect: [],
      monthEnviromentSelect: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAccountDataAPI();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const webApiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let webResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (webApiRequestCallId === this.getAccountDataCallID) {
      const step1Data = webResponseJson?.data?.attributes.my_goals.find(
        (goalstep1: any) => goalstep1.type === "Health"
      )?.data;
      const step2Data = webResponseJson?.data?.attributes.my_goals.find(
        (goalstep2: any) => goalstep2.type === "Safety"
      )?.data;
      const step3Data = webResponseJson?.data?.attributes.my_goals.find(
        (goalstep3: any) => goalstep3.type === "Environment"
      )?.data;
      if (step1Data?.length > 0) {
        this.setState((prev) => ({
          monthHelthSelect: step1Data.map(
            (step1: { progress: any; month: any }) => ({
              type: step1.progress,
              month: step1.month,
            })
          ),
          personalGoalInput: {
            ...prev.personalGoalInput,
            helthGoalInput: step1Data[0].goal,
          },
        }));
      }
      if (step2Data?.length > 0) {
        this.setState((prev) => ({
          monthSafetySelect: step2Data.map(
            (step2: { progress: any; month: any }) => ({
              type: step2.progress,
              month: step2.month,
            })
          ),
          personalGoalInput: {
            ...prev.personalGoalInput,
            SafetyGoalInput: step2Data[0].goal,
          },
        }));
      }
      if (step3Data?.length > 0) {
        this.setState((prev) => ({
          monthEnviromentSelect: step3Data.map(
            (step3: { progress: any; month: any }) => ({
              type: step3.progress,
              month: step3.month,
            })
          ),
          personalGoalInput: {
            ...prev.personalGoalInput,
            EnviormentGoalInput: step3Data[0].goal,
          },
        }));
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  stepPlusFunction = () => {
    this.setState((prevState) => ({ setStep: prevState.setStep + 1 }));
  };

  StepMinusFunction = () => {
    if(this.state.setStep===1){
      this.props.privousStepFun();
    }
    this.setState((prevState) => ({
      setStep: prevState.setStep - 1,
    }));
  };

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = e.target;
    this.setState((prevState) => ({
      personalGoalInput: {
        ...prevState.personalGoalInput,
        [name]: value,
      },
    }));
  };

  handleSelecthelthGoal = (id: string) => {
    const monthIn = this.state.monthHelthSelect.findIndex(
      (x) => x.month === this.state.indexForHelthMonth
    );
    const newMo = [...this.state.monthHelthSelect];
    if (newMo[monthIn]) {
      newMo[monthIn].type = id;
    }
    this.setState({ selectHelthGoal: id, monthHelthSelect: newMo });
  };
  handleSelectSafetyData = (id: string) => {
    const safetyMonth = this.state.monthSafetySelect.findIndex(
      (sefMonth) => sefMonth.month === this.state.indexForSafetyMonth
    );
    const safetyNewMonth = [...this.state.monthSafetySelect];
    if (safetyNewMonth[safetyMonth]) {
      safetyNewMonth[safetyMonth].type = id;
    }
    this.setState({ selectSafetyGoal: id, monthSafetySelect: safetyNewMonth });
  };
  handleSelectEnivormentData = (id: string) => {
    const enviromentMonth = this.state.monthEnviromentSelect.findIndex(
      (envMonth) => envMonth.month === this.state.indexForEnivromentMonth
    );
    const enviromentNewMonth = [...this.state.monthEnviromentSelect];
    if (enviromentNewMonth[enviromentMonth]) {
      enviromentNewMonth[enviromentMonth].type = id;
    }
    this.setState({
      selectEnivormentGoal: id,
      monthEnviromentSelect: enviromentNewMonth,
    });
  };

  getAccountDataAPI = async () => {
    let token = localStorage.getItem("authToken");
    let signUpID = JSON.parse(await getStorageData("SignUpID"));
    const headerData = {
      token: token,
    };
    const getAllRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${signUpID}`
    );
    getAllRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    getAllRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getAccountDataCallID = getAllRequest.messageId;
    runEngine.sendMessage(getAllRequest.id, getAllRequest);
  };

  CreateGoalPostAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const currentYear = new Date().getFullYear();
    const headers = {
      "Content-Type": "application/json",
      token: tokenValue,
    };

    const result = (() => {
      switch (this.state.setStep) {
        case 1:
          return {
            goals: this.state.personalGoalInput.helthGoalInput,
            type: "Health",
            data: this.state.monthHelthSelect.map((item) => ({
              year: currentYear.toString(),
              month: item.month,
              progress: item.type,
            })),
          };
        case 2:
          return {
            goals: this.state.personalGoalInput.SafetyGoalInput,
            type: "Safety",
            data: this.state.monthSafetySelect.map((item) => ({
              year: currentYear.toString(),
              month: item.month,
              progress: item.type,
            })),
          };
        case 3:
          return {
            goals: this.state.personalGoalInput.EnviormentGoalInput,
            type: "Environment",
            data: this.state.monthEnviromentSelect.map((item) => ({
              year: currentYear.toString(),
              month: item.month,
              progress: item.type,
            })),
          };
        default:
          return null;
      }
    })();

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/create_goals`
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(result)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.CreateGoalPostAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  submitData = () => {
    this.stepPlusFunction();
    if (this.state.setStep === 1) {
      this.CreateGoalPostAPI();
    } else if (this.state.setStep === 2) {
      this.CreateGoalPostAPI();
    } else if (this.state.setStep === 3) {
      this.CreateGoalPostAPI();
    }
  };

  slectMonth = (monthData: string, index: number) => {
    this.setState((prevState) => {
      const monthHelthSelect = [...prevState.monthHelthSelect];

      const monthIndex = monthHelthSelect.findIndex(
        (item) => item.month === monthData
      );

      if (monthIndex === -1) {
        monthHelthSelect.push({ month: monthData, type: "" });
      }

      return { monthHelthSelect };
    });

    const monthObj = this.state.monthHelthSelect.find(
      (item) => item.month === monthData
    );

    this.setState({
      indexForHelthMonth: monthData,
      selectedMonthHealth: monthData,
      selectHelthGoal: monthObj?.type || null,
    });
  };

  selectMonthSafety = (monthData: string, index: number) => {
    this.setState((prevState) => {
      const monthSafetySelect = [...prevState.monthSafetySelect];

      const monthIndex = monthSafetySelect.findIndex(
        (item) => item.month === monthData
      );

      if (monthIndex === -1) {
        monthSafetySelect.push({ month: monthData, type: "" });
      }

      return { monthSafetySelect };
    });

    const monthObj = this.state.monthSafetySelect.find(
      (item) => item.month === monthData
    );

    this.setState({
      indexForSafetyMonth: monthData,
      selectedMonthSafety: monthData,
      selectSafetyGoal: monthObj?.type || null,
    });
  };

  selectMonthEnviroment = (monthData: string, index: number) => {
    this.setState((prevState) => {
      const monthEnviromentSelect = [...prevState.monthEnviromentSelect];

      const monthIndex = monthEnviromentSelect.findIndex(
        (item) => item.month === monthData
      );

      if (monthIndex === -1) {
        monthEnviromentSelect.push({ month: monthData, type: "" });
      }

      return { monthEnviromentSelect };
    });

    const monthObj = this.state.monthEnviromentSelect.find(
      (item) => item.month === monthData
    );

    this.setState({
      indexForEnivromentMonth: monthData,
      selectedMonthEnivroment: monthData,
      selectEnivormentGoal: monthObj?.type || null,
    });
  };
  // Customizable Area End
}
