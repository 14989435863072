Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "repeatingtasks2";
exports.labelBodyText = "repeatingtasks2 Body";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.patchApiMethodType = "PATCH";
exports.getLocationsApiEndPoint = "/bx_block_surveys/inspections/load_locations";
exports.getMachinesApiEndPoint = "/bx_block_surveys/inspections/load_machine_tools";
exports.getAssignPersonApiEndPoint = "/bx_block_posts/action_trackers/person_preventive_corrective_action";
exports.getDepartmentsApiEndPoint = "/bx_block_surveys/inspections/load_departments";
exports.postScheduleInspectionApiEndPoint = "/bx_block_surveys/inspections";
exports.getMachinesApiEndPoint = "/bx_block_surveys/inspections/load_machine_tools";
exports.listScheduleInspectionApiEndPoint = "bx_block_surveys/inspections";
exports.inspectionDetailApiEndPoint = "/bx_block_surveys/inspections";
exports.patchScheduleInspectionApiEndPoint = "/bx_block_surveys/inspections";

exports.btnExampleTitle = "CLICK ME";
exports.errorrMsg = "This field is required.";
// Customizable Area End