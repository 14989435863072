import React, { ChangeEvent } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormHelperText,
  FormControlLabel,
  Input,
  Dialog,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  Typography,
  RadioGroup,
  TextField,
  Select,
  ThemeProvider,
  Switch,
  withStyles,
} from "@material-ui/core";
import { Close, KeyboardArrowDown } from "@material-ui/icons";
import { theme, webStyle } from "./styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { CongratsModal } from "../../../../components/src/CongratsModal";
import { isPhone } from "../consts";
import { mic, micStart, upload } from "../assets";
export const configJSON = require("./config");

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(3),
  },
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#8735e1",
    },
    "&$checked + $track": {
      backgroundColor: "#8735e1",
    },
  },
  checked: {},
  track: {},
}))(Switch);

export enum PostSteps {
  Step1,
  Step2,
  Step3,
  StepComp,
}

export type PostFormData = {
  employee_id: string;
  sub_category_id: any;
  autoLocation: boolean;
  work_location_id: any;
  description: string;
  image: string;
  shift: any;
  proposed_description: string;
  hazard: string;
  operation_identification_id: any;
  post_concern_id: number;
  hazard_id: number;
  frequency_id: number;
  severity_id: number;
  consequence_id: number;
  hashtags: string[];
};

type PostProps = {
  setShowPostPopUp: (arg: boolean) => void;
  postSettings: PostSettings;
  loading?: boolean;
  createPost: (arg: any) => void;
  userName: string;
  postCreationFinished: boolean;
  buLocationCategory: any[];
  machineWorkLocation: any[];
  machineChangeStatus: (id: string) => void;
  loaderData: boolean;
};

export type UpldFileLink = string | ArrayBuffer | null;

export type PostState = {
  postData: any[];
  postStep: PostSteps;
  inputSchema: any;
  upldFileLink: UpldFileLink;
  postSubmitted: boolean;
  listening: boolean;
  transcription: string;
  liststep2: boolean;
  stoppedByTimeout: boolean;
  autoStopTimeout: boolean;
};

export type PostSettings = {
  employeeIds: string[];
  machineIds: string[];
  processAndOperationIds: any[];
  location: string;
  shifts: any[];
  concerns: any[];
  hazardSelects: any[];
  frequencies: any[];
  severities: any[];
  consequences: any[];
  hashtags: any[] | null;
};

export type ChildProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  submitForm: () => Promise<void>;
  errors: FormikErrors<PostFormData>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<PostFormData>;
  values: PostFormData;
  handleBlur: (e: React.FocusEvent<any>) => void;
  setStep: (step: PostSteps) => void;
  setUpldFileLink: (link: UpldFileLink) => void;
  setPostSubmitted: (value: boolean) => void;
  loading?: boolean;
  upldFileLink: UpldFileLink;
  postSettings: {
    employeeIds: string[];
    machineIds: string[];
    processAndOperationIds: any[];
    location: string;
    shifts: any[];
    concerns: any[];
    hazardSelects: any[];
    frequencies: any[];
    severities: any[];
    consequences: any[];
    hashtags: any[] | null;
  };
  buLocationCategory: any[];
  machineWorkLocation: any[];
  machineChangeStatus: (id: string) => void;
  loaderData: boolean;
};

export default class PostCreationPopUp extends React.Component<
  PostProps,
  PostState
> {
  textFieldRef: any;
  private recognition: any | undefined;
  constructor(props: PostProps) {
    super(props);

    const inputSchema = Yup.object().shape({
      description: Yup.string().required(configJSON.descIsRequired),
      sub_category_id: Yup.string()
        .notOneOf(["0"], "Please Select Location - BU or Cell")
        .required("Please Select Location - BU or Cell"),
      work_location_id: Yup.string()
        .notOneOf(["0"], "Please Select Machine / Equipment")
        .required("Please Select Machine / Equipment"),
      operation_identification_id: Yup.string()
        .notOneOf(["0"], "Please Select Process & Operation Identification")
        .required("Please Select Process & Operation Identification"),
      image: Yup.mixed().required("Image is required"),
    });

    this.textFieldRef = React.createRef();
    this.changeImg = this.changeImg.bind(this);

    this.state = {
      postData: [],
      postStep: PostSteps.Step1,
      inputSchema,
      upldFileLink: "",
      postSubmitted: false,
      listening: false,
      transcription: "",
      liststep2: false,
      stoppedByTimeout: false,
      autoStopTimeout: false,
    };

    this.setStep = this.setStep.bind(this);
    this.setUpldFileLink = this.setUpldFileLink.bind(this);
    this.setPostSubmitted = this.setPostSubmitted.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleListening = this.toggleListening.bind(this);
  }
  /* istanbul ignore next */
  componentDidMount() {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      this.setupSpeechRecognition();
    }
  }
  /* istanbul ignore next */
  setupSpeechRecognition() {
    this.recognition = new ((window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };

      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  /* istanbul ignore next */
  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  /* istanbul ignore next */
  addVisibilityChangeListener(pageHiddenProp: string) {
    document.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
      if ((document as any)[pageHiddenProp]) {
        if (this.recognition && this.state.listening) {
          this.recognition.stop();
          this.setState({ listening: false });
        }
        if (this.recognition && this.state.liststep2) {
          this.recognition.stop();
          this.setState({ liststep2: false });
        }
      }
    });
  }

  /* istanbul ignore next */
  toggleListening = async () => {
    if (this.recognition) {
      if (this.state.listening) {
        this.setState({ stoppedByTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listening && !this.state.stoppedByTimeout) {
            this.recognition.stop();
            this.setState({ listening: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listening: !prevState.listening,
      }));
    }
  };

  /* istanbul ignore next */
  toggleListeningStep2 = async () => {

    if (this.recognition) {
      if (this.state.liststep2) {
        this.setState({ autoStopTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ autoStopTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.liststep2 && !this.state.autoStopTimeout) {
            this.recognition.stop();
            this.setState({ liststep2: false });
          }
        }, 30000);
      }
      this.setState((prevState) => ({
        liststep2: !prevState.liststep2,
      }));
    }
  };
  /* istanbul ignore next */
  changeImg(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setUpldFileLink(fileReader.result);
    });
    files?.length && fileReader.readAsDataURL(files[0]);
  }
  setStep(step: PostSteps) {
    this.setState({ postStep: step });
  }
  /* istanbul ignore next */
  setUpldFileLink(upldFileLink: UpldFileLink) {
    this.setState({ upldFileLink });
  }

  setPostSubmitted(postSubmitted: boolean) {
    this.setState({ postSubmitted });
  }

  closeModal() {
    this.props.setShowPostPopUp(false);
    this.recognition.stop();
  }
  calculateNextStep(isStep1: boolean, isStep2: boolean, isHazard: boolean) {
    return (
      (isStep1 && PostSteps.Step2) ||
      (isStep2 && isHazard && PostSteps.Step3) ||
      PostSteps.StepComp
    );
  }
  onsubmitFunction = (
    values: any,
    actions: any,
    isStep1: any,
    isStep2: any
  ) => {
    const formData = new FormData();
    const isHazard = values.hazard === "yes";
    const nextStep = this.calculateNextStep(isStep1, isStep2, isHazard);

    !this.state.postSubmitted && this.setState({ postStep: nextStep });

    if (this.state.postSubmitted) {
      const idValues = {
        operation_identification_id: values.operation_identification_id,
        post_concern_id: values.post_concern_id,
        hazard_id: values.hazard_id,
        frequency_id: values.frequency_id,
        severity_id: values.severity_id,
        consequence_id: values.consequence_id,
      };

      const resValues = { ...values, ...idValues };


      for (let key in resValues) {
        const entity = (resValues as Record<string, any>)[key];
        if (entity !== "") {
          entity.name
            ? formData.append(key, entity, entity.name)
            : formData.append(String(key), String(entity));

        }
      }
      formData.append('status', 'report');
      this.props.createPost(formData);
    }
    if (isStep1 || isStep2) {
      this.recognition.stop();
      this.setState({ listening: false, liststep2: false });
    }
    actions.setSubmitting(false);
  };

  renderMic() {
    return this.state.listening ? micStart : mic;
  }

  renderMicStep2() {
    return this.state.liststep2 ? micStart : mic;
  }

  firstStep = (
    setFieldValue: any,
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any,
    touched: any,
    errors: any
  ) => {
    return (
      <div data-id="step1" style={webStyle.form as React.CSSProperties}>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>Employee ID</FormHelperText>
          <Input
            disabled
            name="employee_id"
            data-id="empId"
            style={{
              ...webStyle.input,
              ...webStyle.inputStd,
              ...webStyle.inputDisabled,
            }}
            value={values.employee_id}
            disableUnderline
            placeholder="Employee ID"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormGroup className="regular-input" style={{ marginTop: "5px" }}>
            <div
              className="labels"
              style={{ ...webStyle.inLineFlex, alignItems: "center" }}
            >
              <>
                <Typography
                  style={{
                    ...webStyle.label,
                    ...webStyle.smalLabel,
                  }}
                >
                  Location - BU or Cell
                </Typography>
                <CustomSwitch size="small" />
                <Typography
                  style={{
                    ...webStyle.label,
                    ...webStyle.smallText,
                    ...webStyle.smalLabel,
                  }}
                >
                  Auto detect Location
                </Typography>
              </>
            </div>
            <Select
              name="sub_category_id"
              data-test-id="locationInputId"
              label="Location"
              inputProps={{
                icon: {
                  left: 0,
                },
              }}
              style={
                {
                  ...webStyle.input,
                  ...webStyle.inputStd,
                } as React.CSSProperties
              }
              IconComponent={KeyboardArrowDown}
              value={values.sub_category_id}
              renderValue={
                values.sub_category_id
                  ? undefined
                  : () => (
                    <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                      Select Location - BU or Cell
                    </div>
                  )
              }
              disableUnderline
              onChange={(e) => {
                handleChange(e);
                this.props.machineChangeStatus(e.target.value as string);
              }}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                PaperProps: {
                  style: {
                    maxHeight: "200px",
                    width: "250px",
                  },
                },
                getContentAnchorEl: null,
              }}
            >
              {this.props.buLocationCategory?.map((menuItem, index) => (
                <MenuItem value={menuItem.attributes.id} key={index}>
                  {menuItem.attributes.name}
                </MenuItem>
              ))}
            </Select>
            <Typography style={webStyle.errorStyle}>
              {errors.sub_category_id &&
                touched.sub_category_id &&
                errors.sub_category_id}
            </Typography>
          </FormGroup>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>
            Machine / Equipment
          </FormHelperText>
          <Select
            name="work_location_id"
            data-id="machineId"
            label="Machine / Equipment"
            inputProps={{
              icon: {
                left: 0,
              },
            }}
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.work_location_id}
            renderValue={
              values.work_location_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Machine / Equipment
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.machineWorkLocation?.map((worklocation, index) => (
              <MenuItem value={worklocation.id} key={index}>
                {worklocation.attributes.name}
              </MenuItem>
            ))}
          </Select>
          <Typography style={webStyle.errorStyle}>
            {errors.work_location_id &&
              touched.work_location_id &&
              errors.work_location_id}
          </Typography>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>
            Process & Operation Identification
          </FormHelperText>
          <Select
            name="operation_identification_id"
            data-id="procAndOptId"
            label="Process And Operation"
            inputProps={{
              icon: {
                left: 0,
              },
            }}
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.operation_identification_id}
            renderValue={
              values.operation_identification_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Process & Operation
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.processAndOperationIds?.map(
              (porcessAndOpration) => (
                <MenuItem
                  value={porcessAndOpration.attributes?.id}
                  key={porcessAndOpration.attributes?.id}
                >
                  {porcessAndOpration.attributes?.name}
                </MenuItem>
              )
            )}
          </Select>
          <Typography style={webStyle.errorStyle}>
            {errors.operation_identification_id &&
              touched.operation_identification_id &&
              errors.operation_identification_id}
          </Typography>
        </FormGroup>
        <FormGroup
          className="input-block"
          style={
            {
              ...webStyle.wideInputBlock,
              ...webStyle.customTopIndent,
            } as React.CSSProperties
          }
        >
          <FormHelperText style={webStyle.label}>Describe Issue</FormHelperText>
          <img
            height="21px"
            width="21px"
            src={this.renderMic()}
            alt="mic icon"
            style={webStyle.mic as React.CSSProperties}
            onClick={async () => {
              await this.toggleListening();
              await setFieldValue(
                "description",
                values.description + " " + this.state.transcription
              );
            }}
            data-test-id="speechText"
          />
          <TextField
            name="description"
            data-id="descId"
            placeholder="Brief Description"
            inputRef={this.textFieldRef}
            style={webStyle.input}
            value={values.description}
            maxRows={3}
            minRows={3}
            multiline
            InputProps={{ disableUnderline: true }}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Typography style={webStyle.errorStyle}>
            {errors.description !== "" &&
              touched.description && !values.description &&
              errors.description}
          </Typography>
        </FormGroup>
        <Grid style={{ display: "flex", flexFlow: "wrap", gap: "10px" }}>
          {values.hashtags?.map((hashtag: any, index: number) => (
            <Grid key={index}>
              <Button
                variant="outlined"
                style={{
                  borderRadius: "8px",
                  color: "rgb(168 160 160)",
                  border: "1px solid #DCDCDC",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
                data-test-id="hashtag"
                onClick={() => {
                  setFieldValue(
                    "description",
                    values.description + " " + hashtag
                  );
                  setFieldValue(
                    "hashtags",
                    values.hashtags.filter((value: any) => value !== hashtag)
                  );
                }}
              >
                <span>
                  {hashtag.slice(0, 15)} {hashtag.length < 15 ? "" : "..."}
                </span>
              </Button>
            </Grid>
          ))}
        </Grid>
        <FormGroup
          className="input-block"
          style={webStyle.wideInputBlock as React.CSSProperties}
        >
          <FormHelperText style={webStyle.label}>Related Image</FormHelperText>
          <Button
            component="label"
            style={
              { ...webStyle.input, ...webStyle.upload } as React.CSSProperties
            }
          >
            <div
              className="upload-img"
              style={webStyle.uploadImg as React.CSSProperties}
            >
              <img
                src={this.state.upldFileLink || upload}
                alt="upload image"
                style={webStyle.iploadedImg as React.CSSProperties}
              />
              {values.image ? (
                <>
                  <div style={webStyle.fontBlock}>Upload Another Image</div>
                </>
              ) : (
                <div style={webStyle.fontBlock}>Upload Image</div>
              )}
            </div>
            <input
              data-id="upldFileId"
              type="file"
              name="image"
              id="user-image"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                setFieldValue("image", event?.target.files?.[0]);
                this.changeImg(event);
              }}
              onBlur={handleBlur}
            />
          </Button>
          <Typography style={webStyle.errorStyle}>
            {touched.image && errors.image}
          </Typography>
        </FormGroup>

        <div
          className="buttons"
          style={
            {
              display: "flex",
              justifyContent: "end",
              width: "100%",
              marginTop: "15px",
              marginBottom: "15px",
            } as React.CSSProperties
          }
        >
          <Button
            style={
              {
                ...webStyle.button,
                marginRight: "1rem",
                color: "#8735e1",
                backgroundColor: "#fff",
                border: "1px solid #8735e1",
                borderRadius: "8px",
                ...(isPhone && webStyle.mobileButton),
              } as React.CSSProperties
            }
            type="submit"
            onClick={submitForm}
            disabled={this.props.loading}
            data-test-id="submit"
            onMouseDown={() => this.setPostSubmitted(true)}
          >
            {this.props.loading ? (
              <CircularProgress size="2rem" color="inherit" />
            ) : (
              "Report"
            )}
          </Button>
          <Button
            data-id="nextButtonStep1"
            style={
              {
                ...webStyle.button,
                ...webStyle.fullButton,
                ...(isPhone && webStyle.mobileButton),
              } as React.CSSProperties
            }
            onClick={submitForm}
          >
            Next
          </Button>
        </div>
      </div>
    );
  };

  secondStep = (
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any,
    setFieldValue: any
  ) => {
    const buttonText = values.hazard === "no" ? "Report" : "Next";
    return (
      <>
        <div data-id="step2" style={webStyle.form as React.CSSProperties}>
          <FormGroup className="input-block" style={webStyle.inputBlock}>
            <FormHelperText style={webStyle.label}>Shift</FormHelperText>
            <Select
              name="shift"
              data-id="shiftId"
              label="Select Shift"
              style={
                {
                  ...webStyle.input,
                  ...webStyle.inputStd,
                } as React.CSSProperties
              }
              IconComponent={KeyboardArrowDown}
              value={values.shift}
              renderValue={
                values.shift
                  ? undefined
                  : () => (
                    <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                      Select Shift
                    </div>
                  )
              }
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              displayEmpty
              disableUnderline
            >
              {this.props.postSettings.shifts.map((menuItem) => (
                <MenuItem
                  key={menuItem.attributes?.id}
                  value={menuItem.attributes?.id}
                >
                  {menuItem.attributes?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup className="input-block" style={webStyle.inputBlock}>
            <FormHelperText style={webStyle.label}>
              Concern is Related to
            </FormHelperText>
            <Select
              name="post_concern_id"
              data-id="concernId"
              label="Concern is Related to"
              style={
                {
                  ...webStyle.input,
                  ...webStyle.inputStd,
                } as React.CSSProperties
              }
              IconComponent={KeyboardArrowDown}
              value={values.post_concern_id}
              renderValue={
                values.post_concern_id
                  ? undefined
                  : () => (
                    <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                      Concern is Related to
                    </div>
                  )
              }
              onChange={handleChange}
              onBlur={handleBlur}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              displayEmpty
              disableUnderline
            >
              {this.props.postSettings.concerns?.map((concerns) => (
                <MenuItem
                  key={concerns.attributes?.id}
                  value={concerns.attributes?.id}
                >
                  {concerns.attributes?.name}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
          <FormGroup
            className="input-block"
            style={
              {
                ...webStyle.wideInputBlock,
                ...webStyle.customTopIndent,
              } as React.CSSProperties
            }
          >
            <FormHelperText style={webStyle.label}>
              Proposed Action
            </FormHelperText>
            <img
              height="21px"
              width="21px"
              src={this.renderMicStep2()}
              onClick={async () => {
                await this.toggleListeningStep2();
                await setFieldValue(
                  "proposed_description",
                  values.proposed_description + " " + this.state.transcription
                );
              }}
              data-test-id="proposed_description"
              alt="mic icon"
              style={webStyle.mic as React.CSSProperties}
            />
            <TextField
              name="proposed_description"
              data-id="proposedActId"
              placeholder="Proposed Action"
              style={webStyle.input}
              value={values.proposed_description}
              maxRows={5}
              minRows={5}
              multiline
              InputProps={{ disableUnderline: true }}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          <div className="footer" style={{ marginTop: "200px" }}>
            <FormGroup style={webStyle.footerWarning as React.CSSProperties}>
              <FormHelperText style={webStyle.RadioLabel}>
                Would you like to fill
                <span style={webStyle.bold as React.CSSProperties}>
                  {" "}
                  Hazard Category & Other Details?{" "}
                </span>
                This will help you gain more points.
              </FormHelperText>
              <RadioGroup
                aria-labelledby="hazard-radio-buttons-group-label"
                defaultValue="no"
                value={values.hazard}
                name="radio-buttons-group"
                style={webStyle.footerRadioG as React.CSSProperties}
              >
                <FormControlLabel
                  style={webStyle.footerRadioLabel}
                  name="hazard"
                  data-id="hazardFirstId"
                  value="yes"
                  control={<Radio />}
                  label={
                    <Typography
                      style={webStyle.formControlLabel as React.CSSProperties}
                    >
                      Yes
                    </Typography>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormControlLabel
                  style={webStyle.footerRadioLabel}
                  name="hazard"
                  data-id="hazardSecondId"
                  value="no"
                  control={<Radio />}
                  label={
                    <Typography
                      style={webStyle.formControlLabel as React.CSSProperties}
                    >
                      No
                    </Typography>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </RadioGroup>
            </FormGroup>
            <div
              className="buttons"
              style={
                {
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  ...(isPhone && webStyle.mobileButtons),
                } as React.CSSProperties
              }
            >
              <Button
                style={
                  {
                    ...webStyle.button,
                    ...webStyle.transButton,
                    ...(isPhone && webStyle.mobileButton),
                  } as React.CSSProperties
                }
                data-id="backToPrevStep"
                onClick={() => {
                  this.setStep(PostSteps.Step1);
                  this.recognition.stop();
                  this.setState({ liststep2: false });
                }}
              >
                Previous
              </Button>
              <Button
                style={
                  {
                    ...webStyle.button,
                    ...webStyle.fullButton,
                    ...(isPhone && webStyle.mobileButton),
                  } as React.CSSProperties
                }
                data-id="nextButtonStep2"
                disabled={values.hazard === "no" && this.props.loading}
                type="submit"
                onClick={submitForm}
                onMouseDown={() =>
                  values.hazard === "no" && this.setPostSubmitted(true)
                }
              >
                {this.props.loading ? (
                  <CircularProgress
                    data-test-id="spinner"
                    size="2rem"
                    color="inherit"
                  />
                ) : (
                  buttonText
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  thirdStep = (
    values: any,
    handleBlur: any,
    handleChange: any,
    submitForm: any
  ) => {
    return (
      <div data-id="step3" style={webStyle.form as React.CSSProperties}>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>Hazard</FormHelperText>
          <Select
            name="hazard_id"
            data-id="catId"
            label="Select Category"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.hazard_id}
            displayEmpty
            renderValue={
              values.hazard_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Hazard
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings?.hazardSelects?.map((menuItem) => (
              <MenuItem
                key={menuItem.attributes?.id}
                value={menuItem.attributes?.id}
              >
                {menuItem.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>Frequency</FormHelperText>
          <Select
            name="frequency_id"
            data-id="freqId"
            label="Select Frequency"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.frequency_id}
            displayEmpty
            renderValue={
              values.frequency_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Frequency
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.frequencies?.map((frequencie) => (
              <MenuItem
                key={frequencie.attributes?.id}
                value={frequencie.attributes?.id}
              >
                {frequencie.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>Severity</FormHelperText>
          <Select
            name="severity_id"
            data-id="sevId"
            label="Select Severity"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.severity_id}
            displayEmpty
            renderValue={
              values.severity_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Severity
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.severities?.map((severitie) => (
              <MenuItem
                key={severitie.attributes?.id}
                value={severitie.attributes?.id}
              >
                {severitie.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <FormGroup className="input-block" style={webStyle.inputBlock}>
          <FormHelperText style={webStyle.label}>
            Consequence(HSE) - Potential Impact
          </FormHelperText>
          <Select
            name="consequence_id"
            data-id="consId"
            label="Select Consequences"
            style={
              { ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties
            }
            IconComponent={KeyboardArrowDown}
            value={values.consequence_id}
            displayEmpty
            renderValue={
              values.consequence_id
                ? undefined
                : () => (
                  <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                    Select Consequences
                  </div>
                )
            }
            disableUnderline
            onChange={handleChange}
            onBlur={handleBlur}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {this.props.postSettings.consequences?.map((consequences) => (
              <MenuItem
                key={consequences.attributes?.id}
                value={consequences.attributes?.id}
              >
                {consequences.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        <div className="footer" style={{ width: "100%", marginTop: "320px" }}>
          <div
            className="buttons"
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={
                {
                  ...webStyle.button,
                  ...webStyle.transButton,
                  ...(isPhone && webStyle.mobileButton),
                } as React.CSSProperties
              }
              data-id="backToPrevStep"
              onClick={() => this.setStep(PostSteps.Step2)}
            >
              Previous
            </Button>
            <Button
              style={
                {
                  ...webStyle.button,
                  ...webStyle.fullButton,
                  ...(isPhone && webStyle.mobileButton),
                } as React.CSSProperties
              }
              data-id="nextButtonStep3"
              type="submit"
              disabled={this.props.loading}
              onClick={submitForm}
              onMouseDown={() => this.setPostSubmitted(true)}
            >
              {this.props.loading ? (
                <CircularProgress size="2rem" color="inherit" />
              ) : (
                "Report"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };
  renderFunction = () => {
    const postSettings = this.props.postSettings;
    const { postStep } = this.state;

    const isStep1 = postStep === PostSteps.Step1;
    const isStep2 = postStep === PostSteps.Step2;
    const isStep3 = postStep === PostSteps.Step3;

    if (this.props.postCreationFinished) {
      return (
        <CongratsModal
          onClose={this.closeModal}
          userName={this.props.userName}
        />
      );
    } else {
      return (
        <Dialog
          style={webStyle.modal}
          maxWidth={"md"}
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div
            className="post-popup"
            style={
              {
                ...webStyle.modalMaindivStyle,
                ...(isPhone && webStyle.mobileModal),
              } as React.CSSProperties
            }
          >
            <h2 style={webStyle.header}>Create New Post</h2>
            <IconButton
              style={webStyle.closeBtn as React.CSSProperties}
              onClick={this.closeModal}
            >
              <Close />
            </IconButton>
            {this.props.loaderData ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              postSettings?.hashtags && (
                <Formik
                  initialValues={
                    {
                      employee_id: postSettings.employeeIds[0] || "AB143",
                      sub_category_id: 0,
                      autoLocation: false,
                      work_location_id: 0,
                      description: "",
                      image: "",
                      shift: 0,
                      proposed_description: "",
                      hazard: "no",
                      operation_identification_id: 0,
                      post_concern_id: 0,
                      hazard_id: 0,
                      frequency_id: 0,
                      severity_id: 0,
                      consequence_id: 0,
                      hashtags: postSettings.hashtags?.map((hashtag: any) => {
                        return "#" + hashtag.name;
                      }),
                    } as PostFormData
                  }
                  validationSchema={this.state.inputSchema}
                  onSubmit={(values, actions) => {
                    this.onsubmitFunction(values, actions, isStep1, isStep2);
                  }}
                >
                  {({
                    handleChange,
                    submitForm,
                    errors,
                    setFieldTouched,
                    touched,
                    values,
                    setFieldValue,
                    handleBlur,
                  }) => {
                    return (
                      <ThemeProvider theme={theme}>
                        {isStep1 &&
                          this.firstStep(
                            setFieldValue,
                            values,
                            handleBlur,
                            handleChange,
                            submitForm,
                            touched,
                            errors
                          )}
                        {isStep2 &&
                          this.secondStep(
                            values,
                            handleBlur,
                            handleChange,
                            submitForm,
                            setFieldValue
                          )}
                        {isStep3 &&
                          this.thirdStep(
                            values,
                            handleBlur,
                            handleChange,
                            submitForm
                          )}
                      </ThemeProvider>
                    );
                  }}
                </Formik>
              )
            )}
          </div>
        </Dialog>
      );
    }
  };
  render() {
    return this.renderFunction();
  }
}
