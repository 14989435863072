import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
// Customizable Area Start
import HelpController, { Props, configJSON } from "./HelpController";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import { NavigateNext } from "@material-ui/icons";

export default class Help extends HelpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container style={webStyles.mainContainer as React.CSSProperties}>
        <Grid item lg={12} sm={12}>
          <Box p={{ sm: 4 }}>
            <Box style={webStyles.headerBox}>
              <span data-test-id="account-text" style={webStyles.accountText}>{configJSON.helpText}</span>
            </Box>
            <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.helpText}</Typography>
            <Grid container style={webStyles.mainGrid}>
              <Grid item lg={12} sm={12}>
                <NavLink aria-current="page" to={`/policy/help`} exact={true}>
                  <Button style={{ ...webStyles.accountButtons, textTransform: "none" }} data-test-id="privacyPolicyBtn" onClick={this.getPrivacyPolicy.bind(this)}>
                    {configJSON.privacyPolicyText}
                    <NavigateNext style={{ fill: '#A1A1A1' }} />
                  </Button>
                </NavLink>
                <NavLink aria-current="page" to={`/conditions/help`} exact={true}>
                  <Button style={{ ...webStyles.accountButtons, textTransform: "none" }} data-test-id="termsCondition" onClick={this.getTermsCondition.bind(this)}>
                    {configJSON.TermsConditionsText}
                    <NavigateNext style={{ fill: '#A1A1A1' }} />
                  </Button>
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Loader loading={this.state.loader} data-test-id="loader" />
      </Grid>
    );
  }
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
  mainGrid: {
    background: "rgb(237, 238, 238)",
    height: "100vh"
  },
  accountButtons: {
    width: "100%",
    height: "52px",
    backgroundColor: "#FFFFFF",
    marginBottom: "18px",
    borderRadius: "8px",
    textTransform: "none",
    padding: "18px 20px 18px 20px",
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    lineHeight: "20px",
    justifyContent: 'space-between',
    color: '#2B2A29',
  },
  mainContainer: {
    background: 'rgb(237, 238, 238)',
    height: '100%',
    position: 'fixed',
    width: `-webkit-fill-available`,
  },
  headerBox: {
    fontSize: '0.875rem',
    color: '#A1A1A1',
    display: 'flex',
    gap: '0.3rem',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  accountText: {
    fontFamily: 'ClotherRegular',
    fontSize: "14px",
    lineHeight: "22px",
    color: '#A1A1A1',
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: '24px',
    lineHeight: "24px",
    paddingBottom: '25px'
  },
};

// Customizable Area End

