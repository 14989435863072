import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import SearchAdapter from "../../blocks/adapters/src/SearchAdapter";
import AssociatedImagesAdapter from "../../blocks/adapters/src/AssociatedImagesAdapter";
import ImageScreenAdapter from "../../blocks/adapters/src/ImageScreenAdapter";
import MyPostsAdapter from "../../blocks/adapters/src/MyPostsAdapter";
import ViewPostAdapter from "../../blocks/adapters/src/ViewPostAdapter";
import SupervisorPostCreationAdapter from "../../blocks/adapters/src/SupervisorPostCreationAdapter";


//Assembler generated adapters start
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
const searchAdapter = new SearchAdapter();
const associatedImagesAdapter = new AssociatedImagesAdapter();
const imageScreenAdapter = new ImageScreenAdapter();
const myPostsAdapter = new MyPostsAdapter();
const viewPostAdapter = new ViewPostAdapter();
const supervisorPostCreationAdapter = new SupervisorPostCreationAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to ApolloHSEapp!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'ocropticalcharacterrecognition'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PerformanceTracker'}  onPress={() => navigation.navigate("PerformanceTracker")} />
<CustomTextItem content={'PhotoLibrary'}  onPress={() => navigation.navigate("PhotoLibrary")} />
<CustomTextItem content={'Scoring'}  onPress={() => navigation.navigate("Scoring")} />
<CustomTextItem content={'freecredits'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Share'}  onPress={() => navigation.navigate("Share")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'LanguageSupport'}  onPress={() => navigation.navigate("LanguageSupport")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'dashboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Leaderboard'}  onPress={() => navigation.navigate("Leaderboard")} />
<CustomTextItem content={'markuptools'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'watermark'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'contentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'bulkcommentmanagement'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'certificationtracking'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'ContentFlag'}  onPress={() => navigation.navigate("ContentFlag")} />
<CustomTextItem content={'contentmoderation'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Couponcodegenerator'}  onPress={() => navigation.navigate("Couponcodegenerator")} />
<CustomTextItem content={'Notificationsettings'}  onPress={() => navigation.navigate("Notificationsettings")} />
<CustomTextItem content={'Invitefriends'}  onPress={() => navigation.navigate("Invitefriends")} />
<CustomTextItem content={'deeplinking'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'dashboardguests'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'UserProfileBasicBlock'}  onPress={() => navigation.navigate("UserProfileBasicBlock")} />
<CustomTextItem content={'EducationalUserProfile'}  onPress={() => navigation.navigate("EducationalUserProfile")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'languagedetection'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'mentionstagging'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'Sorting'}  onPress={() => navigation.navigate("Sorting")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Translation'}  onPress={() => navigation.navigate("Translation")} />
<CustomTextItem content={'CameraAccess'}  onPress={() => navigation.navigate("CameraAccess")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'QrCodes'}  onPress={() => navigation.navigate("QrCodes")} />
<CustomTextItem content={'Analytics'}  onPress={() => navigation.navigate("Analytics")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'comments'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'customform'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'Location'}  onPress={() => navigation.navigate("Location")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'Videolibrary'}  onPress={() => navigation.navigate("Videolibrary")} />
<CustomTextItem content={'ReviewApprovalAdmin'}  onPress={() => navigation.navigate("ReviewApprovalAdmin")} />
<CustomTextItem content={'FormApprovalWorkflow'}  onPress={() => navigation.navigate("FormApprovalWorkflow")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'elasticsearch'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Polling'}  onPress={() => navigation.navigate("Polling")} />
<CustomTextItem content={'surveys'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'Hashtags'}  onPress={() => navigation.navigate("Hashtags")} />
<CustomTextItem content={'subtitles'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'newsfeed'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Captcha'}  onPress={() => navigation.navigate("Captcha")} />
<CustomTextItem content={'locationbasedalerts'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'BroadcastMessage'}  onPress={() => navigation.navigate("BroadcastMessage")} />
<CustomTextItem content={'Geofence'}  onPress={() => navigation.navigate("Geofence")} />
<CustomTextItem content={'Adminconsole2'}  onPress={() => navigation.navigate("Adminconsole2")} />
<CustomTextItem content={'Objectidentifier2'}  onPress={() => navigation.navigate("Objectidentifier2")} />
<CustomTextItem content={'Rolesandpermissions'}  onPress={() => navigation.navigate("Rolesandpermissions")} />
<CustomTextItem content={'Accountscoreranking'}  onPress={() => navigation.navigate("Accountscoreranking")} />
<CustomTextItem content={'Machinelearningmlimagecategorisation'}  onPress={() => navigation.navigate("Machinelearningmlimagecategorisation")} />
<CustomTextItem content={'Conversationthreading2'}  onPress={() => navigation.navigate("Conversationthreading2")} />
<CustomTextItem content={'Likeapost2'}  onPress={() => navigation.navigate("Likeapost2")} />
<CustomTextItem content={'Matchalgorithm2'}  onPress={() => navigation.navigate("Matchalgorithm2")} />
<CustomTextItem content={'Uploadmedia3'}  onPress={() => navigation.navigate("Uploadmedia3")} />
<CustomTextItem content={'Textidentification'}  onPress={() => navigation.navigate("Textidentification")} />
<CustomTextItem content={'Imagetraininginchecklist'}  onPress={() => navigation.navigate("Imagetraininginchecklist")} />
<CustomTextItem content={'Emailnotifications2'}  onPress={() => navigation.navigate("Emailnotifications2")} />
<CustomTextItem content={'Googlevision'}  onPress={() => navigation.navigate("Googlevision")} />
<CustomTextItem content={'Voicenotes'}  onPress={() => navigation.navigate("Voicenotes")} />
<CustomTextItem content={'Karmapoints2'}  onPress={() => navigation.navigate("Karmapoints2")} />
<CustomTextItem content={'Emaillists2'}  onPress={() => navigation.navigate("Emaillists2")} />
<CustomTextItem content={'Repeatingtasks2'}  onPress={() => navigation.navigate("Repeatingtasks2")} />
<CustomTextItem content={'Audittrail2'}  onPress={() => navigation.navigate("Audittrail2")} />
<CustomTextItem content={'Dataencryption'}  onPress={() => navigation.navigate("Dataencryption")} />
<CustomTextItem content={'Servicespecificsettingsadmin2'}  onPress={() => navigation.navigate("Servicespecificsettingsadmin2")} />
<CustomTextItem content={'Informationmasking'}  onPress={() => navigation.navigate("Informationmasking")} />
<CustomTextItem content={'Microsoftdefenderintegration'}  onPress={() => navigation.navigate("Microsoftdefenderintegration")} />
<CustomTextItem content={'Logintimeout'}  onPress={() => navigation.navigate("Logintimeout")} />
<CustomTextItem content={'Objectidentificationinvideo'}  onPress={() => navigation.navigate("Objectidentificationinvideo")} />
<CustomTextItem content={'Cfobjecttagging'}  onPress={() => navigation.navigate("Cfobjecttagging")} />
<CustomTextItem content={'Cfobjectidentification2'}  onPress={() => navigation.navigate("Cfobjectidentification2")} />
<CustomTextItem content={'Cfvideoobjectidentification'}  onPress={() => navigation.navigate("Cfvideoobjectidentification")} />
<CustomTextItem content={'Languagesupport4'}  onPress={() => navigation.navigate("Languagesupport4")} />
<CustomTextItem content={'Tabletdesign1'}  onPress={() => navigation.navigate("Tabletdesign1")} />
<CustomTextItem content={'Tabletdesign22'}  onPress={() => navigation.navigate("Tabletdesign22")} />
<CustomTextItem content={'Tabletdesign32'}  onPress={() => navigation.navigate("Tabletdesign32")} />
<CustomTextItem content={'Tabletdesign43'}  onPress={() => navigation.navigate("Tabletdesign43")} />
<CustomTextItem content={'Story2'}  onPress={() => navigation.navigate("Story2")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;