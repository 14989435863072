import React from "react";
import { Box, Button, Dialog, DialogContent, Grid, List, ListItem, ListItemIcon, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { ConfirmImage } from "../../blocks/user-profile-basic/src/assets";

type Props = {
  navigation: any;
  items: {
    img: any;
    name: string;
    link?: string;
    style?: Record<string, string | number>;
  }[];
};

export const sideBarItems: Props["items"] = [
  {
    img: [
      require("../assets/image_Home.png"),
      require("../assets/HomeActive.png"),
    ],
    name: "Home",
    link: "/Dashboard",
  },
  {
    img: [require("../assets/audit.png"), require("../assets/auditActive.png")],
    name: "Audit",
    link: "/Audit",
  },
  {
    img: [require("../assets/search.png"), require("../assets/searchActive.png")],
    name: "Planned Inspection",
    link: "/PlannedInspection",
    style: { paddingRight: '6px' }
  },
  {
    img: [require("../assets/council.svg"), require("../assets/CouncilActive.svg")],
    name: "Safety Council",
    link: "/SafetyCouncilPost",
  },
  {
    img: [require("../assets/post.png"), require("../assets/postActive.png")],
    name: "My Posts",
    link: "/MyPosts",
  },
  {
    img: [require("../assets/post.png"), require("../assets/postActive.png")],
    name: "Publish a Post",
    link: "/PublishPost",
  },
  {
    img: [
      require("../assets/ActionTracker.png"),
      require("../assets/ActiontrackerActive.png"),
    ],
    name: "Action Tracker",
    link: "/PerformanceTracker",
  },
  {
    img: [
      require("../assets/account.png"),
      require("../assets/AccountActive.png"),
    ],
    name: "Account",
    link: "/Account",
  },
  {
    img: [require("../assets/Help.png"), require("../assets/HelpActive.png")],
    name: "Help",
    link: "/Help",
  },
];

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: '8px',
        overflowX: "hidden"
      },
    }
  }
});

export default class SidebarMenu extends React.Component {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmPopUp: false,
    }
  }
  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  };

  setLogOutPopUP = () => {
    this.setState({ confirmPopUp: true })
  }

  closeLogout = () => {
    this.setState({ confirmPopUp: false })
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <List style={webStyles.MainList as React.CSSProperties}>
          <div>
            {sideBarItems.map(({ style = {}, name, link, img }, index) => (
              <SvgImageRander style={style} name={name} index={index} link={link} img={img} />
            ))}
          </div>

          <div>
            <ListItem
              button
              dense
              data-test-id="Logout-button"
              style={
                {
                  ...webStyles.menuButton,
                } as React.CSSProperties
              }
              onClick={this.setLogOutPopUP}
            >
              <ListItemIcon>
                <img src={require("../assets/logout.png")} />
              </ListItemIcon>
              <p style={{ ...webStyles.menuText, color: "#F30202" }}>Logout</p>
            </ListItem>
          </div>
        </List>
        {/* @ts-ignore */}
        {this.state.confirmPopUp &&
          <Dialog
            style={{ borderRadius: '8px' }}
            maxWidth="md"
            open={true}
          >
            <CloseIcon style={{ float: 'right', alignSelf: 'flex-end', padding: '20px 20px 0px 20px', cursor: 'pointer' }} onClick={this.closeLogout} />
            <DialogContent style={webStyles.confirmationText as React.CSSProperties}>
              <Box style={webStyles.iconContainer} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <img src={ConfirmImage} />
              </Box>
              <Typography style={webStyles.confirmText} align="center">Are you sure want to logout?</Typography>
              <Grid container style={{ padding: '0px 10px' }}>
                <Button onClick={this.closeLogout} variant="outlined" data-test-id="handleClose" style={webStyles.cancelBtn as React.CSSProperties}>
                  Cancel
                </Button>
                <Button onClick={this.logout} variant="contained" data-test-id="handleClose" style={webStyles.logOutBtn as React.CSSProperties}>
                  Yes, Logout
                </Button>
              </Grid>
            </DialogContent>
          </Dialog>
        }
      </ThemeProvider>
    );
  }
}

export const SvgImageRander = ({ style, name, index, link, img }: any) => {
  let paths = window.location.pathname.split('/')
  const isActive = (paths[1].toLowerCase() === link?.split("/")[1].toLowerCase() || paths[2]?.toLowerCase() === link?.split("/")[1].toLowerCase());

  return (
    <NavLink
      to={String(link)}
      key={index}
      style={{ textDecoration: "none" }}
      activeClassName="active"
    >
      <ListItem
        dense
        key={name}
        data-test-id="list-button"
        style={{
          ...webStyles.menuButton,
          ...style,
        }}
      >
        <ListItemIcon>
          {isActive ? (
            <img
              src={img[1]}
            />
          ) : (
            <img
              src={img[0]}
            />
          )}
        </ListItemIcon>
        <p
          style={{
            ...webStyles.menuText,
            ...(isActive
              ? webStyles.active
              : ""),
          }}
        >
          {name}
        </p>
      </ListItem>
    </NavLink>
  );
};

const webStyles = {
  iconContainer: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: '#fee6e6',
  },
  confirmText: {
    padding: '30px 0px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
  },
  confirmationText: {
    padding: '18px 20px 30px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cancelBtn: {
    width: '180px',
    height: '54px',
    border: '1px solid #8735E1',
    borderRadius: '8px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#8735E1',
    padding: '0px',
    marginRight: '20px',
    textTransform: 'none',
    boxShadow: 'none',
  },
  logOutBtn: {
    width: '180px',
    height: '54px',
    border: '1px solid #F30202',
    backgroundColor: '#F30202',
    borderRadius: '8px',
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    color: '#FFFFFF',
    padding: '0px',
    textTransform: 'none',
    boxShadow: 'none'
  },
  active: {
    color: "#8735E1",
    fontSize: "20px",
    fontFamily: "ClotherBold",
    fontWeight: 700,
  },

  menuButton: {
    paddingTop: 0,
    paddingLeft: "1.5rem",
    overflow: "hidden",
    lineHeight: "8px",
  },
  menuText: {
    color: "#727272",
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    textDecoration: "none",
  },
  listBox: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: "25px",
  },
  MainList: {
    display: "flex",
    flexDirection: "column",
    minHeight: "82.5vh",
    justifyContent: "space-between",
  },
};
