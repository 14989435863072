import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    submitClicked: boolean;
    remarks: string;
    measurementUnit: {
        id: string;
        type: string;
        attributes: {
            id: number;
            name: string;
            country_id: number;
        }
    }[];
    nonCompiled: {
        id: string;
        type: string;
        attributes: {
            id: number;
            name: string;
            country_id: number;
        }
    }[];
    personList: {
        id: string;
        type: string;
        attributes: {
            full_name: string;
            employee_id: string;
        }
    }[];
    selectedMeasurementUnit: string;
    totalNumber: string;
    compiled: string;
    selectedNonCompiled: string;
    targetedDate: Date | null;
    assignTo: {
        label: string;
        value: string;
    }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class WorkerCheckpointController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAssignPersonApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            submitClicked: false,
            remarks: "",
            assignTo: {
                label: "",
                value: ""
            },
            measurementUnit: [{
                id: "11",
                type: "MeasurementUnit",
                attributes: {
                    id: 11,
                    name: "neutralEartthing",
                    country_id: 11
                }
            }],
            nonCompiled: [{
                id: "13",
                type: "Non_Compiled",
                attributes: {
                    id: 13,
                    name: "NonCompiled1",
                    country_id: 13
                }
            }],
            selectedMeasurementUnit: "",
            totalNumber: "",
            compiled: "",
            selectedNonCompiled: "",
            targetedDate: null,
            personList: [{
                id: "1",
                type: "person",
                attributes: {
                    full_name: "Pattty",
                    employee_id: "10"
                },
            },
            {
                id: "2",
                type: "person",
                attributes: {
                    full_name: "Harry",
                    employee_id: "11"
                }
            }
            ]

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        this.setState({ token });

    }

    handleSubmitButton = () => {
        this.setState({ submitClicked: true })

        const { selectedMeasurementUnit, totalNumber, compiled, selectedNonCompiled, targetedDate, assignTo, remarks } = this.state;
        const errorInSelectedMeasurementUnit = !selectedMeasurementUnit;
        const errorInTotalNumber = !totalNumber;
        const errorInCompiled = !compiled;
        const errorInSelectedNonCompiled = !selectedNonCompiled;
        const errorIntargetedDate = !targetedDate;
        const errorInAssignTo = !assignTo;
        const errorInRemarks = !remarks


        if (errorInSelectedMeasurementUnit || errorInTotalNumber || errorInCompiled || errorInSelectedNonCompiled || errorIntargetedDate || errorInAssignTo || errorInRemarks) {
            return;
        }
    }

    handleChangeMeasurementUnit = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedMeasurementUnit: newValue });
    };

    handleChangeNonCompiled = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as string;
        this.setState({ selectedNonCompiled: newValue });
    };

    handleChangeTotalNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        this.setState({ totalNumber: newValue });
    };

    handleChangeRemarks = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        this.setState({ remarks: newValue });
    };

    handleChangeCompiled = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        this.setState({ compiled: newValue });
    };

    handleChangeTargetedDatePicker = (SelectedDate: any) => {
        if (SelectedDate) {
            SelectedDate?.setHours(SelectedDate?.getHours() + 5);
            SelectedDate?.setMinutes(SelectedDate?.getMinutes() + 30);
            const formatedDate = SelectedDate?.toISOString().replace(/(,|\s)+/g, 'T');
            this.setState({ targetedDate: new Date(formatedDate) })
        }
    }
    handleChangeTargetedDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ targetedDate: new Date(event.target.value) });
    }
    // Customizable Area End
}
