import {
  Avatar,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import React from 'react';
import {
  attachment1,
  attachment2,
  comment,
  like,
  location,
  time,
} from './assets';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { webStyles } from '../../../components/src/styles';
import { getTimeFromNow } from '../../../components/src/helpers/getTimeFromNow';
import { profileIcon } from '../../../components/src/assets';

export const PostView = ({ postData }: { postData: any }) =>
  <Box
    test-id='post-view'
    display="flex"
    flexDirection="column"
    bgcolor="white"
    p={2}
    borderRadius={8}
    border='1px solid #d7d2d2'
    boxShadow={1}
    mt='0.6rem'
    pt={2}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between" >
      <Box display="flex" alignItems="center">
        {/* Your custom icon */}
        <Avatar
          data-test-id='profile-icon'
          src={postData?.attributes?.profile_pic || profileIcon}
          alt="Custom Icon"
          style={{ marginRight: 8, cursor: "pointer" }}
        />

        {/* Heading */}
        <Typography
          data-test-id='post-heading'
          variant="h6"
          style={{ fontSize: "18px", fontFamily: "ClotherBold" }}
          component="div">
          {postData?.attributes?.user_name || "No Name"} created this post
        </Typography>
      </Box>

      <IconButton>
        <MoreVertIcon />
      </IconButton>
    </Box>

    {postData?.attributes?.image_url &&
    <a href={postData?.attributes?.image_url} target='blank'>
      <img
        data-test-id='profile-img'
        src={postData?.attributes?.image_url}
        alt="Your Image"
        style={{
          width: '100%',
          height: 300,
          objectFit: 'cover',
          marginTop: 8,
          borderRadius: "8px",
          cursor: 'pointer'
        }} />
        </a>}

    <Box mt={2}>
      <Typography
        data-test-id='post-description'
        variant="body1"
        component="p"
        style={webStyles.descrp}
      >
        {postData?.attributes?.description || "No Descrpition"}
      </Typography>
    </Box>

    <Box display="flex"
      alignItems="center"
      style={{
        margin: '1rem 0',
        background: "#f5f5f5",
        width: "fit-content",
        borderRadius: "5px",
        cursor: 'pointer'
      }}
    >
      <IconButton>
        <img src={location} />
      </IconButton>
      <Typography
        data-test-id='profile-location'
        variant="body2"
        component="div" style={{
          marginRight: 75,
          fontSize: "1rem",
          color:'#A1A1A1',
          fontFamily:'ClotherRegular'
        }}>
        {postData?.attributes?.work_location || postData?.attributes?.location || "No Location"}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" justifyContent='space-between' flexWrap='wrap'>
      <Box display="flex" alignItems='center' justifyContent='flex-start'>
        <Box display="flex" alignItems='center'>
          <IconButton>
            <Avatar
              data-test-id='secondary-profile-icon'
              src={postData?.attributes?.profile_pic || profileIcon}
              alt="Custom Icon"
              style={{
                marginRight: 6,
                fontSize: "14px",
              }}
            />
          </IconButton>
          <Typography
            data-test-id='user-name'
            variant="body2"
            style={{color:'#A1A1A1', fontFamily:'ClotherRegular'}}
            component="div">
            {postData?.attributes?.user_name?.slice(0, 12)}
            {postData?.attributes?.user_name?.length < 12 ? "" : "..."}
          </Typography>
        </Box>
        <Box display="flex" alignItems='center'>
          <IconButton>
            <img src={time} />
          </IconButton>
          <Typography
            data-test-id='post-creation-date'
            variant="body2"
            style={{ color:'#A1A1A1', fontFamily:'ClotherRegular'}}
            component="div">
            {getTimeFromNow(postData?.attributes?.created_at)} ago
          </Typography>
        </Box>
      </Box>
      <Box display="flex"  justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <IconButton>
            <img src={like} />
          </IconButton>
          <Typography variant="body2" component="div" style={{ color:'#A1A1A1', fontFamily:'ClotherRegular'}}>
            0 Likes
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton>
            <img src={comment} />
          </IconButton>
          <Typography variant="body2" component="div" style={{ color:'#A1A1A1', fontFamily:'ClotherRegular'}}>
            0 Comments
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={1} width='100%' position='relative'>
        <textarea placeholder="Write Your Comment" style={{
          flex: 1,
          padding: 14,
          borderRadius: 4,
          border: '1px solid #f5f5f5',
          background: "#f5f5f5",
          fontSize: '0.875rem',
          resize: 'none',
          height: '3rem',
          paddingRight: '3.5rem',
          color: '#A1A1A1',
          fontFamily: 'ClotherRegular',
          overflow: 'hidden'
        }} />
        <IconButton style={{ position: 'absolute', right: "1.5" }}>
          <img src={attachment1} style={{ paddingRight: "5px" }} />
          <img src={attachment2} />
        </IconButton>
      </Box>
    </Box>
  </Box>