import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    activeButton: string;
    listeningForDesc: boolean;
    listeningCorrectiveActionDesc: boolean;
    currentCorrectiveActionSpeech: number;
    transcription: string;
    stoppedByTimeoutDesc: boolean;
    stoppedByTimeoutCorrectiveActionDesc: boolean;
    correctiveImage: any;
    area: {
        location: string;
        department: string;
    },
    serialNumber: any;
    submitClicked: boolean;
    locationDescription: string;
    selectedId: string;
    errorMsg: string;
    loading: boolean;
    personList: {
        id: string;
        type: string;
        attributes: {
            full_name: string;
            employee_id: string;
        }
    }[];
    inspectionDetailData: {
        id?: string;
        attributes?: {
            account_id: number;
            assigned_to: string;
            assigned_to_id: number;
            department: string;
            department_id: number;
            email: string;
            equipment_name: string;
            equipment_number: number;
            first_image: string;
            inspection_date: string;
            location: string;
            location_id: number;
            machine_tool: string;
            machine_tool_id: number;
            second_image: string;
            status: string;
        }
    };
    correctiveActions: {
        counter: number;
        correctiveAction: string;
        uploadedImage: any;
        errorMessageImage: string;
        uploadedImagePreview: any;
        deficiencyNumber: string;
        assignedPerson: {
            label: string;
            value: string;
        },
        dueDate: Date | null;
    }[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class DetailPlannedInspectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    recognition: any | undefined;
    getInspectionDetailApiCallId: string = "";
    getAssignPersonApiCallId: string = "";
    updateDetailApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            activeButton: "Details",
            listeningForDesc: false,
            listeningCorrectiveActionDesc: false,
            currentCorrectiveActionSpeech: 0,
            transcription: "",
            stoppedByTimeoutDesc: false,
            stoppedByTimeoutCorrectiveActionDesc: false,
            correctiveImage: "",
            area: {
                location: "", 
                department: ""
            },
            serialNumber: "",
            submitClicked: false,
            selectedId: "",
            locationDescription: "",
            errorMsg: "",
            loading: false,
            personList: [],
            inspectionDetailData: {},
            correctiveActions: [{
                counter: 1,
                correctiveAction: "",
                uploadedImage: "",
                errorMessageImage: "",
                uploadedImagePreview: "",
                deficiencyNumber: "",
                assignedPerson: { label: "", value: "" },
                dueDate: null
            }],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getInspectionDetailApiCallId) {
                this.handleInspectiondetailResponse(responseJson)
            }

            if (apiRequestCallId === this.getAssignPersonApiCallId) {
                this.handleAssignPersonApiResponse(responseJson)
            }

            if (apiRequestCallId === this.updateDetailApiCallId) {
                this.handleUpdateDetailApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        let inspectionId = await getStorageData("inspectionid", false);
        this.setState({ token, selectedId: inspectionId }, () => {
            this.inspectionDetailApiCall(inspectionId);
        });

        this.assignPersonApiCall();

        if ("SpeechRecognition" in global || "webkitSpeechRecognition" in global) {
            this.setupSpeechRecognition();
        }
    }

    setupSpeechRecognition() {
        this.recognition = new ((global as any).SpeechRecognition ||
            (global as any).webkitSpeechRecognition)();

        if (this.recognition) {
            this.recognition.lang = "en-US";
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onresult = (event: any) => {
                const transcription = Array.from(event.results)
                    .map((result: any) => result[0].transcript)
                    .join("");
                this.setState({ transcription });
                console.log('mount transcription', transcription)
            };
            const pageHiddenProp = this.getPageHiddenProp();
            if (pageHiddenProp) {
                this.addVisibilityChangeListener(pageHiddenProp);
            }
        }
    }

    addVisibilityChangeListener(pageHiddenProp: string) {
        document.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
            console.log((document as any)[pageHiddenProp], ' &&', this.recognition)
            if ((document as any)[pageHiddenProp] && this.recognition) {
                this.stopRecognition();
            }
        });
    }

    stopRecognition() {
        if (this.state.listeningForDesc) {
            this.recognition.stop();
            this.setState({ listeningForDesc: false });
        }
        if (this.state.listeningCorrectiveActionDesc) {
            this.recognition.stop();
            this.setState({ listeningCorrectiveActionDesc: false });
        }
    }

    getPageHiddenProp() {
        const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
        for (const prop of hiddenProps) {
            if (prop in document) {
                return prop;
            }
        }
        return null;
    }

    toggleListening = async () => {
        if (this.recognition) {
            if (this.state.listeningForDesc) {
                this.setState({ stoppedByTimeoutDesc: true });
                this.recognition.stop();
            } else {
                this.setState({ stoppedByTimeoutDesc: false });
                await this.recognition.start();
                this.setState({ transcription: "" });
                setTimeout(() => {
                    if (this.state.listeningForDesc && !this.state.stoppedByTimeoutDesc) {
                        this.recognition.stop();
                        this.setState({ listeningForDesc: false });
                    }
                }, 30000);

            }
            this.setState((prevState) => ({
                listeningForDesc: !prevState.listeningForDesc,
            }));
        }
    };

    toggleListeningForCorrectiveDesc = async () => {
        if (this.recognition) {
            if (this.state.listeningCorrectiveActionDesc) {
                this.setState({ stoppedByTimeoutCorrectiveActionDesc: true });
                this.recognition.stop();
            } else {
                this.setState({ stoppedByTimeoutCorrectiveActionDesc: false });
                await this.recognition.start();
                this.setState({ transcription: "" });
                setTimeout(() => {
                    if (this.state.listeningCorrectiveActionDesc && !this.state.stoppedByTimeoutCorrectiveActionDesc) {
                        this.recognition.stop();
                        this.setState({ listeningCorrectiveActionDesc: false });
                    }
                }, 30000);

            }
            this.setState((prevState) => ({
                listeningCorrectiveActionDesc: !prevState.listeningCorrectiveActionDesc,
            }));
        }
    };

    assignPersonApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssignPersonApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssignPersonApiEndPoint
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    inspectionDetailApiCall = (id: string) => {
        this.setState({ loading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getInspectionDetailApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.inspectionDetailApiEndPoint}/${id}`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    handleUpdateDetailApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                loading: false
            });
            
            const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
            navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "PlannedInspection");
            navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigateMsg);
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loading: false
            });
        }
    }

    handleAssignPersonApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ personList: responseJson.accounts.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleInspectiondetailResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({
                inspectionDetailData: responseJson.data,
                area: {
                    department: responseJson.data.attributes?.department, 
                    location: responseJson.data.attributes?.location
                },
                loading: false
            })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loading: false
            });
        }
    }

    handleDetailsButtonClick = () => {
        this.setState({ activeButton: 'Details' })
    }

    handleDetailSubmit = async () => {
        this.setState({ submitClicked: true })
        const { area, serialNumber, locationDescription, correctiveActions } = this.state;
        const errorInArea = !area;
        const errorInSerialNumber = !serialNumber;
        const errorInLocationDescription = !locationDescription;
        const errorInCorrectiveActions = (correctiveActions[0].assignedPerson.value === '' ||
            correctiveActions[0].correctiveAction === '' ||
            correctiveActions[0].deficiencyNumber === '' ||
            correctiveActions[0].dueDate === null ||
            correctiveActions[0].uploadedImage === '');

        if (this.state.activeButton === "Details" && (errorInArea || errorInSerialNumber || errorInLocationDescription || errorInCorrectiveActions)) {
            return;
        }

        this.updateInspectionDetails();
    }

    updateInspectionDetails = async () => {
        this.setState({
            loading: true
        });

        const headers = {
            token: this.state.token,
        };

        const status = "Completed";

        const currentDate = moment().format('DD MMM YYYY');
        let formData = new FormData();
        formData.append("status", status);

        this.state.correctiveActions.forEach((value, index) => {
            if (value?.assignedPerson?.value != '') {
                const formattedDate = moment(value?.dueDate?.toISOString()).format('DD MMM YYYY');
                const currentData = `bx_block_posts_action_trackers_attributes[${index}]`;
                formData.append(`${currentData}[due_date]`, formattedDate);
                formData.append(`${currentData}[assigned_to]`, value?.assignedPerson?.label);
                formData.append(`${currentData}[assinged_on]`, currentDate);
                formData.append(`${currentData}[assigned_by]`, `${this.state.inspectionDetailData.attributes?.assigned_to}`);
                formData.append(`${currentData}[account_id]`, `${this.state.inspectionDetailData.attributes?.account_id}`);
                formData.append(`${currentData}[is_planned_inspection_action_tracker]`, "true");
                formData.append(`${currentData}[location_description]`, this.state.locationDescription);
                formData.append(`${currentData}[area]`, `${this.state.area.department} / ${this.state.area.location}`);
                formData.append(`${currentData}[serial_number]`, this.state.serialNumber);
                formData.append(`${currentData}[description]`, value?.correctiveAction);
                formData.append(`${currentData}[action_type]`, "corrective_action");
                formData.append(`${currentData}[deficiency_number]`, value?.deficiencyNumber)
                formData.append(`${currentData}[corrective_or_preventive_action_assigned_person_id]`, value?.assignedPerson?.value);
                if (value?.uploadedImage) {
                    formData.append(`${currentData}[action_image]`, (value?.uploadedImage) as File, value?.uploadedImage?.filename);
                }
            }
        });

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.patchScheduleInspectionApiEndPoint}/${this.state.selectedId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChecklistButtonClick = () => {
        this.setState({ activeButton: 'Checklist' })
    }

    handleAddCorrectiveAction = () => {
        const { correctiveActions } = this.state;
        const counter = correctiveActions[correctiveActions.length - 1].counter + 1;
        this.setState({
            correctiveActions: [...correctiveActions, { counter, correctiveAction: "", uploadedImage: "", errorMessageImage: "", uploadedImagePreview: "", deficiencyNumber: "", assignedPerson: { label: "", value: "" }, dueDate: null }],
        });
    };

    removeCorrectiveAction = (indexToRemove: number) => {
        const { correctiveActions } = this.state;
        if (correctiveActions.length > 1) {
            const updatedActions = correctiveActions.filter(
                (_, index) => index !== indexToRemove
            );
            this.setState({ correctiveActions: updatedActions });
        }
    };

    handleChangeSerialNumber = (event: ChangeEvent<{ name?: string, value: unknown }>) => {
        this.setState({ serialNumber: (event.target.value) as string });
    }

    handleChangeLocationDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ locationDescription: event.target.value });
    }

    handleChangeCorrectiveActions = (value: string, index: number) => {
        const correctiveActions = [...this.state.correctiveActions];
        correctiveActions[index].correctiveAction = value;
        this.setState({ correctiveActions: correctiveActions });
    }

    handleChangeDeficiencyNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const correctiveActions = [...this.state.correctiveActions];
        correctiveActions[index].deficiencyNumber = event.target.value;
        this.setState({ correctiveActions: correctiveActions });
    }

    handleAssignPerson = (newValue: any, index: number) => {
        const correctiveActions = [...this.state.correctiveActions];
        correctiveActions[index].assignedPerson = newValue;
        this.setState({ correctiveActions: correctiveActions });
    }

    handleUploadedImage = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                const correctiveActions = [...this.state.correctiveActions];
                correctiveActions[index].uploadedImage = file;
                correctiveActions[index].errorMessageImage = "";
                this.setState({ correctiveActions: correctiveActions });
                this.changeImg(event, index);
            } else {
                const correctiveActions = [...this.state.correctiveActions];
                correctiveActions[index].errorMessageImage = "Please select a valid image file (PNG or JPEG).";
                this.setState({ correctiveActions: correctiveActions });
            }
        }
    }

    changeImg(event: ChangeEvent<HTMLInputElement>, index: number) {
        const files = event.target.files;
        const correctiveActions = [...this.state.correctiveActions];

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            correctiveActions[index].uploadedImagePreview = fileReader.result;
            this.setState({ correctiveActions: correctiveActions });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleChangeDueDate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const correctiveActions = [...this.state.correctiveActions];
        correctiveActions[index].dueDate = new Date(event.target.value);
        this.setState({ correctiveActions: correctiveActions });
    }
    handleChangeDueDatePicker = (selectedDate: any, index: number) => {
        if (selectedDate && typeof selectedDate === 'object') {
            selectedDate?.setHours(selectedDate.getHours() + 5);
            selectedDate?.setMinutes(selectedDate.getMinutes() + 30);
            const formatedDate = selectedDate?.toISOString().replace(/(,|\s)+/g, 'T');

            const correctiveActions = [...this.state.correctiveActions];
            correctiveActions[index].dueDate = new Date(formatedDate);
            this.setState({ correctiveActions: correctiveActions });
        }
    }
    // Customizable Area End
}
