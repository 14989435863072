import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Stories from "./Stories.web";
import PostCreationPopUp from "./postCreationPopUp/PostCreationPopUp.web";
import { PostView } from "./PostView.web";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      Font:"Clother"
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.setShowPostPopUp = this.setShowPostPopUp.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    this.state.showPostPopUp &&
      prevState.showPostPopUp !== this.state.showPostPopUp &&
      this.getPostSettings();
  }
  setShowPostPopUp = (value: boolean) => {
    this.setState({ showPostPopUp: value });
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const UserListItem = (imgSrc: any, userName: any) => (
      <ListItem>
        <ListItemAvatar style={{ minWidth: "36px" }}>
          <Avatar
            src={String(imgSrc)}
            style={{ width: 24, height: 24, cursor: "pointer" }}
            alt="User"
          />
        </ListItemAvatar>
        <ListItemText primary={userName} />
        <ListItemText style={{ textAlign: "right" }}>
          <span style={webStyles.icon}> 10 </span>
        </ListItemText>
      </ListItem>
    );
    return (
      <ThemeProvider theme={theme}>
        <Box>
          <Box padding="15px" style={{ backgroundColor: "#eee",fontFamily:'ClotherRegular' }}>
            <Grid container style={{ gap: "40px" }}>
              <Grid item xs={8} style={{ paddingLeft: "20px" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  bgcolor="white"
                  p={2}
                  borderRadius={8}
                  boxShadow={1}
                  mt={2}
                >
                  <Stories navigation={undefined} />
                </Box>
                {this.state.isLoading ? (
                  <div style={webStyles.loadingStyle}>
                    <CircularProgress />
                  </div>
                ) : (
                  this.state.dashboardData?.map((postData: any) => (
                    <PostView key={postData.id} postData={postData} />
                  ))
                )}
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={webStyles.customButton}
                  onClick={() => this.setShowPostPopUp(true)}
                  data-test-id="setShowPostPopUp"
                >
                  <span
                    style={{
                      ...webStyles.customButtonText,
                      textTransform: "none",
                    }}
                  >
                    + Create a Post
                  </span>
                </Button>
                <List id="test" style={webStyles.listBox}>
                  <ListSubheader style={webStyles.headB}>
                    <h3>Broadcast Messages</h3>
                    <p style={webStyles.headp}>See All</p>
                  </ListSubheader>
                  <h3 style={{textAlign:'center',fontFamily:'ClotherBold'}}>Coming Soon</h3>
                </List>

                <List style={webStyles.listBox}>
                  <ListSubheader id="subh" style={webStyles.headB}>
                    <h3>Community</h3>
                    <p style={webStyles.headp}>See All</p>
                  </ListSubheader>

                 <h3 style={{textAlign:'center',fontFamily:'ClotherBold'}}>Coming Soon</h3>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {this.state.showPostPopUp && (
          <PostCreationPopUp
            setShowPostPopUp={this.setShowPostPopUp}
            postSettings={this.state.postSettings}
            loading={this.state.loading}
            createPost={this.createPost}
            userName={this.state.userName}
            postCreationFinished={this.state.postCreationFinished}
            buLocationCategory={this.state.buLoaction}
            machineWorkLocation={this.state.machineWorkLocation}
            machineChangeStatus={this.getMachineWorkLocationAPI}
            loaderData={this.state.postDataLoading}
            />
        )}
        <Loader loading={this.state.loader} />
        </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  topbar: { height: "100px", padding: "0 40px" },
  sidebar: {
    background: "#FFF",
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
  },
  icon: {
    fontFamily: "clotherbold",
    backgroundColor: "#eee",
    borderRadius: "50%",
    padding: "9px",
    color: "#A1A1A1",
    fontSize: "12px",
  },
  descrp: {
    fontSize: "18px",
    fontFamily: "clotherRegular",
    color: "#828282",
  },
  
  listBox: {
    borderRadius: "8px",
    background: "#fff",
    marginBottom: "25px",
  },
  headB: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
    lineHeight: "20px",
    fontSize: "18px",
    fontFamily: "Clotherbold",
    color: "#18181E",
    alignItems: "center",
  },
  headp: {
    fontSize: "12px",
    color: "#9519EF",
    cursor: "pointer",
  },
  customButton: {
    display: "flex",
    width: "100%",
    height: "46px",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#8735E1",
    marginBottom: "20px",
    marginTop: "20px",
  },
  customButtonText: {
    color: "#FFF",
    fontFamily: "Clotherbold",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "-0.408px",
    textTransform: "none",
  },
  loadingStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
};
// Customizable Area End
