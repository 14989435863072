import React from "react";

// Customizable Area Start
import {
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NotificationsBlock } from '../../../components/src/NotificationsBlock';
import { chevronRight } from '../../../components/src/assets';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.loading ? <CircularProgress data-test-id='spinner' style={webStyles.notificationPendingBlock as React.CSSProperties} /> :
        <>
          <Box 
            style={{ fontWeight: 'bold' }} 
            p={3} 
            pb={0} 
            display='flex'>
              <Typography 
                variant='body2' 
                style={{
                  ...webStyles.breadCrumbs, 
                  color: '#A1A1A1' 
                  } as React.CSSProperties}>
                    Home
              </Typography>
              <img 
                src={chevronRight} 
                alt="chevron right" 
                height='20' 
                width='20' 
                style={webStyles.breadCrumbs as React.CSSProperties} />
              <Typography 
              variant='body2' 
              style={{ 
                ...webStyles.breadCrumbs, 
                color: '#8735E1' } as React.CSSProperties
                }>
                  Notifications
              </Typography>
          </Box>
          <Typography 
          variant='h5' 
          style={{ 
            fontWeight: 'bold', 
            padding: '1.6rem' }}>Notifications</Typography>
          <Box pb={10}>
            {<NotificationsBlock notifications={this.state.data} getNotifications={() => {}} fullScreen postView={this.postView}/>}
          </Box>
        </>}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyles = {
  breadCrumbs: {
      paddingRight: '0.2rem',
      fontWeight: '700'
  },
  notificationPendingBlock: {
    width: '60px',
    height: '60px',
    color: '#8735E1',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
};
// Customizable Area End
