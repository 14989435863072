import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  activeStep: () => void;
  privousStep: () => void;
  gotoLast: boolean;
  notGoToPrevious: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  roadData: any;
  isSelected: any;
  homeDataEnviroment: any;
  homeDataHelth: any;
  homeDataSafety: any;
  stepValue: number;
  WorkDataEnviroment: any;
  WorkDataHelth: any;
  WorkDataSafety: any;
  HomeEnviromentData: any;
  HomeSafetyData: any;
  HomeHelthData: any;
  WorkEnvirmentState: any;
  WorkHelthState: any;
  WorkSafetyState: any;
  getRiskCategoryData: any;
  isLoadingData: boolean;
  // Customizable Area End
}
interface SS {}

export default class RiskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  RoadGetDataAPICallID: string = "";
  HomeGetDataAPICallID: string = "";
  WorkGetDataAPICallID: string = "";
  RiskLevelDataUpdateCallID: string = "";
  getAccountInfoCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      roadData: [],
      isSelected: [],
      stepValue: 1,
      HomeEnviromentData: [],
      HomeSafetyData: [],
      HomeHelthData: [],
      WorkDataEnviroment: [],
      WorkDataHelth: [],
      WorkDataSafety: [],
      homeDataEnviroment: [],
      homeDataHelth: [],
      homeDataSafety: [],
      WorkEnvirmentState: [],
      WorkHelthState: [],
      WorkSafetyState: [],
      getRiskCategoryData: [],
      isLoadingData: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRoadDataAPI();
    this.getHomeDataAPI();
    this.getWorkDataAPI();
    this.GetAccountInfoAPI();

    if (this.props.gotoLast) {
      this.setState({ stepValue: 7 });
      this.props.notGoToPrevious();
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.RoadGetDataAPICallID) {
          this.setState({
            roadData: webResponseJson.broad_risk_categories.mode_type,
          });
        } else if (webApiRequestCallId === this.HomeGetDataAPICallID) {
          this.setState({
            HomeEnviromentData:
              webResponseJson.broad_risk_categories.Environment,
            HomeHelthData: webResponseJson.broad_risk_categories.Health,
            HomeSafetyData: webResponseJson.broad_risk_categories.Safety,
          });
        } else if (webApiRequestCallId === this.WorkGetDataAPICallID) {
          this.setState({
            WorkDataEnviroment:
              webResponseJson.broad_risk_categories.Environment,
            WorkDataHelth: webResponseJson.broad_risk_categories.Health,
            WorkDataSafety: webResponseJson.broad_risk_categories.Safety,
          });
        } else  /* istanbul ignore if */ if (webApiRequestCallId === this.getAccountInfoCallID) {
          this.setState({isLoadingData:false})
          const selecteDataRoad = webResponseJson?.data?.attributes.broad_risk_category
            .find((item: any) => item.type === "Road")
            ?.data.map((item: any) => item.id);
          this.setState({ isSelected: selecteDataRoad });
          const selecteDataHomeEnviroment = webResponseJson?.data?.attributes?.broad_risk_category
            .find((Home: any) => Home.type === "Home")
            ?.data.filter((HomeSub: any) => HomeSub.sub_type === "Environment")
            .map((enviroment: any) => enviroment.id);

          const selectDataHomeHealth = webResponseJson?.data.attributes.broad_risk_category
            .find((item: any) => item.type === "Home")
            ?.data.filter((item: any) => item.sub_type === "Health")
            .map((item: any) => item.id);

          const selectDataHomeSafety = webResponseJson?.data.attributes.broad_risk_category
            .find((item: any) => item.type === "Home")
            ?.data.filter((item: any) => item.sub_type === "Safety")
            .map((item: any) => item.id);

          this.setState({
            homeDataEnviroment: selecteDataHomeEnviroment,
            homeDataHelth: selectDataHomeHealth,
            homeDataSafety: selectDataHomeSafety,
          });

          const selecteDataWorkEnviroment = webResponseJson?.data.attributes.broad_risk_category
            .find((item: any) => item.type === "Work")
            ?.data.filter((item: any) => item.sub_type === "Environment")
            .map((item: any) => item.id);

          this.setState({ WorkEnvirmentState: selecteDataWorkEnviroment });
          const selectDataWorkHealth = webResponseJson?.data.attributes.broad_risk_category
            .find((item: any) => item.type === "Work")
            ?.data.filter((item: any) => item.sub_type === "Health")
            .map((item: any) => item.id);

          this.setState({ WorkHelthState: selectDataWorkHealth });
          const selectDataWorkSafety = webResponseJson?.data.attributes.broad_risk_category
            .find((item: any) => item.type === "Work")
            ?.data.filter((item: any) => item.sub_type === "Safety")
            .map((item: any) => item.id);

          this.setState({ WorkSafetyState: selectDataWorkSafety });
        } else if (webApiRequestCallId === this.RiskLevelDataUpdateCallID) {
          this.GetAccountInfoAPI();
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getRoadDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_road_broad_risk_categories`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.RoadGetDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getHomeDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_home_broad_risk_categories`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.HomeGetDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getWorkDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_work_broad_risk_categories`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.WorkGetDataAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  handleSelect = (vehical: string) => {
    this.setState((prevState) => {
      const isSelected = Array.isArray(prevState.isSelected)
        ? [...prevState.isSelected]
        : [];

      const vehicalIndex = isSelected.indexOf(vehical);

      if (vehicalIndex === -1) {
        isSelected.push(vehical);
      } else {
        isSelected.splice(vehicalIndex, 1);
      }

      return { isSelected };
    });
  };

  handleSelectHomeEnvirenmentData = (EnivermentSelect: string) => {
    this.setState((prevState) => {
      const homeDataEnviroment = Array.isArray(prevState.homeDataEnviroment)
        ? [...prevState.homeDataEnviroment]
        : [];
      const HomeIndex = homeDataEnviroment.indexOf(EnivermentSelect);
      if (HomeIndex === -1) {
        homeDataEnviroment.push(EnivermentSelect);
      } else {
        homeDataEnviroment.splice(HomeIndex, 1);
      }

      return { homeDataEnviroment };
    });
  };

  handleSelectHomeHelthData = (HelthSelect: string) => {
    this.setState((prevState) => {
      const homeDataHelth = Array.isArray(prevState.homeDataHelth)
        ? [...prevState.homeDataHelth]
        : [];
      const HomeIndex = homeDataHelth.indexOf(HelthSelect);
      if (HomeIndex === -1) {
        homeDataHelth.push(HelthSelect);
      } else {
        homeDataHelth.splice(HomeIndex, 1);
      }

      return { homeDataHelth };
    });
  };

  handleSelectHomeSafetyData = (SafetySelect: string) => {
    this.setState((prevState) => {
      const homeDataSafety = Array.isArray(prevState.homeDataSafety)
        ? [...prevState.homeDataSafety]
        : [];
      const HomeIndex = homeDataSafety.indexOf(SafetySelect);
      if (HomeIndex === -1) {
        homeDataSafety.push(SafetySelect);
      } else {
        homeDataSafety.splice(HomeIndex, 1);
      }

      return { homeDataSafety };
    });
  };
  handleSelectWorkEnviromentData = (workEnviromentSelected: string) => {
    this.setState((prevState) => {
      const WorkEnvirmentState = Array.isArray(prevState.WorkEnvirmentState)
        ? [...prevState.WorkEnvirmentState]
        : [];
      const WorkIndex = WorkEnvirmentState.indexOf(workEnviromentSelected);
      if (WorkIndex === -1) {
        WorkEnvirmentState.push(workEnviromentSelected);
      } else {
        WorkEnvirmentState.splice(WorkIndex, 1);
      }

      return { WorkEnvirmentState };
    });
  };

  handleSelectWorkHelthData = (workHelthSelected: string) => {
    this.setState((prevState) => {
      const WorkHelthState = Array.isArray(prevState.WorkHelthState)
        ? [...prevState.WorkHelthState]
        : [];
      const WorkIndex = WorkHelthState.indexOf(workHelthSelected);
      if (WorkIndex === -1) {
        WorkHelthState.push(workHelthSelected);
      } else {
        WorkHelthState.splice(WorkIndex, 1);
      }

      return { WorkHelthState };
    });
  };

  handleSelectWorkSafetyData = (WorkSeftySelected: string) => {
    this.setState((prevState) => {
      const WorkSafetyState = Array.isArray(prevState.WorkSafetyState)
        ? [...prevState.WorkSafetyState]
        : [];
      const WorkIndex = WorkSafetyState.indexOf(WorkSeftySelected);
      if (WorkIndex === -1) {
        WorkSafetyState.push(WorkSeftySelected);
      } else {
        WorkSafetyState.splice(WorkIndex, 1);
      }

      return { WorkSafetyState };
    });
  };

  setStepValue = () => {
    this.setState((prevState) => ({ stepValue: prevState.stepValue + 1 }));
  };

  previousStep = () => {
    if (this.state.stepValue === 1) {
      this.props.privousStep();
    }
    this.setState((prevState) => ({
      stepValue: prevState.stepValue - 1,
    }));
  };

  RiskLevelPostDataAPI = async () => {
    const {
      homeDataHelth,
      homeDataEnviroment,
      homeDataSafety,
      WorkHelthState,
      WorkSafetyState,
      WorkEnvirmentState,
      isSelected,
    } = this.state;

    const AllArrayData = [
      ...(isSelected ?? []),
      ...(homeDataEnviroment ?? []),
      ...(homeDataHelth ?? []),
      ...(homeDataSafety ?? []),
      ...(WorkHelthState ?? []),
      ...(WorkSafetyState ?? []),
      ...(WorkEnvirmentState ?? [])
    ];

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: tokenValue,
    };

    const httpBody = {
      broad_risk_category_ids: AllArrayData,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/save_broad_risk_categories`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.RiskLevelDataUpdateCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  SubmitButton = () => {
    this.setStepValue();
    if (this.state.stepValue === 7) {
      this.RiskLevelPostDataAPI();
    }
  };

  GetAccountInfoAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ isLoadingData: true });
    this.getAccountInfoCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  // Customizable Area End
}
