export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const buttonFilterActive = require("../assets/active-filter.png");
export const buttonFilter = require("../assets/button-filter.png");
export const calender = require("../assets/calender.png");
export const location = require("../assets/location.png");
export const edit = require("../assets/edit.png");
export const calendarLogo = require("../assets/calendarLogo.png");
export const noChecklistImg = require("../assets/noChecklistImg.png");
export const profile = require("../assets/profile.png");
export const upload = require("../assets/upload.svg");
