import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Card,
    CardContent,
    Chip,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { noChecklistImg, profile, calender } from "./assets";

const today = new Date()

// Customizable Area End

import MachineCheckpointsController, {
    Props,
} from "./MachineCheckpointsController";

export default class MachineCheckpoints extends MachineCheckpointsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noCheckpoints = () => {
        return (
            <Box className="mainBox">
                <Grid container style={{ height: '55vh' }} justifyContent='center' alignItems='center' direction="column">
                    <Grid>
                        <img
                            height="70px"
                            width="70px"
                            src={noChecklistImg}
                            alt="No Checklist Available"
                        />
                    </Grid>
                    <Grid>
                        <Typography className="addCheckpointText">No Checkpoints Available</Typography>
                    </Grid>
                    <Grid>
                        <Typography className="getStartedText">Add Checkpoints to get started.</Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    checkpointList = (val: any) => {
        return (<Grid item xs={12} sm={6} md={4}>
            <Card className="cardStyle"
                data-test-id="checkpoint_list_card">
                <CardContent>
                    <Box className="cardContentMainBox">
                        <Typography className="cardMainHeading trimWords">Neutral Earthing</Typography>
                        <Chip className="greenChip" label="Completed" />
                    </Box>
                    <Box className="imageAndText">
                        <img src={profile} alt="Profile" height={23} width={23} />
                        <Typography className="cardText trimWords">Last Checked by: John Doe</Typography>
                    </Box>
                    <Box className="imageAndText">
                        <img src={calender} alt="calender" height={23} width={23} />
                        <Typography className="cardText trimWords">Last checked on: 30/07/2023 </Typography>
                    </Box>
                    <Box className="imageAndText">
                        <img src={calender} alt="calender" height={23} width={23} />
                        <Typography className="cardText trimWords">Next Due Date: 30/07/2023</Typography>
                    </Box>
                </CardContent>
            </Card>
        </Grid>)
    }
    // Customizable Area End

    render() {
        const { role, checkpoint, selectedMachineData } = this.state;
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Wrapper>
                        <Container maxWidth={"xl"}>
                            <Box className="headingWrapper">
                                <Typography className="subHeadingText">Audit</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText activeHeadingText">{selectedMachineData?.equipment_name}</Typography>
                            </Box>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item className="trimWords"><Typography className="mainHeading">{selectedMachineData?.equipment_name}</Typography></Grid>
                                <Grid item>
                                    {role !== 'worker' && checkpoint.length !== 0 &&
                                        <Box className="addCheckpointButton">
                                            <Button
                                                data-test-id="add_new_checkpoint"
                                                className="addCheckpointButtonStyle"
                                                type="submit"
                                                onClick={() => this.handleAddCheckpointBtn()}
                                            >
                                                Add New Checkpoint
                                            </Button>
                                        </Box>}
                                </Grid>
                            </Grid>

                            <Box>
                                <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                    {checkpoint.map((val) => {
                                        return this.checkpointList(val);
                                    })}
                                </Grid>
                            </Box>
                            {checkpoint.length === 0 && this.noCheckpoints()}
                            {checkpoint.length === 0 && <Box className="addButton">
                                <Button
                                    data-test-id="add_new_checkpoint"
                                    variant="contained"
                                    className="add-btn"
                                onClick={this.handleAddCheckpointBtn}
                                >
                                    Add
                                </Button>
                            </Box>}
                        </Container>
                    </Wrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    padding: "20px 0",
    width: "100%",
    backgroundColor: "#f7f5f5",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .subHeadingText": {
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
    },
    "& .headingWrapper": {
        gap: "10px",
        display: "flex",
    },
    "& .addCheckpointText": {
        color: '#2B2A29',
        fontSize: '20px',
        fontWeight: 400,
        fontFamily: 'ClotherBold',
    },
    "& .addCheckpointButtonStyle": {
        border: "1px solid #8735E1",
        textTransform: "none",
        padding: "20px 12px",
        fontSize: "16px",
        fontWeight: 600,
        height: "30px",
        color: "#8735E1",
        borderRadius: "8px",
        "@media(max-width:601px)": {
            lineHeight: "15px",
            height: "45px"
        },
    },
    "& .getStartedText": {
        color: '#A1A1A1',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'ClotherRegular',
    },
    "& .cardStyle": {
        background: "#fff",
        border: "1.5px solid lightgray",
        cursor: "pointer",
        padding: "4px 2px",
        boxShadow: "none",
        borderRadius: "8px",
    },
    "& .addCheckpointButton": {
        display: "flex",
        gap: 10,
        alignItems: "center",
    },
    "& .activeHeadingText": {
        lineHeight: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontFamily: "ClotherRegular",
        cursor: "pointer"
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .cardMainHeading": {
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: "Clother",
    },
    "& .imageAndText": {
        display: "flex",
        gap: 15,
        marginTop: 10
    },
    "& .cardText": {
        color: "#A1A1A1",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Clother",
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .mainHeading": {
        fontWeight: 700,
        margin: "10px 0",
        color: "#2B2A29",
        fontSize: "26px",
        fontStyle: "normal",
        fontFamily: "ClotherBold",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '152px',
        padding: '10px 15px',
        width: '100%',
        outline: 'none'
    },
    "& .addButton": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        margin: "25px 0px 0px 10px"
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .blueChip": {
        backgroundColor: "#DDF6FF",
        color: "#00b0f0",
        borderRadius: "15px",
        "& .MuiChip-label": {
            padding: "0px 5px",
            fontWeight: 600
        }
    },
    "& .innerGrid": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .mainBox": {
        backgroundColor: 'white',
        width: "100%",
        margin: "15px 0px"
    },
    "& .add-btn": {
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .cardContentMainBox": {
        display: "flex",
        justifyContent: "space-between",
        gap: "15px"
    },
    "& .orangeChip": {
        backgroundColor: "#FFF4DE",
        color: "#DF9300",
        borderRadius: "12px",
        "& .MuiChip-label": {
            padding: "0px 5px",
            fontWeight: 600
        }
    },

    "& .redChip": {
        backgroundColor: "#FFEDED",
        color: "#FA0000",
        borderRadius: "15px",
        "& .MuiChip-label": {
            padding: "0px 5px",
            fontWeight: 600
        }
    },
    "& .greenChip": {
        backgroundColor: "#DBFFED",
        color: "#009A4F",
        borderRadius: "15px",
        height: "25px",
        "& .MuiChip-label": {
            padding: "0px 5px",
            fontWeight: 600
        }
    },

});
// Customizable Area End
