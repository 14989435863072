// Customizable Area Start
export const History = require("../assets/History.png");
export const Close = require("../assets/Close.png");
export const location = require("../assets/location.png");
export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const imgsplash = require("../assets/splash.png");
export const imgambarsar = require("../assets/ambarsar.png");
export const imgsurat = require("../assets/surat.png");
export const Maplogo = require("../assets/Map.png");
export const Plus = require("../assets/Plus.png");
export const startpin = require("../assets/Start_pin.png");
export const destinationPin = require("../assets/destination_pin.png");
export const closePostIcon = require("../assets/checked.svg");
export const SpeachToText = require("../assets/text-to-speak.svg")
export const buttonFilter = require("../assets/button-filter.png");
export const buttonFilterActive = require("../assets/active-filter.png");
export const buttonSort = require("../assets/button-sort.png");
export const buttonSortActive = require("../assets/button-sort-active.png");
export const locationIcon = require("../assets/location3.png");
export const clockIcon = require("../assets/time.png");
export const likeIcon = require("../assets/like.png");
export const commentIcon = require("../assets/comment.png");
export const shareIcon = require("../assets/share.png");
export const optionsIcon = require("../assets/options.png");
export const mic = require("../assets/mic.png");
export const mikeIcon = require("../assets/mike.png");
export const calendarIcon = require("../assets/calendar.png");
export const closeIcon = require("../assets/close-icon.png");
export const profileIcon = require("../assets/profile.png");
export const plusIcon = require("../assets/plusIcon.png");
export const trueIcon = require("../assets/view_mark.png");
export const greenPlusIcon = require("../assets/greenPlusIcon.png");
export const redPlusIcon = require("../assets/redPlusIcon.png");
export const selectedTrue = require("../assets/selectedTrue.png");
export const yellowPlusIcon = require("../assets/yellowPlusIcon.png");
export const singleSelectIcon = require("../assets/image_add.png");
export const MultiSelectIcon = require("../assets/image_combine.png");
export const selectRemoveIcon = require("../assets/removeIcon.png");
export const micStart = require("../assets/mic_Start.png");
// Customizable Area End
