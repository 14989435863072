import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  errorMsg: string;
  loading: boolean;
  machineData: {
    id: string;
    type: string;
    attributes: {
      id: number;
      equipment_name: string;
      equipment_number: number;
      due_date: string;
      account_id: number;
      location_id: number;
      audit_category_id: number;
      category: string;
      location: string;
    }
  }[];
  locations: {
    id: string;
    name: string;
  }[];
  departments: {
    id: string;
    name: string;
  }[];
  role: string;
  openFilterModal: boolean;
  selectedDepartment: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Audittrail2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMachinesApiCallId: string = "";
  getDepartmentsApiCallId: string = "";
  getLocationsApiCallId: string = "";
  getFilterDepartmentApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      errorMsg: "",
      loading: true,
      machineData: [],
      role: '',
      openFilterModal: false,
      departments: [],
      locations: [],
      selectedDepartment: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getMachinesApiCallId) {
        this.handleMachinesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getDepartmentsApiCallId) {
        this.handleDepartmentsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getFilterDepartmentApiCallId) {
        this.handleFilterDepartmentsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getLocationsApiCallId) {
        this.handleLocationsApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });

    this.getDepartmentsApiCall();
    this.getLocationApiCall();
  }

  handleLocationsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ locations: responseJson.data }, () => {
        if (this.state.departments.length > 0) {
          this.getMachinesApiCall();
        }
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleMachinesApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      const data = responseJson.data.map((val: any) => (
        {
          ...val,
          attributes: {
            ...val.attributes,
            location: this.state.locations.filter((value) => value.id === val.attributes.location_id)[0]?.name,
            category: this.state.departments.filter((value) => value.id === val.attributes.audit_category_id)[0]?.name
          }
        }
      ));
  
      this.setState({ machineData: data, role: responseJson.designation, loading: false })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }
  handleDepartmentsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({ departments: responseJson.data }, () => {
        if (this.state.locations.length > 0) {
          this.getMachinesApiCall();
        }
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleFilterDepartmentsApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      const data = responseJson.data.map((val: any) => (
        {
          ...val,
          attributes: {
            ...val,
            location: this.state.locations.filter((value) => value.id === val.location_id)[0].name,
            category: this.state.departments.filter((value) => value.id === val.audit_category_id)[0].name
          }
        }
      ));
  
      this.setState({ machineData: data, loading: false })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }

  getLocationApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getLocationRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLocationsApiCallId = getLocationRequestMsg.messageId;
    getLocationRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLocationsApiEndPoint
    );
    getLocationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getLocationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getLocationRequestMsg.id, getLocationRequestMsg);
  }

  getDepartmentsApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getDepartmentRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDepartmentsApiCallId = getDepartmentRequestMsg.messageId;
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesApiEndPoint
    );
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDepartmentRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getDepartmentRequestMsg.id, getDepartmentRequestMsg);
  }

  getMachinesApiCall = () => {
    this.setState({ loading: true })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getMachinesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMachinesApiCallId = getMachinesRequestMsg.messageId;
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMachinesApiEndPoint
    );
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMachinesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getMachinesRequestMsg.id, getMachinesRequestMsg);

  }

  
  applyFiters = () => {
    this.closeFilterModal();
    if(this.state.selectedDepartment.length > 0){
    this.getFilterDepartmentApiCall();
    }else{
      this.getMachinesApiCall();
    }
  }
  getFilterDepartmentApiCall = () => {
    this.setState({ loading: true })
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilterDepartmentApiCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFilterDepartmentApiEndPoint}?id=${this.state.selectedDepartment[0]}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  closeFilterModal = () => {
    this.setState({ openFilterModal: false });
  };

  handleOpenFilterModal = () => {
    this.setState({ openFilterModal: true })
  }

  handleDepartmentClear = () => {
    this.setState({ selectedDepartment: [] });
  };

  handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      let updatedSelectedDepartment: any = [...prevState.selectedDepartment];
      if (!checked) {
        updatedSelectedDepartment = updatedSelectedDepartment.filter((department: string) => department !== value)
      } else {
        updatedSelectedDepartment.push(value);
      }
      return {
        selectedDepartment: updatedSelectedDepartment,
      };
    });
  }

  handleClearAll = () => {
    this.setState({ selectedDepartment: [] })
  }
  navigateToAddCheckpoint = (id: string) => {
    setStorageData('machineid', id);
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MachineCheckpoints");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  handleAddNewMachine = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "AddMachine");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
