// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import { Box, Grid, Hidden } from '@material-ui/core';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Cfobjectidentification2 from '../../blocks/cfobjectidentification2/src/Cfobjectidentification2';
import Cfvideoobjectidentification from '../../blocks/cfvideoobjectidentification/src/Cfvideoobjectidentification';
import Matchalgorithm2 from '../../blocks/matchalgorithm2/src/Matchalgorithm2';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import Likeapost2 from '../../blocks/likeapost2/src/Likeapost2';
import Watermark from '../../blocks/watermark/src/Watermark';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Notificationsettings from '../../blocks/notificationsettings/src/Notificationsettings';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Languagesupport4 from '../../blocks/languagesupport4/src/Languagesupport4';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Tabletdesign43 from '../../blocks/tabletdesign43/src/Tabletdesign43';
import Microsoftdefenderintegration from '../../blocks/microsoftdefenderintegration/src/Microsoftdefenderintegration';
import CameraAccess from '../../blocks/cameraaccess/src/CameraAccess';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Objectidentifier2 from '../../blocks/objectidentifier2/src/Objectidentifier2';
import Googlevision from '../../blocks/googlevision/src/Googlevision';
import ContentFlag from '../../blocks/contentflag/src/ContentFlag';
import Objectidentificationinvideo from '../../blocks/objectidentificationinvideo/src/Objectidentificationinvideo';
import Story2 from '../../blocks/story2/src/Story2';
import Emaillists2 from '../../blocks/emaillists2/src/Emaillists2';
import AssessmentTest from '../../blocks/assessmenttest/src/AssessmentTest';
import LanguageSupport from '../../blocks/languagesupport/src/LanguageSupport';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Notifications from '../../blocks/notifications/src/Notifications';
import Location from '../../blocks/location/src/Location';
import Leaderboard from '../../blocks/leaderboard/src/Leaderboard';
import Servicespecificsettingsadmin2 from '../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2';
import Imagetraininginchecklist from '../../blocks/imagetraininginchecklist/src/Imagetraininginchecklist';
import Cfobjecttagging from '../../blocks/cfobjecttagging/src/Cfobjecttagging';
import Geofence from '../../blocks/geofence/src/Geofence';
import Audittrail2 from '../../blocks/audittrail2/src/Audittrail2';
import QuestionBank from '../../blocks/questionbank/src/QuestionBank';
import Logintimeout from '../../blocks/logintimeout/src/Logintimeout';
import FormApprovalWorkflow from '../../blocks/formapprovalworkflow/src/FormApprovalWorkflow';
import MyPosts from '../../blocks/formapprovalworkflow/src/MyPosts.web';
import PublishPost from '../../blocks/formapprovalworkflow/src/PublishPost.web';
import ViewPost from '../../blocks/formapprovalworkflow/src/ViewPost.web';
import SupervisorPostCreation from '../../blocks/formapprovalworkflow/src/SupervisorPostCreation/SupervisorPostCreation.web';
import Tabletdesign1 from '../../blocks/tabletdesign1/src/Tabletdesign1';
import Karmapoints2 from '../../blocks/karmapoints2/src/Karmapoints2';
import Dataencryption from '../../blocks/dataencryption/src/Dataencryption';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import Informationmasking from '../../blocks/informationmasking/src/Informationmasking';
import QrCodes from '../../blocks/qrcodes/src/QrCodes';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Accountscoreranking from '../../blocks/accountscoreranking/src/Accountscoreranking';
import Repeatingtasks2 from '../../blocks/repeatingtasks2/src/Repeatingtasks2';
import Captcha from '../../blocks/captcha/src/Captcha';
import Share from '../../blocks/share/src/Share';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import Hashtags from '../../blocks/hashtags/src/Hashtags';
import CreateComment from '../../blocks/comments/src/CreateComment';
import Sorting from '../../blocks/sorting/src/Sorting';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen.web';
import Tabletdesign32 from '../../blocks/tabletdesign32/src/Tabletdesign32';
import Conversationthreading2 from '../../blocks/conversationthreading2/src/Conversationthreading2';
import Gamification from '../../blocks/educationalgamification/src/Gamification';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Scoring from '../../blocks/scoring/src/Scoring';
import Videos from '../../blocks/videos/src/Videos';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/otp-input-confirmation/src/ForgotPasswordOTP.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import Tabletdesign22 from '../../blocks/tabletdesign22/src/Tabletdesign22';
import Translation from '../../blocks/translation/src/Translation';
import Couponcodegenerator from '../../blocks/couponcodegenerator/src/Couponcodegenerator';
import BroadcastMessage from '../../blocks/broadcastmessage/src/BroadcastMessage';
import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
import Machinelearningmlimagecategorisation from '../../blocks/machinelearningmlimagecategorisation/src/Machinelearningmlimagecategorisation.web';
import Textidentification from '../../blocks/textidentification/src/Textidentification';
import Polling from '../../blocks/polling/src/Polling';
import Voicenotes from '../../blocks/voicenotes/src/Voicenotes';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import PerformanceTracker from '../../blocks/performancetracker/src/PerformanceTracker';
import ActionTrackerPost from '../../blocks/performancetracker/src/ActionTrackerPost.web';
import ActionTrackerForWorker from '../../blocks/performancetracker/src/ActionTrackerForWorker.web';
import ActionTrackerPostForm from '../../blocks/performancetracker/src/ActionTrackerPostForm.web';
import PtCoursesScreen from '../../blocks/performancetracker/src/PtCoursesScreen';
import PtTestsForCourseScreen from '../../blocks/performancetracker/src/PtTestsForCourseScreen';
import PtStudentsOnCourseScreen from '../../blocks/performancetracker/src/PtStudentsOnCourseScreen';
import PtTestScoresForStudentScreen from '../../blocks/performancetracker/src/PtTestScoresForStudentScreen';
import PtBasicUserCoursesScreen from '../../blocks/performancetracker/src/PtBasicUserCoursesScreen';
import PtBasicUserAllCoursesScreen from '../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen';
import PtStudentRankingScreen from '../../blocks/performancetracker/src/PtStudentRankingScreen';
import PtStudentInfoScreen from '../../blocks/performancetracker/src/PtStudentInfoScreen';
import Analytics from '../../blocks/analytics/src/Analytics';
import PhotoLibrary from '../../blocks/photolibrary/src/PhotoLibrary';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import LanguageOptions from '../../blocks/languageoptions/src/LanguageOptions';
import ReviewApprovalAdmin from '../../blocks/reviewandapproval/src/ReviewApprovalAdmin';
import ReviewApprovalBasicUser from '../../blocks/reviewandapproval/src/ReviewApprovalBasicUser';
import Dashboard from '../../blocks/dashboard/src/Dashboard.web';
import Search from '../../blocks/machinelearningmlimagecategorisation/src/Search.web';
import AssociatedImages from '../../blocks/machinelearningmlimagecategorisation/src/AssociatedImages.web';
import ImageScreen from '../../blocks/machinelearningmlimagecategorisation/src/ImageScreen.web';
import MyProfile from '../../blocks/dashboard/src/myProfile/MyProfile.web';
import EditProfile from '../../blocks/dashboard/src/myProfile/EditProfile.web';
import DashboardHeader from '../../blocks/dashboard/src/DashboradHeader.web';
import ContentManagement from '../../blocks/contentmanagement/src/ContentManagement.web';
import SidebarMenu from '../../components/src/SidebarMenu.web';
import EmailListDataListing from '../../blocks/emaillists2/src/EmailListDataListing.web';
import DeleteAccount from '../../blocks/email-account-registration/src/DeleteProfile.web';
import Account from '../../blocks/email-account-registration/src/Accounts.web';
import UserProfileBasic from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import ScheduleInspection from '../../blocks/repeatingtasks2/src/ScheduleInspection.web';
import AddMachine from '../../blocks/audittrail2/src/AddMachine.web'
import MachineCheckpoints from '../../blocks/audittrail2/src/MachineCheckpoints.web'
import NonCompiledAssignment from '../../blocks/audittrail2/src/NonCompiledAssignment.web'
import WorkerCheckpoint from '../../blocks/audittrail2/src/WorkerCheckpoint.web'
import ManagerCheckpoint from '../../blocks/audittrail2/src/ManagerCheckpoint.web'
import WorkerCheckpointDetail from '../../blocks/audittrail2/src/WorkerCheckpointDetail.web';
import CheckpointDetail from '../../blocks/audittrail2/src/CheckpointDetail.web';
import DetailPlannedInspection from '../../blocks/repeatingtasks2/src/DetailPlannedInspection.web';
import SafetyInchargeApprovalForm from "../../blocks/formapprovalworkflow/src/SafteyIncharegeApprovalForms.web";
import Help from "../../blocks/user-profile-basic/src/Help.web";
import HelpDetails from "../../blocks/user-profile-basic/src/HelpPrivacyPolicy.web";
import PrivacyPolicy from "../../blocks/user-profile-basic/src/PrivacyPolicy.web";
import SafetyCouncilPost from "../../blocks/analytics/src/SafetyCouncilPosts.web";
import SafetyCouncilPostDetail from "../../blocks/analytics/src/ViewPost.web";
import SafetyCouncilForm from "../../blocks/analytics/src/SafetyCouncilForm.web";
import SafetyCouncilAssignForm from "../../blocks/analytics/src/SafetyCouncilAssignForm.web";
const routeMap = {
  Cfobjectidentification2: {
    component: Cfobjectidentification2,
    path: '/Cfobjectidentification2'
  },
  Cfvideoobjectidentification: {
    component: Cfvideoobjectidentification,
    path: '/Cfvideoobjectidentification'
  },
  Matchalgorithm2: {
    component: Matchalgorithm2,
    path: '/Matchalgorithm2'
  },
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task'
  },
  Likeapost2: {
    component: Likeapost2,
    path: '/Likeapost2'
  },
  // Splashscreen:{
  //  component:Splashscreen,
  // path:"/Splashscreen"},
  Watermark: {
    component: Watermark,
    path: '/Watermark'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Languagesupport4: {
    component: Languagesupport4,
    path: '/Languagesupport4'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Tabletdesign43: {
    component: Tabletdesign43,
    path: '/Tabletdesign43'
  },
  Microsoftdefenderintegration: {
    component: Microsoftdefenderintegration,
    path: '/Microsoftdefenderintegration'
  },
  CameraAccess: {
    component: CameraAccess,
    path: '/CameraAccess'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Objectidentifier2: {
    component: Objectidentifier2,
    path: '/Objectidentifier2'
  },
  Googlevision: {
    component: Googlevision,
    path: '/Googlevision'
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag'
  },
  Objectidentificationinvideo: {
    component: Objectidentificationinvideo,
    path: '/Objectidentificationinvideo'
  },
  Story2: {
    component: Story2,
    path: '/Story2'
  },
  Emaillists2: {
    component: Emaillists2,
    path: '/Emaillists2'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  Leaderboard: {
    component: Leaderboard,
    path: '/Leaderboard'
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: '/Servicespecificsettingsadmin2'
  },
  Imagetraininginchecklist: {
    component: Imagetraininginchecklist,
    path: '/Imagetraininginchecklist'
  },
  Cfobjecttagging: {
    component: Cfobjecttagging,
    path: '/Cfobjecttagging'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  Audit: {
    component: Audittrail2,
    path: '/Audit',
    exact: true
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  Logintimeout: {
    component: Logintimeout,
    path: '/Logintimeout'
  },
  // Videolibrary:{
  //  component:Videolibrary,
  // path:"/Videolibrary"},
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  MyPosts: {
    component: MyPosts,
    path: '/MyPosts',
    exact: true
  },
  PublishPost: {
    component: PublishPost,
    path: '/PublishPost'
  },
  ViewPost: {
    component: ViewPost,
    path: '/MyPosts/ViewPost'
  },
  SupervisorPostCreation: {
    component: SupervisorPostCreation,
    path: '/MyPosts/SupervisorPostCreation'
  },
  Tabletdesign1: {
    component: Tabletdesign1,
    path: '/Tabletdesign1'
  },
  Karmapoints2: {
    component: Karmapoints2,
    path: '/Karmapoints2'
  },
  Dataencryption: {
    component: Dataencryption,
    path: '/Dataencryption'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  Informationmasking: {
    component: Informationmasking,
    path: '/Informationmasking'
  },
  QrCodes: {
    component: QrCodes,
    path: '/QrCodes'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Accountscoreranking: {
    component: Accountscoreranking,
    path: '/Accountscoreranking'
  },
  PlannedInspection: {
    component: Repeatingtasks2,
    path: '/PlannedInspection',
    exact: true
  },
  ScheduleInspection: {
    component: ScheduleInspection,
    path: '/PlannedInspection/ScheduleInspection'
  },
  DetailPlannedInspection: {
    component: DetailPlannedInspection,
    path: '/PlannedInspection/DetailPlannedInspection'
  },
  AddMachine: {
    component: AddMachine,
    path: '/Audit/AddMachine'
  },
  MachineCheckpoints: {
    component: MachineCheckpoints,
    path: '/Audit/MachineCheckpoints'
  },
  NonCompiledAssignment: {
    component: NonCompiledAssignment,
    path: '/Audit/NonCompiledAssignment'
  },
  WorkerCheckpoint: {
    component: WorkerCheckpoint,
    path: '/Audit/WorkerCheckpoint'
  },
  ManagerCheckpoint: {
    component: ManagerCheckpoint,
    path: '/Audit/ManagerCheckpoint'
  },
  WorkerCheckpointDetail: {
    component: WorkerCheckpointDetail,
    path: '/Audit/WorkerCheckpointDetail'
  },
  CheckpointDetail: {
    component: CheckpointDetail,
    path: '/Audit/CheckpointDetail'
  },
  Captcha: {
    component: Captcha,
    path: '/Captcha'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Hashtags: {
    component: Hashtags,
    path: '/Hashtags'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Tabletdesign32: {
    component: Tabletdesign32,
    path: '/Tabletdesign32'
  },
  Conversationthreading2: {
    component: Conversationthreading2,
    path: '/Conversationthreading2/:postID'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/Login'
  },
  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Tabletdesign22: {
    component: Tabletdesign22,
    path: '/Tabletdesign22'
  },
  Translation: {
    component: Translation,
    path: '/Translation'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  BroadcastMessage: {
    component: BroadcastMessage,
    path: '/BroadcastMessage'
  },
  Invitefriends: {
    component: Invitefriends,
    path: '/Invitefriends'
  },
  Machinelearningmlimagecategorisation: {
    component: Machinelearningmlimagecategorisation,
    path: '/Machinelearningmlimagecategorisation'
  },
  Textidentification: {
    component: Textidentification,
    path: '/Textidentification'
  },
  Polling: {
    component: Polling,
    path: '/Polling'
  },
  Voicenotes: {
    component: Voicenotes,
    path: '/Voicenotes'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/SignUp'
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: '/PerformanceTracker',
    exact: true
  },
  ActionTrackerPost: {
    component: ActionTrackerPost,
    path: '/PerformanceTracker/ActionTrackerPost'
  },
  ActionTrackerForWorker: {
    component: ActionTrackerForWorker,
    path: '/ActionTrackerForWorker'
  },
  ActionTrackerPostForm: {
    component: ActionTrackerPostForm,
    path: '/PerformanceTracker/ActionTrackerPostForm'
  },
  PtCoursesScreen: {
    component: PtCoursesScreen,
    path: '/PtCoursesScreen'
  },
  PtTestsForCourseScreen: {
    component: PtTestsForCourseScreen,
    path: '/PtTestsForCourseScreen'
  },
  PtStudentsOnCourseScreen: {
    component: PtStudentsOnCourseScreen,
    path: '/PtStudentsOnCourseScreen'
  },
  PtTestScoresForStudentScreen: {
    component: PtTestScoresForStudentScreen,
    path: '/PtTestScoresForStudentScreen'
  },
  PtBasicUserCoursesScreen: {
    component: PtBasicUserCoursesScreen,
    path: '/PtBasicUserCoursesScreen'
  },
  PtBasicUserAllCoursesScreen: {
    component: PtBasicUserAllCoursesScreen,
    path: '/PtBasicUserAllCoursesScreen'
  },
  PtStudentRankingScreen: {
    component: PtStudentRankingScreen,
    path: '/PtStudentRankingScreen'
  },
  PtStudentInfoScreen: {
    component: PtStudentInfoScreen,
    path: '/PtStudentInfoScreen'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: '/PhotoLibrary'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: '/ReviewApprovalAdmin'
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: '/ReviewApprovalBasicUser'
  },

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  AssociatedImages: {
    component: AssociatedImages,
    path: '/AssociatedImages'
  },
  ImageScreen: {
    component: ImageScreen,
    path: '/ImageScreen'
  },
  MyProfile: {
    component: MyProfile,
    path: '/MyProfile'
  },
  EditProfile: {
    component: EditProfile,
    path: '/EditProfile'
  },
  EmailListDataListing: {
    component: EmailListDataListing,
    path: '/EmailListDataListing'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Account: {
    component: Account,
    path: '/Account'
  },
  DeleteAccount: {
    component: DeleteAccount,
    path: '/delete/account',
    exact: true
  },
  UserProfileBasic: {
    component: UserProfileBasic,
    path: '/settings/account'
  },
  SafetyInchargeApprovalForm: {
    component: SafetyInchargeApprovalForm,
    path: '/SafetyInchargeApprovalForm/PublishPost',
  },
  Help: {
    component: Help,
    path: '/Help'
  },
  HelpPrivacyPolicy: {
    component: HelpDetails,
    path: "/policy/help",
    exact: true
  },
  HelpConditions: {
    component: HelpDetails,
    path: "/conditions/help",
    exact: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy",
    exact: true
  },
  SafetyCouncilPost: {
    component: SafetyCouncilPost,
    path: '/SafetyCouncilPost',
  },
  SafetyCouncilPostDetail: {
    component: SafetyCouncilPostDetail,
    path: '/SafetyCouncilPostDetail/SafetyCouncilPost'
  },
  SafetyCouncilForm: {
    component: SafetyCouncilForm,
    path: '/SafetyCouncilForm/SafetyCouncilPost'
  },
  SafetyCouncilAssignForm: {
    component: SafetyCouncilAssignForm,
    path: '/SafetyCouncilAssignForm/SafetyCouncilPost'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const loginRoute = [
  '/dashboard',
  '/myprofile',
  '/editprofile',
  '/account',
  '/delete/account',
  '/performancetracker',
  '/performancetracker/actiontrackerpost',
  '/performancetracker/actiontrackerpostform',
  '/settings/account',
  '/notifications',
  '/actiontrackerforworker',
  "/safetyinchargeapprovalform/publishpost",
  '/myposts',
  "/publishpost",
  '/myposts/viewpost',
  '/myposts/supervisorpostcreation',
  '/plannedinspection',
  '/plannedinspection/scheduleinspection',
  '/plannedinspection/detailplannedinspection',
  '/audit',
  '/audit/addmachine',
  '/audit/machinecheckpoints',
  '/audit/workercheckpoint',
  '/audit/managercheckpoint',
  '/audit/workercheckpointdetail',
  '/audit/checkpointdetail',
  '/audit/noncompiledassignment',
  '/help',
  '/policy/help',
  '/conditions/help',
  '/safetycouncilpost',
  '/safetycouncilpostdetail/safetycouncilpost',
  '/safetycouncilform/safetycouncilpost',
  '/safetycouncilassignform/safetycouncilpost'
];

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const isAuthed = loginRoute.includes(
      window.location.pathname.toLowerCase()
    );
    return (
      <div>
        <Grid>
          {isAuthed && <DashboardHeader />}
          <Grid style={appRouterStyle.gridStyle}>
            <Hidden smDown>
              {isAuthed && (
                <div style={appRouterStyle.sidebarDiv}>
                  <SidebarMenu />
                </div>
              )}
            </Hidden>
            <div
              style={
                isAuthed
                  ? appRouterStyle.withsidbar
                  : appRouterStyle.withoutsidebar
              }
            >
              {WebRoutesGenerator({ routeMap })}
              <ModalContainer />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default App;

const appRouterStyle = {
  withsidbar: {
    width: '100%',
    background: 'rgb(238 238 238)',
    position: 'relative',
    overflow: 'scroll',
    height: 'calc(100vh - 100px)',
    overflowX: ' hidden',
    fontFamily: 'ClotherRegular'
  },
  withoutsidebar: { width: '100%', background: 'rgb(238 238 238)', fontFamily: 'ClotherRegular' },
  sidebarDiv: {
    background: '#FFF',
    width: '25%',
    minWidth: '260px',
    height: '100%',
    borderEndEndRadius: '10px'
  },
  gridStyle: {
    display: 'flex',
    background: 'rgb(238, 238, 238)',
    position: 'relative'
  }
};
