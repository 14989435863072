// Customizable Area Start
import React from "react";
import {
    Avatar,
    Grid,
    Typography,
    Box,
    Paper,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { locationIcon } from "./assets";
import "../../dashboard/src/Dashboard.css";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import MyPostsController, { Props } from "./SafetyCouncilPostController";
import { configJSON } from "./AnalyticsController";

export default class SafetyCouncilPostDetail extends MyPostsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const {
            loading
        } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <Typography style={webStyle.actionTrackerHeader}>{configJSON.safetyCouncilText}</Typography>
                    <Grid container justifyContent='space-between'>
                        <Typography style={webStyle.actionTrackerSubHeader}>{configJSON.safetyCouncilText}</Typography>
                    </Grid>
                    <Loader loading={loading} data-test-id="loader" />
                    {!this.state.loading && (
                        <Grid container spacing={3} data-test-id="listData">
                            {this.state.postData.map((postData: any, index: any) => (
                                <Grid onClick={() => this.SafetyCouncilPostDetail()} data-test-id={`postcards${[index]}`} item lg={4} xs={12} sm={6} md={6} key={index}>
                                    <Paper style={{ ...webStyle.gridStyle, borderColor: `${this.checkborderColor(postData.attributes.post.risk_level_name)}` }}>
                                        <Box display={"flex"} justifyContent='space-between' alignItems='center' style={webStyle.mainCardBox}>
                                            <Box display={"flex"} alignItems='center' style={{ maxWidth: '95%' }}>
                                                <Avatar src={postData.attributes.supervisor_account.profile_image} style={webStyle.profileImage} />
                                                <Typography style={webStyle.supervisorName as React.CSSProperties}>
                                                    {postData.attributes.supervisor_account.full_name} {configJSON.createdText}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography style={webStyle.description as React.CSSProperties} className='action_description'>
                                            {postData.attributes.description}
                                        </Typography>
                                        <Grid container>
                                            <Typography style={webStyle.locationTypography as React.CSSProperties}>
                                                <img src={locationIcon} style={webStyle.locationLogo} />
                                                <span style={webStyle.locationText}>{postData.attributes.post.location || configJSON.noLocationText}</span>
                                            </Typography>
                                            <Typography style={webStyle.locationTypography as React.CSSProperties}>
                                                <img src={locationIcon} style={webStyle.locationLogo} />
                                                <span style={webStyle.locationText}>{postData.attributes.post.work_location || configJSON.noLocationText}</span>
                                            </Typography>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ))}
                            {this.state.postData.length === 0 &&
                                <Grid container alignItems='center' justifyContent='center'
                                    style={webStyle.NODataText}
                                >
                                    {configJSON.noDataText}
                                </Grid>
                            }
                        </Grid>
                    )}
                </Wrapper>
            </ThemeProvider >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    locationTypography: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        display: "flex",
        alignItems: "center",
        background: "#F8F8F8",
        borderRadius: "8px",
        padding: "7px 10px",
        whiteSpace: "nowrap",
        color: "#A1A1A1",
        marginRight: '10px',
        marginBottom: '10px',
        maxWidth: '38%',
    },
    NODataText: {
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        height: '250px',
    },
    actionTrackerHeader: {
        fontFamily: 'ClotherRegular',
        color: '#A1A1A1',
        fontSize: '14px',
        lineHeight: '22px',
        paddingBottom: '18px',
        cursor: 'pointer'
    },
    actionTrackerSubHeader: {
        fontFamily: 'ClotherBold',
        fontSize: '26px',
        lineHeight: '18px',
        paddingBottom: '27px',
    },
    locationLogo: {
        width: '14px',
        height: '14px',
        marginRight: '6px',
    },
    assignedToText: {
        color: "#A1A1A1",
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        marginLeft: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    description: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        color: '#A1A1A1',
        height: '51px',
    },
    mainCardBox: {
        paddingBottom: '12px'
    },
    supervisorName: {
        fontFamily: 'ClotherBold',
        fontSize: '16px',
        color: '#2B2A29',
        marginLeft: '14px',
        overflow: "hidden",
        maxWidth: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    profileImage: {
        width: '36px',
        height: '36px',
    },
    statusButton: {
        border: "none",
        padding: "6px 9px",
        textAlign: "center",
        textDecoration: " none",
        display: " inline-block",
        cursor: "pointer",
        borderRadius: "19px",
        fontFamily: 'ClotherRegular',
        fontSize: "13px",
        maxWidth: '40%',
    },
    locationText: {
        overflow: "hidden",
        maxWidth: "100%",
        textOverflow: "ellipsis",
    },
    gridStyle: {
        background: "#fff",
        padding: '15px 16px 6px 15px',
        borderRadius: "8px",
        cursor: "pointer",
        height: "90%",
        boxShadow: 'none',
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    userImgDiv: {
        display: "flex",
        marginBottom: "14px",
        marginTop: '8px',
    },
};

const Wrapper = withStyles({
    root: {
        background: "rgb(238, 238, 238)",
        height: "auto",
        padding: "20px",
    }
})(Grid)
