import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ChangeEvent } from 'react';
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

export type scheduleInspectionData = {
    equipmentName: string;
    equipmentNumber: string;
    location: string;
    department: string;
    email: string;
    inspectionDate: string;
    assignTo: string;
    image1: any;
    image2: any;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    loading: boolean;
    inputSchema: any;
    errorMsg: string;
    personList: {
        id: string;
        type: string;
        attributes: {
            full_name: string;
            employee_id: string;
        }
    }[];
    locations: {
        id: string;
        type: string;
        attributes: {
            id: number;
            name: string;
            country_id: number;
        }
    }[];
    departments: {
        id: string;
        type: string;
        attributes: {
            id: number;
            name: string;
        }
    }[];
    machines: {
        id: string;
        type: string;
        attributes: {
            id: number;
            name: string;
        }
    }[];
    image1: any;
    errorMessageImage1: string;
    image2: any;
    errorMessageImage2: string;
    disableSubmit: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class ScheduleInspectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAssignPersonApiCallId: string = "";
    getLocationsApiCallId: string = "";
    getDepartmentsApiCallId: string = "";
    scheduleInspectionApiCallId: string = "";
    getMachinesApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        const today = new Date()
        today.setDate(today.getDate() - 1);
        const inputSchema = Yup.object().shape({
            equipmentName: Yup.string().required(configJSON.errorrMsg),
            equipmentNumber: Yup.string().required(configJSON.errorrMsg),
            location: Yup.string().required(configJSON.errorrMsg),
            department: Yup.string().required(configJSON.errorrMsg),
            machines: Yup.string().required(configJSON.errorrMsg),
            email: Yup.string().email("Invalid email address").required(configJSON.errorrMsg),
            inspectionDate: Yup.date().required(configJSON.errorrMsg).min(today, "Please select current or after date"),
            assignTo: Yup.string().required(configJSON.errorrMsg),
            image1: Yup.mixed().when('image2', {
                is: (image2) => !image2,
                then: Yup.mixed().required('At least one image is required'),
            }),
            image2: Yup.mixed(),
        });

        this.state = {
            // Customizable Area Start
            token: "",
            loading: false,
            inputSchema: inputSchema,
            errorMsg: "",
            personList: [],
            locations: [],
            departments: [],
            machines: [],
            image1: "",
            errorMessageImage1: "",
            errorMessageImage2: "",
            image2: "",
            disableSubmit: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getLocationsApiCallId) {
                this.handleLocationsApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getMachinesApiCallId) {
                this.handleMachinesApiResponse(responseJson)
            }

            if (apiRequestCallId === this.getDepartmentsApiCallId) {
                this.handleDepartmentApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getAssignPersonApiCallId) {
                this.handleAssignPersonApiResponse(responseJson)
            }
            if (apiRequestCallId === this.scheduleInspectionApiCallId) {
                this.handleScheduleInspectionApiResponse(responseJson)
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        this.setState({ token });

        this.getLocationsApiCall();
        this.getDepartmentsApiCall();
        this.assignPersonListApiCall();
        this.getMachinesApiCall();
    }

    handleScheduleInspectionApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ loading: false, disableSubmit: false });
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannedInspection");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loading: false,
                disableSubmit: false
            });
        }
    }

    handleMachinesApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ machines: responseJson.machine_tools.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleAssignPersonApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ personList: responseJson.accounts.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleLocationsApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ locations: responseJson.locations.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleDepartmentApiResponse = (responseJson: any) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ departments: responseJson.departments.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleImage1Change(event: ChangeEvent<HTMLInputElement>, setFieldValue: Function) {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                setFieldValue("image1", event.target.files?.[0]);
                this.changeImg1(event);
                this.setState({ errorMessageImage1: '' });
            } else {
                this.setState({ errorMessageImage1: 'Please select a valid image file (PNG or JPEG).' });
            }
        }
    }

    changeImg1(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ image1: fileReader.result });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleImage2Change(event: ChangeEvent<HTMLInputElement>, setFieldValue: Function) {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                setFieldValue("image2", event.target.files?.[0]);
                this.changeImg2(event);
                this.setState({ errorMessageImage2: '' });
            } else {
                this.setState({ errorMessageImage2: 'Please select a valid image file (PNG or JPEG).' });
            }
        }
    }

    changeImg2(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ image2: fileReader.result });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    scheduleInspectionApiCall = async (values: any) => {
        this.setState({
            loading: true,
            disableSubmit: true
        });
        const headers = {
            token: this.state.token,
        };

        const formattedDate = moment(values.inspectionDate.toISOString()).format('DD MMM YYYY');
        const currentDate = moment().format('DD MMM YYYY');
        let status = currentDate === formattedDate ? "Due" : "Planned";

        let formData = new FormData();
        formData.append("equipment_name", values.equipmentName);
        formData.append("equipment_number", values.equipmentNumber);
        formData.append("location_id", values.location);
        formData.append("machine_tool_id", values.machines);
        formData.append("department_id", values.department);
        formData.append("email", values.email);
        formData.append("inspection_date", formattedDate);
        formData.append("assigned_to_id", values.assignTo.value);
        if (values.image1) {
            formData.append("first_image", values.image1, values.image1.filename);
        }
        if (!values.image1 && values.image2) {
            formData.append("first_image", values.image2, values.image2.filename);
        }
        if (values.image1 && values.image2) {
            formData.append("second_image", values.image2, values.image2.filename);
        }
        formData.append("status", status);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.scheduleInspectionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postScheduleInspectionApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLocationsApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getLocationsApiEndPoint}?per_page=200`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDepartmentsApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDepartmentsApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDepartmentsApiEndPoint}?per_page=200`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    getMachinesApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMachinesApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getMachinesApiEndPoint}?per_page=200`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    assignPersonListApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssignPersonApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAssignPersonApiEndPoint
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    // Customizable Area End
}
