import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("../config.json");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  post: { id?: number };
  From: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewPostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      post: {},
      From: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.Props));
      console.log(responseData, 'responseData')
      this.setState({ post: responseData.attributes });
      setStorageData("post", JSON.stringify(responseData.attributes));
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const postData = await getStorageData("post");
    const From = await getStorageData("from");
    let post = JSON.parse(postData);
    this.setState({ post , From});
  }

  navigateToMyPosts = () => {
    this.props.navigation.goBack();
  };

  proceedPostHandler = () => {
    const postData = {
      id: this.state.post.id,
    };
    const message: Message = new Message(
      getName(MessageEnum.SupervisorPostCreation)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.Props), postData); // this line is only needed when props has to be sent
    this.send(message);
  };

  // Customizable Area End
}
