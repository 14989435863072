// Customizable Area Start
import React from "react";
import {
    Grid,
    Typography,
    Paper,
    Button,
    Input,
    FormHelperText,
    InputAdornment,
    styled,
} from "@material-ui/core";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
import DatePicker from "react-datepicker";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import { calendarIcon } from "./assets"
import { NavigateNext } from "@material-ui/icons";
import ViewPostController, { Props } from "./SafetyCouncilAssignController";
import { configJSON } from "./AnalyticsController";

export default class SafetyCouncilAssignForm extends ViewPostController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const {
            loading,
            actionPostData,
        } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Wrapper style={{ padding: "30px", background: "rgb(238, 238, 238)" }}>
                    <Grid container>
                        <Typography style={webStyle.headers}>{configJSON.safetyCouncilText}</Typography>
                        <NavigateNext style={{ fill: '#DCDCDC' }} />
                        <Typography style={webStyle.headers}>{configJSON.PostText}</Typography>
                        <NavigateNext style={{ fill: '#DCDCDC' }} />
                        <Typography style={webStyle.currentPage}>{configJSON.FormText}</Typography>
                    </Grid>
                    <Typography style={webStyle.header}>{configJSON.FormText}</Typography>
                    {!loading && (
                        <Paper style={webStyle.paperStyle}>
                            <Grid container spacing={3}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Typography className="inputLabel">
                                            Concern Location
                                        </Typography>
                                        <Input
                                            disabled
                                            name="employee_id"
                                            data-id="empId"
                                            style={{
                                                ...webStyle.input,
                                                ...webStyle.inputStd,
                                                ...webStyle.inputDisabled,
                                            }}
                                            value={actionPostData.location}
                                            disableUnderline
                                            placeholder="Location"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item md={12} xs={12}>
                                        <FormHelperText className="inputLabel">Assign to</FormHelperText>
                                        <Input
                                            disabled
                                            name="employee_id"
                                            data-id="empId"
                                            style={{
                                                ...webStyle.input,
                                                ...webStyle.inputStd,
                                                ...webStyle.inputDisabled,
                                            }}
                                            value={actionPostData.category}
                                            disableUnderline
                                            placeholder="Assign To"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid item md={12} xs={12} style={{ marginTop: '36px' }}>
                                        <Input
                                            name="employee_id"
                                            data-id="empId"
                                            style={{
                                                ...webStyle.input,
                                                ...webStyle.inputStd,
                                                ...webStyle.inputDisabled,
                                            }}
                                            value={actionPostData.assigned_to}
                                            disableUnderline
                                            placeholder="Assign to"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <FormHelperText style={webStyle.label}>Employee ID</FormHelperText>
                                    <Input
                                        disabled
                                        name="employee_id"
                                        data-id="empId"
                                        style={{
                                            ...webStyle.input,
                                            ...webStyle.inputStd,
                                            ...webStyle.inputDisabled,
                                        }}
                                        value={actionPostData.employee_id}
                                        disableUnderline
                                        placeholder="Employee ID"
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <FormHelperText style={webStyle.label}>
                                        Due Date
                                    </FormHelperText>
                                    <DatePicker
                                        scrollableYearDropdown
                                        className="Location-select"
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        dateFormatCalendar="MMMM"
                                        dateFormat="dd/MM/yyyy"
                                        data-test-id="dateChange"
                                        name="due_date_CA"
                                        placeholderText="Due Date"
                                        minDate={new Date()}
                                        wrapperClassName="date-picker"
                                        data-testId="date-picker-inline-todate"
                                        selected={this.state.selectedDate === "" ? null : moment(this.state.selectedDate).toDate()}
                                        popperClassName="example-datepicker-class"
                                        onChange={() => { }}
                                        disabled
                                        customInput={
                                            <Input
                                                onClick={() => { }}
                                                fullWidth={true}
                                                disableUnderline
                                                disabled
                                                data-testid="textInputTest"
                                                name="dateTest"
                                                defaultValue={""}
                                                endAdornment={
                                                    <div>
                                                        <InputAdornment position="end">
                                                            <img
                                                                src={calendarIcon}
                                                                style={{
                                                                    width: 28,
                                                                    height: 28,
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    </div>
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <div style={webStyle.btnDiv}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={webStyle.btnStyleColor as React.CSSProperties}
                            data-test-id="Assign"
                            onClick={this.goToListing}
                        >
                            Back
                        </Button>
                    </div>
                </Wrapper>
                <Loader loading={this.state.loading} />
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    inputDisabled: {
        background: '#F3F3F3'
    },
    input: {
        borderRadius: '8px',
        border: '1px solid #DCDCDC',
        fontFamily: 'ClotherBold',
        backgroundColor: '#F3F3F3',
        color: '#A1A1A1',
        fontSie: '16px'
    },
    inputStd: {
        height: '3.5rem',
        width: '100%',
        padding: '10px'
    },
    label: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#18181E',
        marginBottom: '8px',
        marginTop: '0px'
    },
    labelStyle: {
        border: "1px solid #DCDCDC",
        borderRadius: "8px",
        fontFamily: "ClotherRegular",
        padding: "7px",
    },
    followDetailsBox: {
        minHeight: '149px',
        borderRadius: '8px',
        border: '1px solid #DCDCDC',
    },
    details: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#828282',
        padding: '15px'
    },
    label2: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        lineHeight: '18px',
        color: '#2B2A29',
        padding: '15px'
    },
    btnStyleColor: {
        minWidth: "160px",
        height: "50px",
        backgroundColor: "#8735E1",
        textTransform: "none",
        color: "white",
        border: "1px solid #8735E1",
        padding: '10px',
        borderRadius: "8px",
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
    },
    btnDiv: {
        padding: "30px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    paperStyle: {
        height: "auto",
        padding: "30px",
        background: "#fff",
        marginTop: '10px'
    },
    header: {
        fontFamily: 'ClotherBold',
        fontSize: '24px',
        lineHeight: '24px',
        color: '#2B2A29',
        padding: '10px 0px'
    },
    currentPage: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        color: '#8735E1',
        marginLeft: '2px',
    },
    headers: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#A1A1A1',
        margin: '0px',
    },
};

export const Wrapper = styled(Grid)({
    width: "100%",
    backgroundColor: "#f7f5f5",
    padding: "20px 20px",
    boxSizing: "border-box",
    height: "calc(100vh - 100px)",
    overflow: "auto",
    position: "relative",
    "& .inputLabel": {
        color: '#18181E',
        fontSize: '18px',
        fontFamily: 'ClotherRegular',
        marginBottom: '5px'
    },
    "& .Location-select": {
        padding: "10px",
        border: "1px solid lightgray",
        borderRadius: "8px",
        width: "100%",
        color: "#2B2A29",
        fontFamily: "ClotherBold",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
    },
})
