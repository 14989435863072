import React from "react";
// Customizable Area Start
import { Box,ThemeProvider, createTheme, styled } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { webStyles } from "../../../components/src/styles";
import Slider from "react-slick";
import StoriesController,{Props} from "./StoriesController.web";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      color:'#A1A1A1'
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
let settings = {
  arrows:false,
  infinite: true,
  speed: 500,
  slidesToShow: 14,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 12,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow:11,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1110,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 775,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 410,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2
      }
    }
  ]
};
const images = [
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",

];
  // Customizable Area End
  // Customizable Area Start
export default class Stories extends StoriesController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #eee",
            margin: "0 -1rem 0.75rem",
            padding: "0.3rem 1rem",
          }}
        >
          <p style={{ ...webStyles.basicHeader, margin: 0 }}>Stories</p>
          <p style={{ margin: 0 }}>
            <KeyboardArrowLeft style={{ cursor: "pointer" }} onClick={this.previous} data-test-id="previous"/>
            <KeyboardArrowRight style={{ cursor: "pointer" }} data-test-id="next" onClick={this.next} />
          </p>
        </Box>
          <MainContainer >
          <Slider {...settings}  ref={this.setSliderRef}>
          {images.map((image, index) => (
             <div key={index}>
             <img
                src={image}
                alt={`Image ${index + 1}`}
                style={{
                  width: "50px",
                  height: "50px",
                  border: "1px solid purple",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
           </div>
          ))}
      </Slider>
      </MainContainer>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const MainContainer = styled(Box)({
  width: '-webkit-fill-available',
})
// Customizable Area End