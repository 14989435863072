import React, { UIEvent, useState } from 'react';
import { Avatar, Box, ListItem, Typography } from '@material-ui/core';
import { imgicon1, imgicon2, imgicon3 } from '../../blocks/dashboard/src/assets';
import { getTimeFromNow } from './helpers/getTimeFromNow';
import { Notifications } from './types';

const userImages = [imgicon1, imgicon2, imgicon3];

export const NotificationsBlock = ({
    notifications,
    getNotifications,
    fullScreen,
    postView,
}: {
    notifications: Notifications | null,
    getNotifications?: (page?: number, perPage?: number, scrollPosition?: number) => void,
    fullScreen?: boolean,
    postView: any;
}) => {
    const totalPages: any = notifications?.meta.pagination.total_pages;

    // Start from 2 as original first request was already called
    const [pageForNotifications, setPageForNotifications] = useState(2);

    const paginateOnScroll = (event: UIEvent<HTMLDivElement>) => {
        if (pageForNotifications <= totalPages) {
            const targetElement = (event.target as any);
            const scrollHeight = targetElement.scrollHeight;
            const elementHeight = targetElement.offsetHeight;
            const topScrollCoord = targetElement.scrollTop;

            if (elementHeight + topScrollCoord >= scrollHeight) {
                getNotifications?.(pageForNotifications);
                setPageForNotifications(pageForNotifications + 1);
            }
        }
    }

    const getWithoutLastWords = (content: string) => {
        return content.split(" ").slice(0, -2).join(" ")
    }

    const getLast3Words = (content: string) => {
        const lastWord = content.split(" ").splice(-2).join(" ");
        return lastWord
    }
    return <>
        <Box
            fontFamily='ClotherRegular'
            textAlign='center'
            onScroll={paginateOnScroll}
            style={{
                ...(!fullScreen && webStyles.menuItem as React.CSSProperties)
            }}>
            {notifications?.data.map((notification, index) => {
                const { account, contents, created_at, post } = notification.attributes;
                let user_name = notification.attributes.full_name
                let content: any = "";
                if (notification.attributes.headings === "safety_concern_published") {
                    user_name = "Safety Incharge"
                    content = <span>has raised concern against <span style={webStyles.secondaryFont2}>you</span></span>
                } else if (notification.attributes.headings === "action_tracker_reassign") {
                    user_name = "Supervisor"
                    content = <span>has Re-assigned tracker to <span style={webStyles.secondaryFont2}>you</span></span>
                } else if (notification.attributes.headings === "post_created" || notification.attributes.headings === "personal_details_created") {
                    user_name = ""
                    content = <span><span style={webStyles.secondaryFont2}>Congratulations </span>contribution points earned <span style={webStyles.secondaryFont2}>for adding Personal Details</span></span>
                } else if (notification.attributes.headings === "action_tracker_follow_up") {
                    user_name = "Supervisor"
                    content = <span>has followed up with <span style={webStyles.secondaryFont2}>you</span></span>
                } else if (notification.attributes.headings === "action_tracker_or created") {
                    user_name = "Supervisor"
                    content = <span>has Assigned capa to <span style={webStyles.secondaryFont2}>you</span></span>
                } else if (notification.attributes.headings === "safety_concern_published") {
                    user_name = "Safety incharge"
                    content = <span>has raised concern against <span style={webStyles.secondaryFont2}>you</span></span>
                } else if (notification.attributes.headings === "action_tracker_verified") {
                    user_name = "Supervisor"
                    content = <span>has verified your <span style={webStyles.secondaryFont2}>Action Tracker</span></span>
                } else if (notification.attributes.headings === "Post created notification") {
                    user_name = post.data.attributes.user_name
                    content = <span>{getWithoutLastWords(contents)}<span style={webStyles.secondaryFont2}>{" "}{getLast3Words(contents)}</span></span>
                } else if (notification.attributes.headings === "action_tracker_reply") {
                    user_name = "Worker"
                    content = <span>has replied to your <span style={webStyles.secondaryFont2}>follow-up</span></span>
                } else if (notification.attributes.headings === "action_tracker_pending_review") {
                    user_name = "Worker"
                    content = <span>has changed status to <span style={webStyles.secondaryFont2}>Pending Review state</span></span>
                } else if (notification.attributes.headings === "action_tracker_not_my_domain") {
                    user_name = "Worker"
                    content = <span>has changed status to <span style={webStyles.secondaryFont2}>Not My Domain state</span></span>
                } else if (notification.attributes.headings === "action_tracker_cancelled") {
                    user_name = "Worker"
                    content = <span>has changed status to <span style={webStyles.secondaryFont2}>Cancelled state</span></span>
                } else if (notification.attributes.headings === "action_tracker_planned") {
                    user_name = "Worker"
                    content = <span>has changed status to <span style={webStyles.secondaryFont2}>Planned state</span></span>
                }
                return <ListItem
                    key={notification.id}
                    style={{
                        ...webStyles.notificationItem,
                        ...(fullScreen && webStyles.fullScreenItem)
                    }}
                    data-test-id="notification"
                    onClick={() => postView(notification.attributes.headings, notification.attributes?.post?.data)}
                >
                    <Avatar
                        src={post ? post.data.attributes.profile_pic : account?.data?.attributes.profile_image}
                        style={webStyles.profilePic}
                    />
                    <Box display={"flex"} flexDirection={"column"}>
                        <Typography style={webStyles.userName as React.CSSProperties}>{user_name}
                            <span style={webStyles.secondaryFont as React.CSSProperties}>{content}</span>
                        </Typography>
                        <Box style={webStyles.Time as React.CSSProperties}>{getTimeFromNow(created_at)} ago</Box>
                    </Box>
                </ListItem>
            })}
        </Box >
    </>
}

const webStyles = {
    profilePic: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        borderRadius: '50%'
    },
    userName: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#2B2A29',
        marginRight: '5px',
        marginBottom: '4px'
    },
    notificationItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        borderTop: '1px solid #EEEEEE',
        cursor: 'pointer',
    },
    fullScreenItem: {
        width: '95%',
        backgroundColor: '#FFFFFF',
        margin: 'auto'
    },
    menuItem: {
        height: '268px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    secondaryFont: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#A1A1A1',
        marginLeft: '4px'
    },
    Time: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#A1A1A1',
        display: 'flex',
    },
    secondaryFont2: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#2B2A29',
        // marginLeft: '4px'
    }
};
