Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.empIDErrMsg ="Please enter valid Employee ID";
exports.fullNameErrMsg ="Please enter valid Name";
exports.MobileErrMsg ="Please enter valid Mobile Number";
exports.emailErrMsg ="Please enter valid email";
exports.genderErrMsg ="Please Select Gender";
exports.dobErrMsg ="Please Select Date of Birth";

// Posts section
exports.postsGetUrl = "/bx_block_profile/profiles/get_post_creation_data";
exports.postCreateUrl = "/bx_block_posts/posts";
exports.postsContentType = "application/json";
exports.postsApiMethodType = "GET";
exports.postsApiPostMethodType = "POST";
exports.languages = [
  { id: '1', name: 'English' },
  { id: '2', name: 'Hindi' },
  { id: '3', name: 'Dutch' },
  { id: '4', name: 'Hungarian' },
  { id: '5', name: 'Tamil' },
  { id: '6', name: 'Malayalam' },
  { id: '7', name: 'Gujarati' },
];
exports.MonthGoalObj=[
  { id: 1, value: "J", month: "January" },
  { id: 2, value: "F", month: "February" },
  { id: 3, value: "M", month: "March" },
  { id: 4, value: "A", month: "April" },
  { id: 5, value: "M", month: "May" },
  { id: 6, value: "J", month: "June" },
  { id: 7, value: "J", month: "July" },
  { id: 8, value: "A", month: "August" },
  { id: 9, value: "S", month: "September" },
  { id: 10, value: "O", month: "October" },
  { id: 11, value: "N", month: "November" },
  { id: 12, value: "D", month: "December" },
]

// Notifications section
exports.notificationsGetUrl = "bx_block_notifications/notifications",
exports.notificationsContentType = "application/json";
exports.notificationsApiMethodType = "GET";
// Customizable Area End