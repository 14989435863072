import React from "react";
// Customizable Area Start
import {
  Box,
  AppBar,
  Toolbar,
  Avatar,
  Dialog,
  Button,
  Grid,
  Hidden,
  Drawer,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import {
  languageIcon,
  notificationIcon,
  cancelicon,
  selectLanguage,
  imageLogo,
  notificationsIcon,
} from "./assets";
import DashboardController, { Props } from "./DashboardController.web";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import {
  sideBarItems,
  SvgImageRander,
} from "../../../components/src/SidebarMenu.web";
import { NotificationsBlock } from "../../../components/src/NotificationsBlock";
export const webConfigJSON = require("./config.js");
// Customizable Area End
export default class DashboardHeader extends DashboardController {
  constructor(props: Props) {
    super(props);
    this.getNotifications = this.getNotifications.bind(this);
  }
  render() {
    // Customizable Area Start

    const { accountDetails } = this.state;

    const fullName = accountDetails?.attributes?.full_name || "No Name";


    const renderNotifications = (
      isNotificationsOpen: boolean,
      loading: boolean,
      notifications: any,
      goToNotificationsPage: any,
      getNotifications: any
    ) => {
      if (!isNotificationsOpen) {
        return null;
      }
      return (
        <Backdrop
          style={{ color: "#fff", zIndex: 11 }}
          open={isNotificationsOpen}
          onClick={goToNotificationsPage}
        >
          <>
            <Box style={webStyles.outerBlock as React.CSSProperties}>
              <svg
                height="15"
                width="30"
                style={webStyles.triangle as React.CSSProperties}
              >
                <polygon
                  points="22,0 28,15 15,15"
                  style={{ fill: "#FFFFFF", stroke: "#EEEEEE", strokeWidth: 1 }}
                />
              </svg>
              <Box
                width="22rem"
                border="1px solid #EEEEEE"
                borderRadius="8px"
                justifyContent="space-between"
                fontFamily="ClotherRegular"
                style={webStyles.wrapper as React.CSSProperties}
              >

                {loading && (
                  <CircularProgress
                    data-test-id="spinner"
                    style={
                      webStyles.notificationPendingBlock as React.CSSProperties
                    }
                  />
                )}

                {notifications?.data?.length && (
                  <>
                    <Box
                      data-test-id="notifications-list"
                      style={{
                        ...webStyles.notificationItem,
                        border: "none",
                      }}
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="h6"
                        style={webStyles.notificationHeader}
                      >
                        Notifications
                      </Typography>
                      <Link
                        data-test-id="notifications-page-link"
                        onClick={goToNotificationsPage}
                        to="/Notifications"
                        style={webStyles.viewAllText}
                      >
                        View all
                      </Link>
                    </Box>
                    <NotificationsBlock
                      notifications={notifications}
                      getNotifications={getNotifications}
                      postView={() => { }}
                    />
                  </>
                )}

                {!notifications?.data?.length && !loading && (
                  <Box
                    data-test-id="notifications-empty-message"
                    style={webStyles.notificationPending as React.CSSProperties}
                  >
                    <img
                      src={notificationsIcon}
                      alt="notifications icon"
                      style={{
                        display: "block",
                        margin: "auto",
                        marginBottom: "0.5rem",
                      }}
                    />
                    <Typography
                      variant="body2"
                      style={{
                        display: "block",
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      You have no notifications right now.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        </Backdrop>
      );
    };
    return (
      <AppBar
        position="static"
        color="default"
        elevation={2}
        style={{ backgroundColor: "#fff", margin: 0 }}
      >
        <Toolbar style={webStyles.topbar}>
          <Hidden mdUp>
            <div style={{ marginRight: "20px" }}>

              <MenuIcon
                onClick={this.dropDownOpen}
                style={{ cursor: "pointer" }}
              />

            </div>
          </Hidden>
          <Grid container alignItems="center" justifyContent={"space-between"}>
            <Grid item>
              <Link to="/Dashboard">
                <img
                  src={imageLogo}
                  alt="Logo"
                  style={{ width: "172px", cursor: "pointer" }}
                />
              </Link>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Avatar
                  data-test-id="notifications-icon"
                  onClick={() =>
                    this.showNotifications(!this.state.isNotificationsOpen)
                  }
                  src={notificationIcon}
                  alt="notificationIcon"
                  style={{ marginRight: "15px", cursor: "pointer", height: '34px', width: '34px' }}
                />
                <Avatar
                  onClick={this.openModal}
                  src={languageIcon}
                  alt="User"
                  style={{ marginRight: "10px", cursor: "pointer", height: '34px', width: '34px' }}
                  data-test-id="openModal"
                />
                {renderNotifications(
                  this.state.isNotificationsOpen,
                  this.state.loading,
                  this.state.notifications,
                  this.goToNotificationsPage,
                  this.getNotifications
                )}

                <Link
                  to="/MyProfile"
                  style={{
                    display: "flex",
                    textDecoration: "none",
                    color: "black",
                    width: '148px',
                  }}
                >

                  <Avatar
                    src={accountDetails?.attributes?.profile_image}
                    alt="User"
                    style={{ cursor: "pointer", height: '54px', width: '54px' }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontFamily: "Clotherbold",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '85px',
                      }}
                    >
                      {fullName}
                    </Typography>
                    <span
                      style={{
                        marginTop: "0",
                        fontSize: "14px",
                        fontFamily: "ClotherRegular",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '85px',
                      }}
                    >
                      {accountDetails.attributes?.role_name || "No Role"}
                    </span>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {this.state.isModalOpen === true && (
          <Dialog maxWidth={"sm"} open={this.state.isModalOpen}>
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  paddingLeft: "20px",
                }}
              >
                <h2>Select Language</h2>
                <img
                  src={cancelicon}
                  alt=""
                  style={{
                    cursor: "pointer",
                    height: "18px",
                    width: "18px",
                    marginTop: "25px",
                    marginRight: "15px",
                  }}
                  onClick={this.closeModal}
                  data-test-id="CloseModal"
                />
              </div>

              <Grid container spacing={2} style={{ padding: "30px" }}>
                {webConfigJSON.languages.map((language: any) => (
                  <Grid item xs={6}>
                    <div
                      key={language.id}
                      onClick={() => this.handleSelect(language.id)}
                      data-test-id="selectLanguage"
                      style={{
                        ...webStyles.languageStyle,
                        color:
                          this.state.isSelected === language.id
                            ? "#9519EF"
                            : "#A1A1A1",
                        border:
                          this.state.isSelected === language.id
                            ? "2px solid #9519EF"
                            : "1px solid #A1A1A1",
                      }}
                    >
                      <p>{language.name}</p>
                      {this.state.isSelected === language.id && (
                        <img src={selectLanguage} alt="Selected Language" />
                      )}
                    </div>
                  </Grid>
                ))}
              </Grid>

              <div
                style={{
                  padding: "30px",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                }}
              >
                <Button
                  onClick={this.closeModal}
                  data-test-id="closebtn"
                  variant="outlined"
                  color="secondary"
                  style={webStyles.btnStyle as React.CSSProperties}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={webStyles.btnStyleColor as React.CSSProperties}
                  onClick={() => this.closeModal()}
                  data-test-id="closemodal"
                >
                  Save
                </Button>
              </div>
            </div>
          </Dialog>
        )}

        <Drawer
          open={this.state.dropOpen}
          onClose={this.handleClose}
          data-test-id="handleCloseDrawer"
        >
          <div>
            {sideBarItems.map(({ style = {}, name, link, img }, index) => (
              <div onClick={this.handleClose} data-test-id="handleCloseDrawer">
                <SvgImageRander
                  style={style}
                  name={name}
                  index={index}
                  link={link}
                  img={img}
                />
              </div>
            ))}
            <div
              onClick={this.logout}
              style={webStyles.drawerLogout}
              data-test-id="logoutdrawer"
            >
              <img src={require("../assets/logout.png")} />
              <span style={webStyles.menuText}>Logout</span>
            </div>
          </div>
        </Drawer>
      </AppBar>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyles = {
  menuText: {
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    textDecoration: "none",
    color: "#F30202",
    marginLeft: "32px",
  },
  topbar: { height: "100px", padding: "0 40px" },
  sidebar: {
    background: "#FFF",
    boxShadow: "4px 0px 4px 0px rgba(0, 0, 0, 0.02)",
  },
  btnStyle: {
    width: "100%",
    maxWidth: "120px",
    height: "40px",
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid Blue Violet",
    color: "#8735E1",
    backgroundColor: "white",
    borderColor: "rgb(135, 53, 225)",
  },
  btnStyleColor: {
    width: "100%",
    maxWidth: "120px",
    height: "40px",
    fontWeight: 600,
    textTransform: "none",
    border: "1px solid Blue Violet",
    color: "white",
    backgroundColor: "#8735E1",
  },
  languageStyle: {
    display: "flex",
    width: "235px",
    height: "60px",
    borderRadius: "8px",
    alignItems: "center",
    paddingLeft: "15px",
    fontWeight: 500,
    fontSize: "18px",
    justifyContent: "space-between",
    paddingRight: "10px",
    cursor: "pointer",
  },

  drawerLogout: {
    padding: "11px 0px 0px 25px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  triangle: {
    position: "relative",
    left: "18.5rem",
  },
  notificationPendingBlock: {
    position: "absolute",
    top: "40%",
    left: "43%",
  },
  notificationPending: {
    position: "absolute",
    top: "15%",
    left: "18%",
  },
  outerBlock: {
    position: "absolute",
    top: "4.5rem",
    right: "231px",
  },
  wrapper: {
    position: "relative",
    minHeight: "10rem",
    backgroundColor: "#FFF",
    zIndex: 2,
  },
  notificationItem: {
    display: "flex",
    alignItems: "center",
    padding: "1rem",
    borderTop: "1px solid #EEEEEE",
  },
  secondaryFont: {
    color: "#A1A1A1",
    fontSize: "0.875rem",
  },
  notificationHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '16px',
    color: '#0F172A'
  },
  viewAllText: {
    fontFamily: 'ClotherRegular',
    fontSize: '12px',
    lineHeight: '24px',
    color: "#8735E1",
    textDecoration: "none",
  }

};
// Customizable Area End
