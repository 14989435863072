// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.errorText = "This field is required";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip"
exports.addDetailsTitle = "Add Details";
exports.MyPosts = "My Posts";
exports.HomeText = "Home";
exports.postHeaderText = "Post";
exports.unActioned = "Un Actioned"
exports.relatedImgText = "Related Image";
exports.describeIssue = "Describe Issue";
exports.location = "Location - BU or Cell";
exports.autoDetect = "Auto detect Location";
exports.concern = "Concern is related to";
exports.hazard = "Hazard";
exports.frequency = "Frequency";
exports.severity = "Severity";

exports.activity = "Activity Identification";
exports.task = "Task Identification";
exports.training = "Training Status";
exports.safetyContact = "Last Safety Contact";

exports.statutory = "Statutory / Regulatory / Legal Concern";
exports.sop = "Standard Compliance - SOP Compliance";
exports.noGo = "No Go Parameter Violation";
exports.safety = "Safety Absolute Violation";
exports.riskCount = "Risk Count";

exports.remove = "Remove";
exports.addCA = "+ Add another Corrective Action";
exports.addPA = "+ Add another Preventive Action";

exports.consequence = "Consequence(HSE) - Potential Impact";
exports.riskLevel = "Risk Levels";
exports.CancelText = "Cancel";
exports.NextText = "Next";
exports.PreviousText = "Previous"
exports.getMyPostsEndpoint = "bx_block_posts/posts/my_posts"
exports.getPostEndpoint = "bx_block_posts/posts"
exports.getOptionsEndpoint = "bx_block_profile/profiles/get_post_creation_data"
exports.getTaskAndActivityIdentification = "bx_block_posts/posts/task_and_activity_identification"
exports.postForCapaEndPoint = "bx_block_posts/post_with_action_trackers"
exports.getPersonDataEndPoint = "bx_block_posts/action_trackers/person_preventive_corrective_action"
exports.apiFormDataType = "multipart/form-data";
exports.apiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.patchApiMethodType = "PATCH";

// Customizable Area End
