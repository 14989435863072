// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Avatar,
} from "@material-ui/core";

import { NavigateNext } from "@material-ui/icons";

import { locationIcon, clockIcon, profileIcon } from "./assets";

import ViewPostController, { Props } from "./ViewPostController";
import { Wrapper } from "./styles";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";

export default class ViewPost extends ViewPostController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { post } = this.state;
    console.log(post, 'post')
    return (
      <Grid container>
        <Wrapper>
          <Container maxWidth={"xl"}>
            {
              this.state.From === "notifications" ?
                <Box className="headingWrapper">
                  <Typography className="subHeadingText">Home</Typography>
                  <Typography className="subHeadingText">
                    <NavigateNext className="navigateNext" />
                  </Typography>
                  <Typography className="subHeadingText">Notifications</Typography>
                  <Typography className="subHeadingText">
                    <NavigateNext className="navigateNext" />
                  </Typography>
                  <Typography className="activeHeadingText">
                    Post
                  </Typography>
                </Box> : <>
                  <Box className="headingWrapper">
                    <Typography
                      data-test-id="my-post"
                      className="subHeadingText"
                      onClick={this.navigateToMyPosts}
                    >
                      My Posts
                    </Typography>
                    <Typography>
                      <NavigateNext className="navigateNext" />
                    </Typography>
                    <Typography
                      className="subHeadingText"
                      onClick={this.navigateToMyPosts}
                    >
                      Un Actioned
                    </Typography>
                    <Typography>
                      <NavigateNext className="navigateNext" />
                    </Typography>
                    <Typography className="activeHeadingText">Post</Typography>
                  </Box>
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading">Post</Typography>
                  </Box>
                </>
            }

            <Box className="card">
              <Box className="card_title_wrapper">
                <Avatar
                  className="Post-profile-img"
                  data-test-id="profile-icon"
                  src={post?.profile_pic || profileIcon}
                  alt="Custom Icon"
                />
                <Typography className="card-post-name">
                  {post?.user_name || "No Name"} created this post
                </Typography>
              </Box>
              <img className="card-image" src={post?.image_url} alt="post" />
              <Typography className="card-post-detail">
                {post?.description || "No Descrpition"}
              </Typography>

              <Box className="Post-time-detail">
                <Box className="Post-time-inner">
                  <Button className="Floor_btn">
                    <img src={locationIcon} alt="location" />
                    <span>{(post?.work_location && post?.work_location.length > 50 ? `${post?.work_location.substring(0, 50)}...` : post?.work_location) || "No Location"}</span>
                  </Button>
                  <Avatar
                    className="Post-inner-profile-img"
                    data-test-id="profile-icon"
                    src={post?.profile_pic || profileIcon}
                    alt="Custom Icon"
                  />
                  <Typography className="Post-time-content">
                    {post?.user_name || "No Name"}
                  </Typography>
                  <img src={clockIcon} alt="time" className="time-icon" />
                  <Typography className="Post-time-content">
                    {getTimeFromNow(post?.created_at)} ago
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!post?.actioned_or_unactioned_value && (
              <Box className="process-wrapper">
                <Button onClick={this.navigateToMyPosts} variant="contained" className="Ignore-btn">
                  Ignore
                </Button>
                <Button
                  data-test-id="proceed-btn"
                  variant="contained"
                  className="process-btn"
                  onClick={this.proceedPostHandler}
                >
                  Proceed
                </Button>
              </Box>
            )
            }
          </Container>
        </Wrapper>
      </Grid>
    );
  }
}

// Customizable Area End
