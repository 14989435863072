Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.AccountText = "Account";
exports.privacySettingText = "Privacy Settings";
exports.privacyHeadingText = "Who can view my contact details?";
exports.reportingManagerText = "Only Reporting Manager";
exports.reportingManagerTeamMenmText = "Reporting Manager and Team Members";
exports.everyPlantText = "Everyone from the Plant";
exports.everyAppUserText = "Everyone who uses the app";
exports.reportingValue = "reporting";
exports.teamManagersValue = "teamManagers";
exports.plantTeamValue = "plantTeam";
exports.everyoneAppValue = "everyoneApp";
exports.privacyPolicyText = "Privacy Policy";
exports.privacyPolicyAPIEndPoint = "bx_block_content_management/terms_and_conditions?id=2";
exports.iAgreeBtnText = "I Agree";
exports.termsConditionText = "Terms And Condition";
exports.termsConditionAPIEndPoint = "bx_block_content_management/terms_and_conditions?id=1";
exports.TermsConditionsText = "Terms & Conditions";
exports.TermsandConditionsText = "Terms and Conditions";
exports.helpText = "Help";
// Customizable Area End

