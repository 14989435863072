import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    assignButtonClicked: boolean;
    addAnotherCheckpoint: {
        counter: number;
        nonCompiledCheckpointName: string;
        uploadedImage: any;
        uploadedImagePreview: any;
        description: string;
        assignedPerson: {
            label: string;
            value: string;
        },
        assignDate: Date | null;
    }[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NonCompiledAssignmentController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAssignPersonApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            assignButtonClicked: false,
            addAnotherCheckpoint: [{
                counter: 1,
                nonCompiledCheckpointName: "",
                uploadedImage: "",
                uploadedImagePreview: "",
                description: "",
                assignedPerson: {
                    label: "",
                    value: "",
                },
                assignDate: null,
            }],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        this.setState({ token });
    }

    handleAssignButton = () => {
        this.setState({ assignButtonClicked: true })
        const { addAnotherCheckpoint, assignButtonClicked } = this.state
        const errorInAddAnotherCheckpoint = (addAnotherCheckpoint[0].assignedPerson.value === '' ||
            addAnotherCheckpoint[0].nonCompiledCheckpointName === '' ||
            addAnotherCheckpoint[0].description === '' ||
            addAnotherCheckpoint[0].assignDate === null ||
            addAnotherCheckpoint[0].uploadedImage === '');

        if (assignButtonClicked && errorInAddAnotherCheckpoint) {
            return;
        }
    }


    removeAddAnotherCheckpoint = (indexToRemove: number) => {
        const { addAnotherCheckpoint } = this.state;
        if (addAnotherCheckpoint.length > 1) {
            const updatedActions = addAnotherCheckpoint.filter(
                (_, index) => index !== indexToRemove
            );
            this.setState({ addAnotherCheckpoint: updatedActions });
        }
    };


    handleAssignAnotherCheckpoint = () => {
        const { addAnotherCheckpoint } = this.state;
        const counter = addAnotherCheckpoint[addAnotherCheckpoint.length - 1].counter + 1;
        this.setState({
            addAnotherCheckpoint: [...addAnotherCheckpoint, { counter, nonCompiledCheckpointName: "", uploadedImage: "", uploadedImagePreview: "", description: "", assignedPerson: { label: "", value: "" }, assignDate: null }],
        });
    }

    handleChangenonCompiledCheckpointName = (value: string, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].nonCompiledCheckpointName = value;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    handleChangeDescription = (value: string, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].description = value;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    changeImg(event: React.ChangeEvent<HTMLInputElement>, index: number) {
        const files = event.target.files;
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            addAnotherCheckpoint[index].uploadedImagePreview = fileReader.result;
            this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleUploadedImage = (file: File | undefined, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].uploadedImage = file;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    handleAssignPerson = (newValue: any, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].assignedPerson = newValue;
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }

    handleChangeAssignDatePicker = (selectedDate: any, index: number) => {
        if (selectedDate && typeof selectedDate === 'object') {
            selectedDate?.setHours(selectedDate.getHours() + 5);
            selectedDate?.setMinutes(selectedDate.getMinutes() + 30);
            const formatedDate = selectedDate?.toISOString().replace(/(,|\s)+/g, 'T');

            const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
            addAnotherCheckpoint[index].assignDate = new Date(formatedDate);
            this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
        }
    }
    handleChangeAssignedDate = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const addAnotherCheckpoint = [...this.state.addAnotherCheckpoint];
        addAnotherCheckpoint[index].assignDate = new Date(event.target.value);
        this.setState({ addAnotherCheckpoint: addAnotherCheckpoint });
    }
    // Customizable Area End
}
