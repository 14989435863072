import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    styled,
    Grid,
    TextField
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";
import { calendarLogo, upload } from "./assets";

const today = new Date()

// Customizable Area End

import NonCompiledAssignmentController, {
    Props,
    configJSON,
} from "./NonCompiledAssignmentController";

export default class NonCompiledAssignment extends NonCompiledAssignmentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    checkpointAssignmentForm = () => {
        const options = [{ value: "1", label: "Sam" }, { value: "2", label: "Patty" }, { value: "3", label: "Rohan" },]
        const { addAnotherCheckpoint, assignButtonClicked } = this.state;
        return (
            <Box className="mainBox">
                <Grid container className="mainGrid" spacing={2}>

                    {this.state.addAnotherCheckpoint.map((value, index) => {
                        const displayError = assignButtonClicked && index === 0;

                        return (
                            <>
                                <Grid item md={6} xs={12}>
                                    <Typography className="inputLabel">Non Compiled Checkpoint Name</Typography>
                                    <Input
                                        name="no_compiled_checkpoint_name"
                                        data-test-id="no_compiled_checkpoint_name"
                                        className="enterInputField"
                                        value={value.nonCompiledCheckpointName}
                                        disableUnderline
                                        placeholder="Non Compiled Checkpoint Name"
                                        onChange={(event) => this.handleChangenonCompiledCheckpointName(event.target.value, index)}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !addAnotherCheckpoint[0].nonCompiledCheckpointName && configJSON.errorMsg}
                                    </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}></Grid>

                                <Grid item md={6} xs={12}>
                                    <Typography className="inputLabel">Description</Typography>
                                    <textarea
                                        name="description"
                                        data-test-id="description"
                                        className="remarksTextarea"
                                        value={value.description}
                                        placeholder="Description"
                                        onChange={(event) => this.handleChangeDescription(event.target.value, index)}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !addAnotherCheckpoint[0].description && configJSON.errorMsg}
                                    </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Box className="uploadImageBox">
                                        <Button component="label" className="uploadImage">
                                            <div className="upload-img">
                                                <img
                                                    src={value.uploadedImagePreview || upload}
                                                    alt="upload image"
                                                    className="uploadedImg"
                                                />
                                            </div>
                                            {!value.uploadedImage && (
                                                <> <div className="uploadImageFont">Upload Image</div> </>
                                            )}
                                            <input
                                                data-test-id="upload_image"
                                                type="file"
                                                name="image"
                                                accept="image/png, image/jpeg"
                                                onChange={(event) => {
                                                    this.handleUploadedImage(event.target.files?.[0], index);
                                                    this.changeImg(event, index);
                                                }}
                                            />
                                        </Button>
                                    </Box>
                                    <Typography className="errorStyle">
                                        {displayError && !addAnotherCheckpoint[0].uploadedImage && configJSON.errorMsg}
                                    </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        options={options}
                                        // loading={!Object.keys(personList).length}
                                        popupIcon={<KeyboardArrowDown />}
                                        data-test-id="assign_to"
                                        id="assign_to"
                                        getOptionLabel={(option) => option?.label}
                                        onChange={(event, newValue) => this.handleAssignPerson(newValue, index)}
                                        onInputChange={() => { }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                id="assign_to"
                                                inputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder="Assign Person for Non Compiled Checkpoint"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !addAnotherCheckpoint[0].assignedPerson.value && configJSON.errorMsg}
                                    </Typography>
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <DatePicker
                                        className="field-input"
                                        scrollableYearDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={100}
                                        dateFormat="dd/MM/yyyy"
                                        dateFormatCalendar="MMMM"
                                        data-test-id="assign_date"
                                        name="assign_date"
                                        placeholderText="Due Date"
                                        minDate={today}
                                        wrapperClassName="date-picker"
                                        customInput={this.CustomInputDate(value.assignDate, {}, this.handleChangeAssignedDate)}
                                        popperClassName="example-datepicker-class"
                                        selected={value.assignDate}
                                        onChange={(date) => {
                                            this.handleChangeAssignDatePicker(date, index);
                                        }}
                                    />
                                    <Typography className="errorStyle">
                                        {displayError && !addAnotherCheckpoint[0].assignDate && configJSON.errorMsg}
                                    </Typography>
                                </Grid>

                                {this.state.addAnotherCheckpoint.length > 1 && (
                                    <>
                                        <Grid item md={6} xs={12}>
                                            <Button
                                                data-test-id="remove_corrective"
                                                className="addAssignBtn addAssignAction"
                                                onClick={() => this.removeAddAnotherCheckpoint(index)}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                        <Grid item md={6} xs={12} style={{padding: "0px"}}></Grid>
                                    </>
                                )}
                            </>)
                    })}

                    <Grid item md={6} xs={12}>
                        <Button
                            data-test-id="assign_another_checkpoint"
                            className="addAssignBtn addAssignAction"
                            onClick={this.handleAssignAnotherCheckpoint}
                        >
                            + Assign Another Non Compiled Checkpoint
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    CustomInputDate = (value: any, onClick: any, onChange: any) => {
        return (
            <Input
                placeholder="Select Date"
                value={value}
                onClick={onClick}
                fullWidth={true}
                disableUnderline
                data-test-id="targeted_date_test"
                name="date_test"
                onChange={onChange}
                defaultValue={""}
                endAdornment={
                    <div>
                        <InputAdornment position="end">
                            <img src={calendarLogo} style={{ width: 28, height: 28, cursor: 'pointer' }} />
                        </InputAdornment>
                    </div>
                }
            />
        );
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Wrapper>
                        <Container maxWidth={"xl"}>

                            <Box className="headingWrapper">
                                <Typography className="subHeadingText">Audit</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText trimWords">Transformer1</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText trimWords">NeutralEarthing</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText trimWords activeHeadingText">Assign Person</Typography>
                            </Box>
                            <Box className="checkpointTitle">
                                <Typography className="mainHeading trimWords">Assign Person</Typography>
                            </Box>

                            {this.checkpointAssignmentForm()}
                            <Box className="submit-button">
                                <Button
                                    data-test-id="assign_btn"
                                    variant="contained"
                                    className="add-btn"
                                    onClick={this.handleAssignButton}
                                >
                                    Assign
                                </Button>
                            </Box>
                        </Container>
                    </Wrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    position: "relative",
    boxSizing: "border-box",
    padding: "20px 0",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    "& .loader-outer": {
        height: "100%",
        top: 0,
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        top: "40%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        left: "50%",
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .subHeadingText": {
        fontStyle: "normal",
        leadingTrim: "both",
        textEdge: "cap",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        fontSize: "14px",
    },
    "& .uploadImage": {
        borderRadius: '8px',
        textTransform: 'capitalize',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '150px',
        padding: '10px 15px',
        width: '100%',
        "& .MuiButton-label": {
            diplay: 'flex',
            flexDirection: 'column',
        },
    },
    "& .uploadedImg": {
        maxWidth: '5rem',
        maxHeight: '5rem',
        objectFit: 'contain'
    },
    "& .uploadImageBox": {
        display: "flex",
        height: "100%",
        flexDirection: "column-reverse"
    },
    "& .enterInputField": {
        border: '1px solid lightgray',
        fontSie: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .remarksTextarea": {
        resize: 'none',
        userDrag: 'none',
        border: '1px solid lightgray',
        fontSie: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        height: '150px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .inputLabel": {
        color: 'var(--Black, #18181E)',
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'ClotherRegular',
        marginBottom: '5px'
    },
    "& .headingWrapper": {
        gap: "10px",
        display: "flex",
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .activeHeadingText": {
        fontStyle: "normal",
        fontWeight: 700,
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        cursor: "pointer"
    },
    "& .mainHeading": {
        color: "#2B2A29",
        textEdge: "cap",
        fontSize: "26px",
        leadingTrim: "both",
        fontWeight: 700,
        fontStyle: "normal",
        fontFamily: "ClotherBold",
    },
    "& .buttonsMainBox": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "25px",
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .noData": {
        textAlign: "center",
        marginTop: "20px",
        fontSize: "18px"
    },
    "& .checkpointTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0",
    },
    "& .field-input": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '152px',
        padding: '10px 15px',
        width: '100%',
        outline: 'none'
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    "& .Location-input .MuiInputBase-input": {
        height: "14px",
    },
    "& .MuiInputBase-input": {
        height: "15px",
    },
    "& .MuiInputBase-root": {
        fontFamily: "ClotherBold",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px"
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "0.85rem",
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .mainGrid": {
        padding: '20px',
        },
    "& .mainBox": {
        backgroundColor: 'white',
        width: "100%",
        margin: "15px 0px"
    },
    "& .add-btn": {
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .addAssignBtn": {
        color: "#8735E1",
        border: "1px solid #8735E1",
        fontSize: "20px"
        , background: "#FFF",
        fontFamily: "ClotherBold",
        fontWeight: 700,
        borderRadius: "8px",
        textTransform: "capitalize",
        margin: "0px",
    },
    "& .addAssignAction": {
        width: "100%",
        marginTop: "15px",
    },
    "& .PreviousBtn": {
        padding: "10px",
        color: "#8735E1",
        textAlign: "center",
        borderRadius: "8px",
        border: "1px solid #8735E1",
        background: "#FFF",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "18px",
        fontWeight: 700
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
});

// Customizable Area End