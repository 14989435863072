import React from "react";

// Customizable Area Start
import {
  Avatar,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { locationIcon, timeIcon } from "./assets";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import PerformanceTrackerController, {
  Props,
} from "./PerformanceTrackerController";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class ActionTrackerPost extends PerformanceTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { actionPostData } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid style={{ padding: "30px", background: "rgb(238, 238, 238)" }}>
          <Grid container>
            <Typography style={webStyle.actionTrackerHeaders}>Action tracker</Typography>
            <NavigateNext style={{ fill: '#DCDCDC' }} />
            <Typography style={webStyle.currentPage}>Post</Typography>
          </Grid>
          <Typography style={webStyle.header}>Post</Typography>
          {!this.state.loaderData && (
            <Paper style={webStyle.paperStyle}>
              <Grid>
                <Typography style={webStyle.firstTypography} data-test-id="profileImgText">
                  <Avatar data-test-id="profileImg" src={actionPostData.attributes.worker_account?.profile_image} style={webStyle.profileImage} />
                  <span style={webStyle.fullName as React.CSSProperties}>{actionPostData.attributes.worker_account?.full_name} Created this post</span>
                </Typography>
                <img src={actionPostData.attributes.post?.image_url}
                  alt="Your Image"
                  style={webStyle.postImage}
                  data-test-id="postImage"
                />
                <Typography style={webStyle.postDescribtion}>
                  {actionPostData.attributes.post?.description}
                </Typography>
                <Grid container>
                  <Typography style={webStyle.locationTypography as React.CSSProperties}>
                    <img src={locationIcon} style={webStyle.locationIcon} />
                    <span style={webStyle.locationText as React.CSSProperties}>{actionPostData.attributes.post?.location || "No Location"}</span>
                  </Typography>
                  <Typography style={webStyle.usernameTimestyle}>
                    <Avatar src={actionPostData.attributes.worker_account?.profile_image} style={webStyle.bottomProfileImg} />
                    <span style={webStyle.locationText as React.CSSProperties}>{actionPostData.attributes.worker_account?.full_name}</span>
                  </Typography>
                  <Typography style={webStyle.usernameTimestyle}>
                    <img src={timeIcon} style={webStyle.timeIcon} />{getTimeFromNow(actionPostData.attributes.created_at)} ago
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}

          <div style={webStyle.btnDiv}>
            {this.state.is_supervisor ? (
              <>
                {(this.state.actionPostData.attributes.status !== "verified" && this.state.actionPostData.attributes.status !== "pending_review") && (
                  <Button
                    data-test-id="reAssignPage"
                    variant="outlined"
                    color="secondary"
                    style={webStyle.btnStyle as React.CSSProperties}
                    onClick={this.navigatePage.bind(this, "reAssign")}
                  >
                    Re-Assign
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  style={webStyle.btnStyleColor as React.CSSProperties}
                  data-test-id="followUpPage"
                  onClick={this.navigatePage.bind(this, "followUp")}
                >
                  Follow Up
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="updateBtn"
                onClick={this.navigatePage.bind(this, "update")}
              >
                Update
              </Button>
            )}
          </div>
        </Grid>
        <Loader loading={this.state.loaderData} />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  locationText: {
    overflow: "hidden",
    maxWidth: "130px",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
  },
  timeIcon: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
  },
  bottomProfileImg: {
    width: '26px',
    height: '26px',
    border: '1px',
    marginRight: '6px',
  },
  postDescribtion: {
    fontFamily: 'ClotherRegular',
    fontSize: '18px',
    lineHeight: '22px',
    paddingBottom: '20px',
  },
  postImage: {
    width: '100%',
    height: '380px',
    margin: '20px 0px',
    borderRadius: '8px',
  },
  profileImage: {
    width: '46px',
    height: '46px',
    marginRight: '16px',
  },
  header: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#2B2A29',
    padding: '20px 0px'
  },
  currentPage: {
    fontFamily: 'ClotherBold',
    fontSize: '14px',
    color: '#8735E1',
    marginLeft: '2px',
  },
  actionTrackerHeaders: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#18181E',
    margin: '0px',
  },
  locationIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  locationTypography: {
    alignItems: "center",
    display: "flex",
    borderRadius: "6px",
    background: "#F7F3F3",
    whiteSpace: "nowrap",
    color: "#A1A1A1",
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    padding: "5px 15px",
    marginRight: '20px',
  },
  btnStyleColor: {
    minWidth: "160px",
    height: "50px",
    backgroundColor: "#8735E1",
    textTransform: "none",
    color: "white",
    border: "1px solid #8735E1",
    padding: '10px',
    borderRadius: "8px",
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
  },
  paperStyle: {
    height: "auto",
    padding: "30px",
    background: "#fff",
    minHeight: "80vh",
    marginTop: '10px'
  },
  btnStyle: {
    minWidth: "160px",
    height: "50px",
    textTransform: "none",
    borderColor: "rgb(135, 53, 225)",
    border: "1px solid #8735E1",
    color: "#8735E1",
    fontFamily: 'ClotherBold',
    fontSize: "20px",
    lineHeight: '22px',
    backgroundColor: "white",
    borderRadius: '8px',
    marginRight: '16px',
  },
  btnDiv: {
    padding: "30px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  firstTypography: {
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    display: "flex",
    alignItems: "center",
  },
  fullName: {
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
  },
  usernameTimestyle: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    display: "flex",
    alignItems: "center",
    color: '#A1A1A1',
    marginRight: '20px',
  },
};
// Customizable Area End
