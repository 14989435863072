import React from "react";
import {
    Typography,
    withStyles,
    Dialog,
    Button,
    Box,
} from "@material-ui/core";
import PrivacyPolicyController, { Props, configJSON } from "./PrivacyPolicyController";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../components/src/Loader.web";
// Customizable Area Start
import "../../email-account-registration/src/Accounts.css";
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    render() {
        return (
            <>
                <Loader loading={this.state.loader} data-test-id="loader" />
                <Box position={"sticky"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} style={webStyles.bottomBtnBox}>
                    <Typography style={webStyles.privacyHeader}>{configJSON.privacyPolicyText}</Typography>
                    <CloseIcon data-test-id="closeIcon" style={webStyles.closeIconStyle} onClick={this.props.setPrivacyPolicyModal}></CloseIcon>
                </Box>
                <Typography className="privacyPolicy" style={webStyles.policyData} dangerouslySetInnerHTML={{ __html: this.state.privacyData }}></Typography>
            </>
        );
    }
}
// Customizable Area End

// Customizable Area Start

const webStyles = {
    privacyHeader: {
        fontFamily: 'ClotherBold',
        fontSize: '24px',
    },
    bottomBtnBox: {
        top: "0px",
        height: '76px',
        padding: '30px 30px 20px',
        background: 'white'
    },
    policyData: {
        padding: '0px 30px',
        background: 'white',
    },
    closeIconStyle: {
        fill: '#A1A1A1',
        cursor: 'pointer',
    },
    iAgreeBtn: {
        width: "160px",
        height: "50px",
        borderRadius: "8px",
        background: '#8735E1',
        margin: '10px',
        color: '#FFFFFF',
        fontSize: '20px',
        fontFamily: 'ClotherBold'
    }
};
// Customizable Area End

