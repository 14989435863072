import React from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Radio,
    FormGroup,
    RadioGroup,
    FormControlLabel,
    FormHelperText,
    Paper,
    Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";

const today = new Date()

// Customizable Area End

import WorkerCheckpointDetailController, {
    Props,
} from "./WorkerCheckpointDetailController";

export default class WorkerCheckpointDetail extends WorkerCheckpointDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    workerCheckpointDetailForm = () => {
        return (
            <Box className="mainBox">
                <Grid container className="paperContent" spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Unit of Measurement:</Box>
                            <Box className="boxFont2 trimWords">Lorem Ipsum</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Total Number(Target):</Box>
                            <Box className="boxFont2">12</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Compiled:</Box>
                            <Box className="boxFont2">12</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Non Compiled:</Box>
                            <Box className="boxFont2">07</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Paper elevation={0}>
                            <Box>Targeted Date:</Box>
                            <Box className="boxFont2">20/10/2023</Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className="remarksGrid">
                            <Box className="remarksBox1">Remarks:</Box>
                            <Box className="remarksBoxFont2">
                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                                Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup className="footerWarning">
                            <Grid container>
                                <Grid>
                                    <FormHelperText className="radioButtonLabel">
                                        Assign person for Non Compiled Equipment?
                                    </FormHelperText>
                                </Grid>
                                <Grid>
                                    <RadioGroup
                                        aria-labelledby="hazard-radio-buttons-group-label"
                                        defaultValue="no"
                                        name="radio-buttons-group"
                                        className="radiobuttons"
                                    >
                                        <FormControlLabel
                                            name="hazard"
                                            data-id="hazardFirstId"
                                            value="yes"
                                            control={<Radio color="primary" />}
                                            label={
                                                <Typography>
                                                    Yes
                                                </Typography>
                                            }
                                        />
                                        <FormControlLabel
                                            name="hazard"
                                            data-id="hazardSecondId"
                                            value="no"
                                            control={<Radio color="primary" />}
                                            label={
                                                <Typography>
                                                    No
                                                </Typography>
                                            }
                                        />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <WorkerWrapper>
                        <Container maxWidth={"xl"}>

                            <Box className="headingWrapper">
                                <Typography className="subHeadingText">Audit</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText trimWords">Transformer3</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText activeHeadingText trimWords">NeutralEarthing</Typography>
                            </Box>
                            <Box className="checkpointTitle">
                                <Typography className="mainHeading trimWords">Neutral Earthing</Typography>
                            </Box>

                            {this.workerCheckpointDetailForm()}

                            <Box className="submit-button">
                                <Button
                                    data-test-id="assign_btn"
                                    variant="contained"
                                    className="add-btn"
                                >
                                    Assign
                                </Button>
                            </Box>
                        </Container>
                    </WorkerWrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const WorkerWrapper = styled(Box)({
    padding: "20px 0",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    "& .headingWrapper": {
        gap: "10px",
        display: "flex",
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .footerWarning": {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    "& .radiobuttons": {
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    "& .radioButtonLabel": {
        fontFamily: 'ClotherRegular',
        color: '#2B2A29',
        fontSize: '17px',
        fontweight: 'bolder',
        marginRight: '20px',
    },
    "& .paperContent .MuiPaper-root": {
        backgroundColor: 'rgb(238, 238, 238)',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        height: '60px',
        padding: '10px 15px',
    },
    "& .boxFont2": {
        fontFamily: "clotherBold",
    },
    "& .remarksBoxFont2": {
        minHeight: "100px",
        fontFamily: "clotherRegular",
        fontSize: '17px',
        padding: '10px 15px',
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .subHeadingText": {
        fontWeight: 400,
        lineHeight: "22px",
        textEdge: "cap",
        fontStyle: "normal",
        leadingTrim: "both",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        fontSize: "14px",
    },
    "& .remarksGrid": {
        border: '1px solid lightgrey',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        width: '100%',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    "& .enterInputField": {
        height: '60px',
        border: '1px solid lightgray',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        padding: '10px 15px',
        width: '96%'
    },
    "& .remarksBox1": {
        fontFamily: "clotherBold",
        height: '20px',
        borderBottom: "1px solid lightgrey",
        padding: '10px 15px'
    },
    "& .activeHeadingText": {
        fontWeight: 700,
        color: "#8735E1",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        leadingTrim: "both",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        cursor: "pointer"
    },
    "& .mainHeading": {
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: "26px",
        leadingTrim: "both",
        color: "#2B2A29",
        textEdge: "cap",
        fontFamily: "ClotherBold",
    },
    "& .checkpointTitle": {
        display: "flex",
        margin: "10px 0",
        justifyContent: "space-between",
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .mainGrid": {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '20px 20px 0px 20px',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        padding: '20px 20px 20px 20px',
        backgroundColor: 'white',
        margin: "15px 0px"
    },
    "& .add-btn": {
        width: "160px",
        height: "50px",
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
});

// Customizable Area End
