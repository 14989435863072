import React from "react";
import {
  Box,
  Typography,
  Grid,
  Radio,
  withStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";
import UserProfileBasicController, { Props, configJSON } from "./UserProfileBasicController";
import { NavigateNext } from '@material-ui/icons';

export default class UserProfileBasic extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Grid container style={webStyles.mainContainer}>
        <Grid item lg={12} sm={12}>
          <Box p={{ sm: 4 }}>
            <Box style={webStyles.headerBox}>
              <span data-test-id="account-text" style={webStyles.accountText} onClick={this.goToAccount.bind(this)}>{configJSON.AccountText}</span>
              <NavigateNext />
              <span style={webStyles.currentPage} data-test-id="delete-text">{configJSON.privacySettingText}</span>
            </Box>
            <Typography style={webStyles.accoundHeading} data-test-id="account-delete-header">{configJSON.privacySettingText}</Typography>
          </Box>
          <CustomBox>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" style={webStyles.privacyHeading}>{configJSON.privacyHeadingText}</FormLabel>
              <RadioGroup
                data-test-id="radio-buttons"
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={configJSON.reportingValue}
                name="radio-buttons-group"
                value={this.state.selectedSetting}
                onChange={this.handleChange.bind(this)}
              >
                <FormControlLabel data-test-id="selected-setting" className={this.state.selectedSetting === configJSON.reportingValue ? "is-active" : "no-active"} value={configJSON.reportingValue} control={<Radio data-test-id="radio1"/>} label={configJSON.reportingManagerText} />
                <FormControlLabel data-test-id="team-manager-setting" className={this.state.selectedSetting === configJSON.teamManagersValue ? "is-active" : "no-active"} value={configJSON.teamManagersValue} control={<Radio />} label={configJSON.reportingManagerTeamMenmText} />
                <FormControlLabel data-test-id="plant-team-setting" className={this.state.selectedSetting === configJSON.plantTeamValue ? "is-active" : "no-active"} value={configJSON.plantTeamValue} control={<Radio />} label={configJSON.everyPlantText} />
                <FormControlLabel className={this.state.selectedSetting === configJSON.everyoneAppValue ? "is-active" : "no-active"} value={configJSON.everyoneAppValue} control={<Radio />} label={configJSON.everyAppUserText} />
              </RadioGroup>
            </FormControl>
          </CustomBox>
        </Grid>
      </Grid>
    );
  }
}

// Customizable Area Start

const webStyles = {
  mainContainer: {
    background: 'rgb(237, 238, 238)',
    height: '100vh',
  },
  privacyHeading: {
    fontFamily: "ClotherBold",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#2B2A29",
    paddingBottom: '16px',
  },
  headerBox: {
    fontSize: '0.875rem',
    color: '#A1A1A1',
    display: 'flex',
    gap: '0.3rem',
    alignItems: 'center',
    marginBottom: '1.25rem'
  },
  accountText: {
    fontFamily: 'ClotherRegular',
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer',
  },
  accoundHeading: {
    fontFamily: "ClotherBold",
    fontSize: '24px',
    lineHeight: "24px",
    paddingBottom: '11px'
  },
  currentPage: {
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontSize: "14px",
    lineHeight: "22px",
    cursor: 'pointer',
  },
};

const CustomBox = withStyles({
  root: {
    background: '#FFF',
    height: '194px',
    padding: "20px",
    borderRadius: "8px",
    margin: '0 30px',
    "& .MuiTypography-body1": {
      fontFamily: 'inherit',
      fontSize: "14px",
      lineHeight: "16px",
      color: '#18181E',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#8735E1',
    },
    '& .is-active': {
      fontFamily: 'ClotherBold'
    },
    '& .no-active': {
      fontFamily: 'ClotherRegular'
    }
  }
})(Box)
// Customizable Area End

