import React from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  styled,
  Backdrop,
} from "@material-ui/core";
import {
  iconPlus,
  iconTick,
  cardGroup,
  heartHandsGrad,
  iconSafety,
} from "../assets";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MobileFooterMenu from "../MobileFooterMenu.web";
import ProfileController, { Props } from "../ProfileController.web";
import { account, posts, help, imgh, heartHands, star } from "../assets";
import { webStyles } from "../../../../components/src/styles";
import { PostView } from "../PostView.web";
import { AccountTile } from "../../../../components/src/AccountTile";

export default class MyProfile extends ProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const isPhone = window.innerWidth < 600;
    const isWide = !isPhone;

    const sideBarItems = [
      {
        img: imgh,
        name: "Home",
        link: "Dashboard",
      },
      {
        img: posts,
        name: "My Posts",
      },
      {
        img: account,
        name: "Account",
        link: "EditProfile",
      },
      {
        img: help,
        name: "Help",
      },
    ];
    const { editProfileData, percentageProfile } = this.state;
    const inputDate = new Date(editProfileData?.attributes?.date_of_birth);
    const formattedDate = inputDate.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    return (
      this.state.isLoading ? (
        <Backdrop style={{ color: "#fff" ,zIndex:1}} open={this.state.isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
         <div className="my-profile">
        <style>
          {`.my-profile * {
              font-family: 'ClotherRegular';
          }`}
        </style>
        <Box>
          <Box style={{ backgroundColor: "#eee" }}>
            <Grid>
              <Grid>
                <Box p={{ sm: 4 }}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={
                      {
                        ...webStyles.profileContainer,
                        ...(isWide && { backgroundColor: "#fff" }),
                      } as React.CSSProperties
                    }
                  >
                    {isWide && (
                      <IconButton
                        style={webStyles.dotMenu as React.CSSProperties}
                      >
                        <MoreVertIcon
                          onClick={this.handleClick}
                          data-id="handleClick"
                        />
                        <CustomMenu
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}
                          data-id="handleClose"
                          style={{
                            position: "absolute",
                            top: "35px",
                            left: "-35px",
                          }}
                        >
                          <MenuItem
                            onClick={this.handleMenuItemClick}
                            data-id="handleMenuClick"
                          >
                            Edit Profile
                          </MenuItem>
                        </CustomMenu>
                      </IconButton>
                    )}
                    <Grid item>
                      <Box
                        position="relative"
                        display="inline-flex"
                        width="6.8rem"
                      >
                        <CircularProgress
                          variant="determinate"
                          value={percentageProfile}
                          size="6.8rem"
                          style={webStyles.innerCircle as React.CSSProperties}
                        />
                        <CircularProgress
                          variant="determinate"
                          value={100 - percentageProfile}
                          size="6.8rem"
                          style={
                            webStyles.backgroundCircle as React.CSSProperties
                          }
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <div
                            style={webStyles.percentDiv as React.CSSProperties}
                          >
                            {percentageProfile}
                          </div>
                          <Avatar
                            src={editProfileData?.attributes?.profile_image}
                            alt="User"
                            style={webStyles.avatarStyle}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item style={webStyles.avatarWrapper}>
                      <div style={webStyles.basicHeader}>
                        {editProfileData?.attributes?.full_name}
                      </div>
                      <div style={webStyles.avatarList}>
                        <span
                          style={
                            webStyles.userPropertyName as React.CSSProperties
                          }
                        >
                          Role:
                        </span>
                        <span
                          style={
                            webStyles.userPropertyValue as React.CSSProperties
                          }
                        >
                          {editProfileData?.attributes?.role_name}
                        </span>
                      </div>
                      <div style={webStyles.avatarList}>
                        <span
                          style={
                            webStyles.userPropertyName as React.CSSProperties
                          }
                        >
                          Employee Code:
                        </span>
                        <span
                          style={
                            webStyles.userPropertyValue as React.CSSProperties
                          }
                        >
                          {editProfileData?.attributes?.employee_id}
                        </span>
                      </div>
                    </Grid>
                    <Box marginTop="1.25rem">
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={4}>
                          <AccountTile
                            name="Followers"
                            bottomContent="0"
                            img={iconPlus}
                          />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <AccountTile
                            name="Following"
                            bottomContent="0"
                            img={iconTick}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AccountTile
                            name="Safety"
                            bottomContent="0%"
                            img={iconSafety}
                            inRow={isPhone}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AccountTile
                            name="Health score"
                            bottomContent="0"
                            img={iconTick}
                            inRow={isPhone}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AccountTile
                            name="Environment Score"
                            bottomContent="0"
                            img={iconPlus}
                            inRow={isPhone}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <AccountTile
                            name="Contribution"
                            bottomContent={
                              <div style={webStyles.bottomContent}>
                                <span style={webStyles.bottomSpan}>
                                  Level 1
                                </span>
                                <img
                                  src={star}
                                  alt="star"
                                  style={webStyles.starImg}
                                />
                              </div>
                            }
                            img={heartHands}
                            addTop={
                              <div>
                                <span
                                  style={
                                    webStyles.addTop as React.CSSProperties
                                  }
                                >
                                  0
                                </span>
                                <span>Points</span>
                              </div>
                            }
                            addBottom={
                              <div style={webStyles.addBottomCont}>
                                <span
                                  style={
                                    webStyles.addBottom as React.CSSProperties
                                  }
                                >
                                  Need
                                </span>
                                <span
                                  style={
                                    webStyles.addBottomHigh as React.CSSProperties
                                  }
                                >
                                  + 0
                                </span>
                                <span
                                  style={
                                    webStyles.addBottom as React.CSSProperties
                                  }
                                >
                                  For Level 2
                                </span>
                              </div>
                            }
                            extended
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                  {isWide && (
                    <Grid item style={webStyles.contentContainer}>
                      <Grid
                        item
                        xs={8}
                        style={{
                          borderRadius: "0.5rem",
                          backgroundColor: "#fff",
                          marginRight: "1.2rem",
                        }}
                      >
                        <Box style={webStyles.headB}>
                          <h3>Recent Posts</h3>
                          <p style={{ ...webStyles.headp, cursor: "pointer" }}>
                            View All
                          </p>
                        </Box>
                        {this.state.dashboardData?.map((postData: any) => (
                          <PostView key={postData.id} postData={postData} />
                        ))}
                      </Grid>

                      <Grid item xs={4}>
                        <Box style={webStyles.listBox}>
                          <Box style={webStyles.headB}>
                            <h3>Contribution Points</h3>
                            <p
                              style={{ ...webStyles.headp, cursor: "pointer" }}
                            >
                              See All
                            </p>
                          </Box>
                          <Box padding="1rem">
                            <Box
                              borderRadius="8px"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-evenly",
                                maxWidth: "100%",
                                height: "auto",
                                color: "#fff",
                                padding: "1rem",
                                fontSize: "0.875rem",
                                background: `url(${cardGroup}) right bottom no-repeat, linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)`,
                              }}
                            >
                              <img
                                src={heartHandsGrad}
                                alt="card icon"
                                style={{
                                  width: "2.375rem",
                                  height: "2.375rem",
                                }}
                              />
                              <Typography>Your Contribution Points</Typography>
                              <Typography
                                style={{
                                  fontWeight: 700,
                                  fontSize: "2.5rem",
                                  marginBottom: "0.5rem",
                                }}
                              >
                                0
                              </Typography>
                              <Typography>
                                Need{" "}
                                <span style={{ fontWeight: 700 }}>+ 0</span>{" "}
                                points for level 20
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          style={{ ...webStyles.listBox, marginTop: "1.2rem" }}
                        >
                          <Box id="subh" style={webStyles.headB}>
                            <h3>Risk Profile Card</h3>
                            <p
                              style={{ ...webStyles.headp, cursor: "pointer" }}
                            >
                              Learn More
                            </p>
                          </Box>
                          <Box padding="1rem">
                            <Grid
                              container
                              style={{
                                borderRadius: "8px",
                                color: "#fff",
                                padding: "1rem",
                                background: `url(${cardGroup}) right bottom no-repeat, linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)`,
                              }}
                            >
                              <Grid
                                item
                                style={{
                                  fontSize: "0.875rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                  gap: "0.5rem",
                                }}
                                xs={12}
                                sm={6}
                                lg={7}
                              >
                                <Typography
                                  style={{
                                    fontSize: "1.4rem",
                                    fontWeight: 700,
                                  }}
                                >
                                  {editProfileData?.attributes?.full_name}
                                </Typography>
                                <div>
                                  Function:{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    {editProfileData?.attributes?.function_name}
                                  </span>
                                </div>
                                <div>
                                  Location:{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    {editProfileData?.attributes?.location_name}
                                  </span>
                                </div>
                                <div>
                                  Role:{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    {editProfileData?.attributes?.role_name}
                                  </span>
                                </div>
                                <div>
                                  Date:{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    {formattedDate}
                                  </span>
                                </div>
                                <div>
                                  Emp Code:{" "}
                                  <span style={{ fontWeight: 700 }}>
                                    {editProfileData?.attributes?.employee_id}
                                  </span>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={5}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={
                                    editProfileData?.attributes?.profile_image
                                  }
                                  alt="user photo"
                                  style={{
                                    width: "100%",
                                    maxWidth: "140px",
                                    height: "auto",
                                    margin: "auto",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {isPhone && (
                    <MobileFooterMenu
                      navigation={this.props.navigation}
                      items={sideBarItems}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>)
    );
  }
}
const CustomMenu = styled(Menu)({
  "& .MuiList-padding": {
    paddingBottom: "0px",
  },
});
