import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { EditSteps } from "./myProfile/types";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel?: any;
  logoutClear?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    attributes: any;
    type: string;
    quantity: string;
  }[];
  type: string;
  token: string;
  step: EditSteps;
  editProfileData?: any;
  anchorEl: any;
  selectRole: any;
  selectCountrie: any;
  selectEmployeType: any;
  selectLocation: any;
  selectBloodGroupType: any;
  selectFunctionType: any;
  selectSubFunctionType: any;
  selectWorkLocation: any;
  activeStep: number;
  tooltipVisible: boolean;
  goToLast: boolean;
  percentageProfile: any;
  countrieCode: any;
  isLoading: boolean;
  // Customizable Area End
}
interface SS {}

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllPostDataAPICallId: string = "";
  profileSchema: object;
  getAccountDetailsAPICallID: string = "";
  getWorkDetailsAPICallID: string = "";
  getfunctionAPICallID: string = "";
  getSubfunctionAPICallID: string = "";
  getWorkLocationCallID: string = "";
  updateProfileDataCallID: string = "";
  getPercentageAPICallID: string = "";
  getLocationAPICallID: string = "";
  getCountryCodeAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      token: "",
      step: EditSteps.Step1,
      editProfileData: {},
      anchorEl: null,
      selectRole: [],
      selectCountrie: [],
      selectEmployeType: [],
      selectLocation: [],
      selectBloodGroupType: [],
      selectFunctionType: [],
      selectSubFunctionType: [],
      selectWorkLocation: [],
      activeStep: 1,
      tooltipVisible: false,
      goToLast: false,
      percentageProfile: null,
      countrieCode: [],
      isLoading:false,
    };
    this.profileSchema = Yup.object({
      Official_email_address: Yup.string()
        .email(configJSON.emailErrMsg)
        .matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,"Please enter a valid email address")
        .notOneOf([Yup.ref("email_address")], "Official email address must be different from the personal email address")
        .required(configJSON.emailErrMsg),
      full_name: Yup.string()
        .required(configJSON.fullNameErrMsg)
        .min(3, "Full Name must be at least 3 characters long")
        .max(50, "Full Name max length must be 50 characters"),
      employee_id: Yup.string()
        .required(configJSON.empIDErrMsg)
        .min(3, "Employee ID must be at least 3 characters long"),
      mobile_number: Yup.string()
        .matches(/^\d+$/, "Please enter a valid numeric mobile number")
        .length(10, "Please enter a 10-digit mobile number")
        .typeError(configJSON.MobileErrMsg)
        .required(configJSON.MobileErrMsg),
      gender: Yup.string().required(configJSON.genderErrMsg),
      dob: Yup.string()
        .nullable()
        .required(configJSON.dobErrMsg),
      email_address: Yup.string()
        .email(configJSON.emailErrMsg)
        .matches(
          /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
          "Please enter a valid email address"
        )
        .required(configJSON.emailErrMsg),
    });
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPostDetialData();
    this.getAccountDetailsAPI();
    this.getWorkDetailsAPI();
    this.getPercentageAPI();
    this.getCountryCode();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (this.getAllPostDataAPICallId === webApiRequestCallId) {
          this.setState({ dashboardData: webResponseJson?.posts?.data || [] });
        } else if (webApiRequestCallId === this.getAccountDetailsAPICallID) {
          this.setState({ editProfileData: webResponseJson?.data || {} , isLoading:false});
          this.getLocationAPI(webResponseJson?.data.attributes.country_id);
          this.getFunctionAPI(
            webResponseJson?.data.attributes.profile_location_id
          );
          this.getSubFunctionAPI(webResponseJson?.data.attributes.category_id);
          this.getWorkLocationAPI(
            webResponseJson?.data.attributes.sub_category_id
          );
        }
        this.editStepselectRecieveFunc(webApiRequestCallId, webResponseJson);
        this.percentageGetReciveFunc(webApiRequestCallId, webResponseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  setStep(step: EditSteps) {
    this.setState({ step });
  }
  editStepselectRecieveFunc = (
    webApiRequestCallId: any,
    webResponseJson: any
  ) => {
    if (webApiRequestCallId === this.getWorkDetailsAPICallID) {
      this.setState({
        selectRole: webResponseJson?.roles?.data,
        selectEmployeType: webResponseJson?.employee_types?.data,
        selectBloodGroupType: webResponseJson?.blood_groups?.data,
        selectCountrie: webResponseJson?.countries?.data,
      });
    } else if (webApiRequestCallId === this.getLocationAPICallID) {
      this.setState({ selectLocation: webResponseJson.location.data });
    } else if (webApiRequestCallId === this.getfunctionAPICallID) {
      this.setState({ selectFunctionType: webResponseJson.functions.data });
    } else if (webApiRequestCallId === this.getSubfunctionAPICallID) {
      this.setState({
        selectSubFunctionType: webResponseJson.sub_functions.data,
      });
    } else if (webApiRequestCallId === this.getWorkLocationCallID) {
      this.setState({
        selectWorkLocation: webResponseJson.work_locations.data,
      });
    } else if (webApiRequestCallId === this.updateProfileDataCallID) {
      this.setState({isLoading:false});
      this.getAccountDetailsAPI();
    }
  };
  percentageGetReciveFunc = (
    webApiRequestCallId: any,
    webResponseJson: any
  ) => {
    if (webApiRequestCallId === this.getPercentageAPICallID) {
      this.setState({ percentageProfile: webResponseJson.percentage });
    } else if (webApiRequestCallId === this.getCountryCodeAPICallID) {
      this.setState({
        countrieCode: webResponseJson.country_code_and_flags?.data,
      });
    }
  };
  changleScemaBack = (toStep: number) => {
    if (toStep == 1) {
      this.profileSchema = Yup.object({
        full_name: Yup.string()
        .required(configJSON.fullNameErrMsg)
        .min(3, "Full Name must be at least 3 characters long")
        .max(50, "Full Name max length must be 50 characters"),
        Official_email_address: Yup.string().email(configJSON.emailErrMsg)
          .matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
            "Please enter a valid official email address")
          .required(configJSON.emailErrMsg),
        gender: Yup.string().required(configJSON.genderErrMsg),
        employee_id: Yup.string()
        .required(configJSON.empIDErrMsg)
        .min(3, "Employee ID must be at least 3 characters long"),
        email_address: Yup.string()
          .email(configJSON.emailErrMsg)
          .matches(/^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
            "Please enter a valid personal email address")
          .required(configJSON.emailErrMsg),

        mobile_number: Yup.string()
          .matches(/^\d+$/, "Please enter a valid numeric mobile number")
          .length(10, "Please enter a 10-digit mobile number")
          .typeError(configJSON.MobileErrMsg)
          .required(configJSON.MobileErrMsg),
        dob: Yup.string()
          .nullable()
          .required(configJSON.dobErrMsg),
      });
    }
    if (toStep == 2) {
      this.profileSchema = Yup.object({
        employee_type: Yup.string()
        .required("Please Select Employee Type"),
        Countrie: Yup.string().required("Please select Country"),
        subFunction: Yup.string().required("Please select sub function"),
        contractor_name: Yup.string().required("Please enter contractor name"),
        role: Yup.string().required("Please select role"),
        location: Yup.string().required("Please select location"),
        work_location: Yup.string().required("Please select work location"),
        work_location_desc: Yup.string()
        .when("work_location", {is: "0",then: Yup.string().required("Please enter work location description"),}),
        function: Yup.string().required("Please select function"),
      });
    }
  };

  getPostDetialData = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts"
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getAllPostDataAPICallId = getRequestMsg.messageId;
    runEngine.sendMessage(getRequestMsg.id, getRequestMsg);
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  skipStepFunction = () => {
    if (this.state.activeStep !== 3) {
      this.activeStepFun();
    } else {
      this.props.navigation.navigate("MyProfile");
    }
  };

  copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      this.state.editProfileData.attributes?.unique_auth_id
    );
    this.setState({ tooltipVisible: true });

    setTimeout(() => {
      this.setState({ tooltipVisible: false });
    }, 3000);
  };

  setEditProfileData(editProfileData: any) {
    this.setState({ editProfileData });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuItemClick = () => {
    this.props.navigation.navigate("EditProfile");
    this.handleClose();
  };

  getAccountDetailsAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({isLoading:true})
    this.getAccountDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getWorkDetailsAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_user_content_data`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getWorkDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getLocationAPI = async (index: number) => {
    console.log("its calling", index)
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_location?country_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getLocationAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };
  getFunctionAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_functions?location_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getfunctionAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getSubFunctionAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_sub_functions?function_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getSubfunctionAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getWorkLocationAPI = async (index: number) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_work_locations?sub_function_id=${index}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getWorkLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  activeStepFun = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }));
  };

  prviousStepFun = () => {
    this.setState((prevState) => ({ activeStep: prevState.activeStep - 1 }));
    this.setState({ step: 2 });
  };
  prviousStepFunPersonalGoal = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
      goToLast: true,
    }));
  };

  EditProfileDataAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    let dateObject = new Date(this.state.editProfileData.dob);

    let year = dateObject.getFullYear();
    let month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    let dayDate = dateObject
      .getDate()
      .toString()
      .padStart(2, "0");

    let formattedDate = `${year}-${month}-${dayDate}`;
    let formdata = new FormData();
    formdata.append("employee_id", this.state.editProfileData.employee_id);
    formdata.append("full_name", this.state.editProfileData.full_name);
    formdata.append("country_code", this.state.editProfileData.country_code);
    formdata.append(
      "full_phone_number",
      this.state.editProfileData.mobile_number
    );
    formdata.append("email", this.state.editProfileData.email_address);
    formdata.append(
      "official_email",
      this.state.editProfileData.Official_email_address
    );
    formdata.append("gender", this.state.editProfileData.gender);
    formdata.append(
      "contractor_name",
      this.state.editProfileData.contractor_name
    );
    formdata.append("icf_number_code", this.state.editProfileData.icf_Country_code);
    formdata.append("icf_name", this.state.editProfileData.ice_name);
    formdata.append("icf_number", this.state.editProfileData.ice_number);
    formdata.append(
      "employee_type_id",
      this.state.editProfileData.employee_type
    );
    formdata.append(
      "work_location_id",
      this.state.editProfileData.work_location == "Other"
        ? "Other"
        : this.state.editProfileData.work_location
    );

    this.state.editProfileData.work_location == "Other" &&
      formdata.append(
        "other_work_location",
        this.state.editProfileData.work_location_desc
      );
    formdata.append("blood_group_id", this.state.editProfileData.blood_group);
    formdata.append("category_id", this.state.editProfileData.function);
    formdata.append("sub_category_id", this.state.editProfileData.subFunction);
    formdata.append("role_id", this.state.editProfileData.role);
    formdata.append("country_id", this.state.editProfileData.Countrie);
    formdata.append("profile_location_id", this.state.editProfileData.location);
    this.state.editProfileData.fileUpload &&
      formdata.append("profile_image", this.state.editProfileData?.fileUpload);
    formdata.append("date_of_birth", formattedDate);
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.setState({isLoading:true})
    this.updateProfileDataCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getPercentageAPI = async () => {
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_profile_percentage`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getPercentageAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  onSubmitButton = (values: any, actions: any) => {
    const isStep1 = this.state.step === EditSteps.Step1;
    const isStep2 = this.state.step === EditSteps.Step2;
    const isStep3 = this.state.step === EditSteps.Step3;
    const nextStep =
      (isStep1 && EditSteps.Step2) ||
      (isStep2 && EditSteps.Step3) ||
      (isStep3 && EditSteps.StepComp) ||
      EditSteps.StepComp;
    if (isStep1) {
      this.profileSchema = Yup.object({
        contractor_name: Yup.string().required("Please enter contractor name"),
        employee_type: Yup.string().required("Please Select Employee Type"),
        role: Yup.string().required("Please select role"),
        Countrie: Yup.string().required("Please select Countrie"),
        location: Yup.string().required("Please select location"),
        subFunction: Yup.string().required("Please select sub function"),
        function: Yup.string().required("Please select function"),
        work_location: Yup.string().required("Please select work location"),
        work_location_desc: Yup.string().when("work_location", {
          is: "0",
          then: Yup.string().required("Please enter work location description"),
        }),
      });
    }
    if (isStep2) {
      this.profileSchema = Yup.object({
        ice_number: Yup.string()
          .matches(/^\d+$/, "Please enter a valid numeric mobile number")
          .length(10, "Please enter a 10-digit mobile number")
          .typeError(configJSON.MobileErrMsg)
          .required(configJSON.MobileErrMsg),
      });
    } else if (isStep3 === true) {
      this.EditProfileDataAPI();
    }

    this.setStep(nextStep);
    if (nextStep === EditSteps.StepComp) {
      this.setEditProfileData(values);
    }
    actions?.setSubmitting(false);
  };

  getCountryCode = async () => {
    const getAllCountryRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `country_codes`
    );
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllCountryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getCountryCodeAPICallID = getAllCountryRequestMsg.messageId;
    runEngine.sendMessage(getAllCountryRequestMsg.id, getAllCountryRequestMsg);
  };

  // Customizable Area End
}
