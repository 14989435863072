import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { PostSettings } from "./postCreationPopUp/PostCreationPopUp.web";
import { Notifications } from "../../../components/src/types";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel?: any;
  logoutClear?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    attributes: any;
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  showPostPopUp: boolean;
  isOpen: any;
  onClose: any;
  children: any;
  isModalOpen: any;
  selectedLanguage: string;
  isTextFieldFocused: boolean;
  postSettings: PostSettings;
  isSelected: string;
  notifications: Notifications | null;
  isNotificationsOpen: boolean;
  userName: string;
  accountDetails: any;
  postCreationFinished: boolean;
  isLoading: boolean;
  loader: boolean;
  buLoaction: any;
  machineWorkLocation: any;
  dropOpen: any;
  postDataLoading: boolean;
  loadingData: boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiPostsItemCallId: string = "";
  apiCreatePostsItemCallId: string = "";
  getAllPostDataCallId: string = "";
  getNotificationsCallId: string = "";
  getAccountInfoDetailsCallID: string = "";
  getSubFuncLocationCallID: string = "";
  getMachineWorkLocationCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: localStorage.getItem("authToken") || "",
      loading: false,
      showPostPopUp: false,
      isTextFieldFocused: false,
      selectedLanguage: "",
      isModalOpen: false,
      isOpen: false,
      onClose: "",
      children: "",
      postSettings: {
        employeeIds: ["AB123", "test"],
        machineIds: ["Machine FL26", "test"],
        processAndOperationIds: ["Machine FL26", "test"],
        location: "BU25",
        shifts: ["shift1", "shift2", "shift3"],
        concerns: ["Unsafe Act", "Safe Act", "Not Evaluated"],
        hazardSelects: ["low", "high", "medium"],
        frequencies: ["fast", "slow"],
        severities: ["1", "2", "3"],
        consequences: ["straight", "curve"],
        hashtags: null,
      },
      isSelected: "",
      notifications: null,
      isNotificationsOpen: false,
      userName: "",
      accountDetails: {},
      postCreationFinished: false,
      isLoading: false,
      loader: false,
      buLoaction: [],
      machineWorkLocation: [],
      dropOpen: null,
      postDataLoading: false,
      loadingData: false
    };
    this.createPost = this.createPost.bind(this);
    this.goToNotificationsPage = this.goToNotificationsPage.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.getAccountInfoDetailsAPI();
    this.getPostData();
    this.getSubFuncLocationAPI();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.setState({ loader: true });
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.setState({ loading: false, errorMsg: "" });
        this.getReciveFunction(apiRequestCallId, responseJson);
        this.getreciveSubFunctionWork(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.setState({
          loading: false,
          dashboardData: [{ type: "candidate", quantity: "0", attributes: "" }],
          errorMsg: errorReponse,
        });
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  dropDownOpen = () => {
    this.setState({
      dropOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      dropOpen: null,
    });
  };

  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    this.handleClose();
  };
  getPostData = () => {
    this.setState({ postCreationFinished: false, loader: true });
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      "Content-Type": "application/json",
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllPostDataCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_posts/posts"
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getReciveFunction = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiDashboardItemCallId) {
      this.setState({
        loading: false,
        loader: false,
        dashboardData: responseJson?.posts?.data,
        errorMsg: "",
      });
    }

    if (apiRequestCallId === this.apiPostsItemCallId) {
      const postSettings = this.parsePostSettings(responseJson);
      this.setState({
        postSettings: postSettings,
        postDataLoading: false,
        errorMsg: "",
      });
    }

    if (apiRequestCallId === this.apiCreatePostsItemCallId) {
      this.setState({
        loading: false,
        errorMsg: "",
        userName: responseJson?.data?.attributes?.user_name,
      });
      this.getPostData();
      this.setState({ machineWorkLocation: [] });
      this.setState({ postCreationFinished: true });
    }

    if (apiRequestCallId === this.getAllPostDataCallId) {
      this.setState({
        dashboardData: responseJson?.posts?.data || [],
        isLoading: false,
        loader: false,
      });
    }

    if (apiRequestCallId === this.getAccountInfoDetailsCallID) {
      this.setState({
        accountDetails: responseJson?.data || {},
        loader: false,
        loadingData: false
      });
    }

    if (apiRequestCallId === this.getNotificationsCallId) {
      const currentNotifications = this.state.notifications?.data || [];
      const notifications = [
        ...currentNotifications,
        ...responseJson?.notifications.data,
      ];
      this.setState({
        notifications: { ...responseJson?.notifications, data: notifications },
      });
    }
  };

  getreciveSubFunctionWork = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getSubFuncLocationCallID) {
      this.setState({ buLoaction: responseJson.sub_functions.data });
    }
    if (apiRequestCallId === this.getMachineWorkLocationCallID) {
      this.setState({ machineWorkLocation: responseJson.work_locations.data });
    }
  };

  getPostSettings(): boolean {
    this.setState({ postCreationFinished: false });
    const header = {
      "Content-Type": configJSON.postsContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPostsItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postsGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiMethodType
    );
    this.setState({ postDataLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getNotifications(page = 1, perPage = 4): boolean {
    this.setState({ loading: true });

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNotificationsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationsGetUrl}?page=${page}&per_page=${perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.notificationsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createPost(postBody: any): boolean {
    this.setState({ loading: true });
    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreatePostsItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCreateUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      postBody
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  goToNotificationsPage() {
    this.setState({ isNotificationsOpen: false });
  }

  getPropNames(object: any): string[] {
    return (
      object?.data?.map((dataVal: any) => dataVal.attributes.name) || [object]
    );
  }

  parsePostSettings(receivedData: any): PostSettings {
    return {
      consequences: receivedData.consequences?.data,
      frequencies: receivedData.frequencies?.data,
      hazardSelects: receivedData?.hazards?.data,
      severities: receivedData?.severities?.data,
      processAndOperationIds: receivedData.operation_identifications?.data,
      concerns: receivedData.post_concerns?.data,
      employeeIds: this.getPropNames(receivedData["employee_id"]),
      location: this.getPropNames(receivedData["location"])[0],
      machineIds: this.getPropNames(receivedData["work_location"]),
      shifts: receivedData.shifts?.data,
      hashtags: receivedData?.hashtags || [],
    };
  }

  showNotifications = (isNotificationsOpen: boolean) => {
    this.setState({ notifications: null });
    this.setState({ isNotificationsOpen });
    isNotificationsOpen && this.getNotifications();
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  logoutClear = () => {
    localStorage.clear();
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleLanguageSelect = (language: string) => {
    this.setState({ selectedLanguage: language });
  };

  handleTextFieldFocus = () => {
    this.setState({ isTextFieldFocused: true });
  };

  handleTextFieldBlur = () => {
    this.setState({ isTextFieldFocused: false });
  };

  handleSelect = (language: string) => {
    this.setState({ isSelected: language });
  };

  getAccountInfoDetailsAPI = async () => {
    this.setState({ loader: true });
    let tokenValue = localStorage.getItem("authToken");
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${SignUpID}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.setState({ loadingData: true })
    this.getAccountInfoDetailsCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getSubFuncLocationAPI = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_all_sub_functions`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getSubFuncLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  getMachineWorkLocationAPI = async (id: any) => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/get_work_locations?sub_function_id=${id}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getMachineWorkLocationCallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };
  // Customizable Area End
}
